import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  Messages: Message[] = [];
  counter = 0;

  constructor() {
  }

  getMessages(): Message[] {
    return this.Messages;
  }

  /*
  Current Types:
    - info
    - warning
    - success
    - danger
    If it is not one of these, the default is a black background
    */
  sendMessage(content, style) {
    const message = new Message(content, this.counter, style);
    this.Messages.push(message);
    const tempcount = this.counter;
    setTimeout(res => {
      this.dismissMessage(tempcount);
    }, 12000);
    this.counter++;
  }

  dismissMessage(id) {
    delete this.Messages[this.Messages.indexOf(this.Messages.find(x => x && x.id === id))];
  }
}

export class Message {
  id: string;
  content: string;
  style: string;

  constructor(content, counter, style?) {
    this.content = content;
    this.style = style || 'info';
    this.id = counter;
  }

}
