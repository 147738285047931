import {HttpParams} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Article} from '../../models/Article/article.object';
import {ArticleService} from '../../models/Article/article.service';
import {AttachmentService} from '../../models/Attachment/attachment.service';
import {DocumentService} from '../../models/Document/document.service';
import {FilterObject} from '../../objects/filter-object';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-article-picker',
  templateUrl: './article-picker.component.html',
  providers: [ArticleService, AttachmentService, DocumentService],
  styleUrls: ['./article-picker.component.css']
})
export class ArticlePickerComponent implements OnInit {

  // Array of all articles to show
  isLoading = false;
  filterObject: FilterObject = new FilterObject();
  showFilters = true;
  @Input() List: Article[] = [];
  @Output() ListChange: EventEmitter<Article[]> = new EventEmitter<Article[]>(); // When ready we're generating and sending out an Document.

  Articles: Article[] = []; // Full list with articles
  Selected: Article[] = []; // List with selected articles and their amounts

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<ArticlePickerComponent>;

  constructor(private _Dialog: MatDialog, private _Article: ArticleService, public _Global: GlobalService) {
    this.filterObject.include = ['Unit', 'Packaging'];
  }

  ngOnInit() {
  }

  open(input: Article[] = []) {
    // Assign selected
    this.Selected = input;

    this.filter();
    // Show dialog
    this.dialogRef = this._Dialog.open(this.templateRef, {width: '80vw'});
  }

  getData() {
    // Get list of articles
    this._Article.get(new HttpParams().append('filter', JSON.stringify(this.filterObject))).subscribe(res => {
      this.Articles = res;
      this.Articles.forEach(r => {
        r.Children.unshift(new Article(r));
        r.Children[0].Children = [];
      });
    });
    this._Article.count(new HttpParams().append('where', JSON.stringify(this.filterObject.where))).subscribe(count => {
      this.filterObject.count = count.count;
    });
  }


  pageChange(e: { pageIndex: number, pageSize: number, length: number }) {
    this.filterObject.skip = e.pageIndex * e.pageSize;
    this.filterObject.limit = e.pageSize;
    this.getData();
  }

  save() {
    this.ListChange.emit(this.Selected);
    this._Dialog.getDialogById(this.dialogRef.id).close();
  }

  onToggleAll(e: MatCheckboxChange) {
    if (e.checked) {
      this.Articles.forEach(item => {
        if (!this.Selected.find(x => x.Id === item.Id)) this.Selected.push(item);
      });
    } else {
      this.Articles.forEach(item => {
        if (this.Selected.find(x => x.Id === item.Id)) this.Selected.splice(this.Selected.findIndex(x => x.Id === item.Id), 1);
      });
    }
  }

  onToggle(e: MatCheckboxChange, r: Article) {
    if (e.checked) {
      if (!this.Selected.find(x => x.Id === r.Id)) this.Selected.push(r);
    } else {
      if (this.Selected.find(x => x.Id === r.Id)) this.Selected.splice(this.Selected.findIndex(x => x.Id === r.Id), 1);
    }
  }

  isSelected(item: Article): boolean {
    return this.Selected.findIndex(x => x.Id === item.Id) !== -1;
  }


  filter(data: any = null) {
    // Build up and filter
    const and: object[] = [];

    // Add division filter
    and.push({'DivisionId': this._Global.Division.Id});
    if (data && data.Code) and.push({'Code': {'like': '%' + data.Code + '%'}});
    if (data && data.Barcode) and.push({'Barcode': {'like': '%' + data.Barcode + '%'}});
    if (data && data.Category && data.Category.length > 0) {
      data.Category.forEach(c => and.push({'SearchCategories': {'like': '%' + c + '%'}}));
    }
    if (data && data.Description) and.push({or: [{'Description': {'like': '%' + data.Description + '%'}}, {'search': {'like': '%' + data.Description + '%'}}]});

    // If needed reset filter
    this.filterObject.where = {'and': and};
    this.filterObject.skip = 0; // Back to first page
    this.getData();
  }

}

