import {BasketLine} from '../BasketLine/basket-line.object';
import {PromotionBlock} from '../PromotionBlock/promotion-block.object';

export class PromotionOption {
  Id: string = null;
  Title: string = null;
  Block: PromotionBlock = null;
  Image = '';
  MinRequired = 0;
  MaxRequired = 0;
  DefaultSelected = 0;
  OrderNumber = 0;
  BasketLines: BasketLine[] = []; // Lines added for this option

  randomNumber: number;

  constructor(data: any = null) {
    for (const prop in data) if (prop in this) this[prop] = data[prop];
    this.randomNumber = Math.random();

    this.Block = this.Block ? new PromotionBlock(this.Block) : null;
    this.BasketLines.forEach((item, index) => {
      this.BasketLines[index] = new BasketLine(item);
    });
  }

  getImage(): string {
    // return environment.apiUrl + '/Attachments/download/' + encodeURIComponent(this.Image);
    return 'https://api-aorta.edgardcooper.com/v1/Attachments/download/' + encodeURIComponent(this.Image);
  }
}
