import {HttpParams} from '@angular/common/http';
import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR, UntypedFormControl} from '@angular/forms';
import {Role} from '../../models/Role/role.object';
import {RoleService} from '../../models/Role/role.service';
import {FilterObject} from '../../objects/filter-object';

@Component({
  selector: 'app-role-selector',
  templateUrl: './selector.component.html',
  providers: [
    RoleService,
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RoleSelectorComponent), multi: true}
  ]
})
export class RoleSelectorComponent implements OnInit {

  filterObject: FilterObject = new FilterObject;

  // CODE BELOW IS REQUIRED TO REGISTER THE NG_VALUE_ACCESSOR
  _value: any = null;

  @Input() multiple = false;
  @Input() getOnlyId = false;

  // SELECTOR <=> this.ChildControl <=> CONTROLLER (THIS) <=> value (get/set) <=> END component

  Results: Role[];
  ChildControl = new UntypedFormControl();

  get value() {
    return this._value;
  }

  @Input('name') name: string = null; // verplicht ?
  @Input() placeholder = 'Gebruikers Type';
  @Input() required: any = null; // Does not work with @param required

  set value(val: any) {
    if (val instanceof Role || !val) {
      this._value = val;
      console.warn(val);
      this.ChildControl.setValue(val);
      this.onChange(val);
    } else if (val instanceof Array) {
      this._value = val;
      this.ChildControl.setValue(val);
      this.onChange(val);
    }
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  // Asynch search observable
  ngOnInit() {
    const Params = new HttpParams().append('filter', JSON.stringify(this.filterObject));
    this._Role.get(Params).subscribe(res => this.Results = res);
  }

  compareWith(o1: any, o2: any) {
    return o1 === o2 || o1 && o2 && o1.Id && o2.Id && o1.Id === o2.Id;
  }

  constructor(private _Role: RoleService) {
    this.filterObject.include = this._Role.defaultInclude;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    this.value = value;
  }
}
