import {HttpParams} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_ASYNC_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/internal/operators';
import {Relation} from '../../models/Relation/relation.object';
import {RelationService} from '../../models/Relation/relation.service';
import {FilterObject} from '../../objects/filter-object';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-relation-selector',
  templateUrl: './selector.component.html',
  providers: [
    RelationService,
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RelationSelectorComponent), multi: true},
    {provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => RelationSelectorComponent), multi: true}
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelationSelectorComponent implements ControlValueAccessor, OnDestroy {

  // SELECTOR <=> this.ChildControl <=> CONTROLLER (THIS) <=> value (get/set) <=> END component

  Results: Relation[];
  ChildControl = new UntypedFormControl();

  @Input('name') name: string = null; // verplicht ?
  @Input() disabled = false;
  @Input() placeholder = 'Relations code';
  @Input() required = false; // Does not work with @param required
  Selected: Relation = null; // Default selected
  @Input('filter') filterObject: FilterObject = null;
  copyFilter: FilterObject = new FilterObject();

  // V2
  modelChanged: Subject<any> = new Subject<any>();
  childValue: Relation = null; // Default selected
  listResults: Relation[];
  childControl = new UntypedFormControl();

  // CODE BELOW IS REQUIRED TO REGISTER THE NG_VALUE_ACCESSOR
  onChange: any = () => {
  }
  onTouched: any = () => {
  }

  constructor(private _Relation: RelationService, private _Global: GlobalService) {
    this.copyFilter = new FilterObject(this.filterObject);

    // Subscribe to changes with a debounceTime
    this.modelChanged.pipe(
        debounceTime(500),
        distinctUntilChanged()
    ).subscribe(val => {

      if (val instanceof Relation || !val) {
        this.value = val;
      } else {
        this.doSearch(val).subscribe(res => {
          this.listResults = res;

          // Only one result found / multiple
          if (res && res.length === 1) {
            this.childValue = res[0];
            this.value = res[0];
          } else {
            this.validate(this.childControl).subscribe(); // Run validator manually
            this.value = null;
          }
        })
      }
    });
  }

  get value() {
    return this.childValue instanceof Relation ? this.childValue : null;
  }

  set value(val) {
    if (!this.childValue && val instanceof Relation) this.childValue = val; // Set once on init
    this.onChange(val);
    this.onTouched();
  }

  ngOnDestroy() {
  }

  // Async validator, note the NG_ASYNC_VALIDATORS provider !
  validate(c: UntypedFormControl): Observable<any> {
    return new Observable(observer => {
      // Selected from autocomplete list = valid OR empty and NOT required
      if (this.Selected instanceof Relation || (!this.ChildControl.value && !this.required)) {
        this.ChildControl.setErrors(null);
        observer.next(null);
        observer.complete();
      } else {
        this.ChildControl.setErrors({err: 'err'});
        observer.next({'err': true});
        observer.complete();
      }
    });
  }

  // Asynch search observable
  doSearch(e: any): Observable<Relation[]> {
    if (e && e.indexOf('%') === -1) e = ['%', e, '%'].join(''); // Add wildcards if not set
    this.copyFilter.include = this.filterObject && this.filterObject.include ? this.filterObject.include : [{'Addresses': [{'Country': 'ShipmentArticle'}, 'SupplyPlant', 'CarrierServiceLevel', 'CarrierServiceLevelOther', 'Type']}, 'Contacts', 'CustomerGroup', {'DefaultAddress': ['Type', 'CarrierServiceLevel', 'SupplyPlant', 'CarrierServiceLevelOther', {'Country': 'ShipmentArticle'}]}, {'DefaultContact': {'Locale': 'Country'}}, 'DefaultVATCode', 'DefaultSupplyPlant'];
    const tmpWhere = {and: [{IsApproved: true}, {DivisionId: this._Global.Division.Id}, {'or': [{'Code': {'like': e}}, {'Name': {'like': e}}, {'Description': {'like': e}}]}]};
    this.copyFilter.where = this.filterObject && this.filterObject.where ? {'and': [this.filterObject.where, tmpWhere]} : tmpWhere;
    const Params = new HttpParams().append('filter', JSON.stringify(this.copyFilter));
    return this._Relation.get(Params);
  }

  updateNgModel(val) {
    this.modelChanged.next(val); // To subscribe on debounce
  }

  displayFn(input: Relation): string {
    return input instanceof Relation ? input.Name : input;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    this.value = value;
  }

}
