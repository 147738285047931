import {HttpParams} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {AccountService} from '../../../models/Account/account.service';
import {ActivationAccountService} from '../../../models/ActivationAccount/activation-account.service';
import {GlobalService} from '../../../services/global.service';

@Component({
  selector: 'app-left-side-navigation-component',
  templateUrl: './left-side-navigation.component.html',
  styleUrls: ['./left-side-navigation.component.css'],
  providers: [AccountService, ActivationAccountService]

})
export class LeftSideNavigationComponent implements OnInit {

  numStudentsToModerate: Observable<{ count: number }>;
  numActivationsToModerate: Observable<{ count: number }>;

  constructor(public _Global: GlobalService, private _Account: AccountService, private _ActivationAccount: ActivationAccountService) {
  }

  ngOnInit() {
    let params = new HttpParams().append('where', JSON.stringify({and: [{or: [{IsActive: {inq: [false]}}, {IsActive: null}]}, {RoleId: 'Student'}]}));
    this.numStudentsToModerate = this._Account.count(params);

    params = new HttpParams().append('where', JSON.stringify({'Start': {gte: moment(new Date()).startOf('month').toDate()}}));
    this.numActivationsToModerate = this._ActivationAccount.count(params);
  }

}
