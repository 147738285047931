import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {Country} from './country.object';

@Injectable()
export class CountryService {

  defaultInclude = ['Locales', 'ShipmentArticle', 'Translations'];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<Country[]> {
    return this.http
        .get<Country[]>(environment.apiUrl + '/Countries', {params: ReqParams})
        .pipe(
            map(res => <Country[]>res),
            map(res => {
              return res.map(rel => new Country(rel));
            })
        );
  }

  upsert(Relation: Country): Observable<Country> {
    return this.http
        .put(environment.apiUrl + '/Countries', JSON.stringify(Relation))
        .pipe(
            map(res => <Country>res),
            map(res => {
              // Only update available props in relation. (Avoid to add irelevant props)
              for (const prop in res) if (prop in Relation) Relation[prop] = res[prop];
              return new Country(Relation);
            })
        );
  }

  remove(Relation: Country): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/Countries/' + Relation.Id);
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/Countries/count', {params: ReqParams})
        .pipe(
            map(res => <{ count: number }>res)
        );
  }

}
