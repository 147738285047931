import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Article} from '../../models/Article/article.object';
import {Group} from '../../models/Group/group.object';
import {GroupService} from '../../models/Group/group.service';
import {Promotion} from '../../models/Promotion/promotion.object';
import {PromotionCode} from '../../models/PromotionCode/promotion-code.object';
import {Relation} from '../../models/Relation/relation.object';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-group-editor',
  templateUrl: './group-editor.component.html',
  styleUrls: ['./group-editor.component.css'],
  providers: [GroupService]
})
export class GroupEditorComponent implements OnInit {

  // Original & deep copy
  Original: Group = new Group;
  Copy: Group = new Group;

  isLoading: boolean;

  @Input() List: Group[] = [];
  @Output() ListChange: EventEmitter<Group[]> = new EventEmitter<Group[]>();
  @Output() Refresh: EventEmitter<boolean> = new EventEmitter();

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<GroupEditorComponent>;

  constructor(private _Global: GlobalService, private _Dialog: MatDialog, private _Group: GroupService) {
  }

  ngOnInit() {
  }

  // Show editor & assign vars
  edit(entry: Group = new Group) {
    this.Original = entry; // Assign original
    this.Copy = new Group(JSON.parse(JSON.stringify(entry))); // Deep copy
    this.dialogRef = this._Dialog.open(this.templateRef);
  }

  save() {
    this.isLoading = true;
    this._Group
        .upsert(this.Copy)
        .subscribe(res => {
          this.Copy = res;
          Object.assign(this.Original, res);
          const found = this.List.find(x => x.Id === res.Id);
          found ? Object.assign(found, res) : this.List.push(res); // Modify or push array list
          this.ListChange.emit(this.List);
          this.isLoading = false;
          this._Dialog.getDialogById(this.dialogRef.id).close();
        }, () => this.isLoading = false);
  }

  remove(entry: Group) {
    this.isLoading = true;
    this._Group
        .remove(entry)
        .subscribe(res => {
          if (this.List) this.List.splice(this.List.indexOf(entry), res.count);
          this.ListChange.emit(this.List);
          this.isLoading = false;
          this._Dialog.getDialogById(this.dialogRef.id).close();
        }, () => this.isLoading = false);
  }

  removeArticle(input: Article) {
    this._Group.removeByArticle(this.Copy, input).subscribe(() => {
      this.Copy.Articles.splice(this.Copy.Articles.indexOf(input), 1);
    });
  }

  removeRelation(input: Relation) {
    this._Group.removeByRelation(this.Copy, input).subscribe(() => {
      this.Copy.Relations.splice(this.Copy.Relations.indexOf(input), 1);
    });
  }

  removePromotion(input: Promotion) {
    this._Group.removeByPromotion(this.Copy, input).subscribe(() => {
      this.Copy.Promotions.splice(this.Copy.Promotions.indexOf(input), 1);
    });
  }

  removeCode(input: PromotionCode) {
    this._Group.removeByPromotionCode(this.Copy, input).subscribe(() => {
      this.Copy.Promotions.splice(this.Copy.PromotionCodes.indexOf(input), 1);
    });
  }

}
