import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PriceList} from '../../models/PriceList/price-list.object';
import {PriceListService} from '../../models/PriceList/price-list.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-price-list-editor',
  templateUrl: './price-list-editor.component.html',
  styleUrls: ['./price-list-editor.component.css'],
  providers: [PriceListService]
})
export class PriceListEditorComponent implements OnInit {

  // Original & deep copy
  Original: PriceList = new PriceList;
  Copy: PriceList = new PriceList;

  isLoading: boolean;

  @Input() List: PriceList[] = [];
  @Output() ListChange: EventEmitter<PriceList[]> = new EventEmitter<PriceList[]>();
  @Output() Refresh: EventEmitter<boolean> = new EventEmitter();

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<PriceListEditorComponent>;

  constructor(private _Global: GlobalService, private _Dialog: MatDialog, private _PriceList: PriceListService) {
  }

  ngOnInit() {
  }

  // Show editor & assign vars
  show(entry: PriceList = new PriceList) {
    this.Original = entry; // Assign original
    this.Copy = new PriceList(JSON.parse(JSON.stringify(entry))); // Deep copy
    this.dialogRef = this._Dialog.open(this.templateRef);
  }

}
