import {Account} from '../Account/account.object';
import {DefaultModel} from '../Default/default.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';
import {SupportMessage} from '../SupportMessage/support-message.object';

export class SupportTicket extends DefaultModel {

  Subject: string = null;
  Status: DropdownOption = new DropdownOption({
    Id: '43904331-d406-4ac1-a7c5-c08095164a0e',
    Model: 'SupportTicket',
    Discriminator: 'Status',
    Value: 'Open',
    Order: 100
  });
  Type: DropdownOption = null;
  Account: Account = null;
  Date: Date = null;
  Deadline: Date = null;
  SupportMessages: SupportMessage[] = [];

  constructor(data: any = null) {
    super(data);
    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    // Convert untyped to typed objects
    this.Status = this.Status ? new DropdownOption(this.Status) : null;
    this.Type = this.Type ? new DropdownOption(this.Type) : null;
    this.Account = this.Account ? new Account(this.Account) : null;
    if (this.SupportMessages) {
      this.SupportMessages.forEach((item, index) => {
      this.SupportMessages[index] = new SupportMessage(item);
    });
    }
  }
}
