import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';
import {Article} from '../../models/Article/article.object';
import {ArticleService} from '../../models/Article/article.service';
import {Group} from '../../models/Group/group.object';
import {GroupSubscriptionService} from '../../models/GroupSubscription/group-subscription.service';
import {Relation} from '../../models/Relation/relation.object';
import {RelationService} from '../../models/Relation/relation.service';
import {FilterObject} from '../../objects/filter-object';

@Component({
  selector: 'app-group-finder',
  templateUrl: './group-finder.component.html',
  styleUrls: ['./group-finder.component.css'],
  providers: [GroupSubscriptionService, RelationService, ArticleService]
})
export class GroupFinderComponent implements OnInit {

  filterRelation: FilterObject = new FilterObject();
  filterArticle: FilterObject = new FilterObject();

  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<GroupFinderComponent>;

  constructor(private dialog: MatDialog) {
    this.filterRelation.include = ['Groups'];
    this.filterArticle.include = ['Groups', {'Parent': ['Groups']}];
  }

  ngOnInit(): void {
  }

  open() {
    this.dialogRef = this.dialog.open(this.templateRef);
  }

  // Get array of matching groups
  getMatchingGroups(a: Group[], b: Group[]): string[] {
    return a.filter(y => b.findIndex(x => x.Id === y.Id) !== -1).map(x => x.Title);
  }

  // Get NO matching groups
  getUnmatchingGroups(a: Group[], b: Group[]): string[] {
    return a.filter(y => b.findIndex(x => x.Id === y.Id) === -1).map(x => x.Title);
  }

  // Is match?
  isMatch(article: Article, relation: Relation): string {
    if (relation.IsBlocked) return 'relation-blocked';
    else if (article.IsBlocked) return 'article-blocked';
    else if ((this.getMatchingGroups(article.Groups, relation.Groups).length - this.getDateLimitedGroups(article.Groups, relation.Groups).length) > 0) return 'match';
    else if (this.getDateLimitedGroups(article.Groups, relation.Groups).length > 0) return 'warning';
    return 'nomatch';
  }

  // Matching, but limited in date
  getDateLimitedGroups(a: Group[], b: Group[]): string[] {
    return a
      .filter(y => b.findIndex(x => x.Id === y.Id) !== -1)
      .filter(x => x.Start || x.End)
      .map(x => [x.Title, ' (', moment(x.Start).format('DD/MM/YYYY'), '-', moment(x.End).format('DD/MM/YYYY'), ')'].join(''));
  }

  // Min matching date
  getMinMatchingDate(a: Group[], b: Group[]): Date {
    return a
      .filter(y => b.findIndex(x => x.Id === y.Id) !== -1)
      .filter(x => x.Start)
      .map(x => x.Start)
      .reduce((x, y) => moment(x).isBefore(y) ? x : y, null);
  }

  // Max matching date
  getMaxMatchingDate(a: Group[], b: Group[]): Date {
    return a
      .filter(y => b.findIndex(x => x.Id === y.Id) !== -1)
      .filter(x => x.End)
      .map(x => x.End)
      .reduce((x, y) => moment(x).isAfter(y) ? x : y, null);
  }

}
