import {DefaultModel} from '../Default/default.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';
import {Group} from '../Group/group.object';
import {Location} from '../Location/location.object';
import {PriceListDetail} from '../PriceListDetail/price-list-detail.object';

export class Article extends DefaultModel {

  ExternalId: string = null;
  Parent: Article = null;
  Category1: DropdownOption = null;
  Unit: DropdownOption = null;
  Packaging: DropdownOption = null;
  PackageType: DropdownOption = null;
  BlockedSupplyPlants: string[] = [];
  OptionWeight: string = null;
  Code: string = null;
  Description: string = null;
  Remarks: string = null;
  CommercialName: string = null;
  DisplayName: string = null;
  Barcode: string = null;
  Start: Date = null;
  End: Date = null;
  InStockDate: Date = null;
  Stock: number = null;
  Length: number = null;
  Width: number = null;
  Height: number = null;
  Weight: number = null;
  Volume: number = null;
  MinQTY: number = 1;
  MaxQTY: number = null;
  Step: number = 1;
  BatchAmount: number = null;
  Order: number = null;
  SalesPrice: number = 0;
  PurchasePrice: number = null;
  IsBlocked: number = null;
  IsUndeliverable: number = null;
  Image: string = null;
  Imput: string = null;
  IsNew: string = null;
  Keywords: string[] = [];
  Locations: Location[] = [];

  Children: Article[] = []; // The variants e.g: 700g, 7kg, 400g etc.
  Categories: DropdownOption[] = [];
  Groups: Group[] = [];
  PriceListDetails: PriceListDetail[] = [];

  // For NgModelgroups
  randomNumber: number;

  constructor(data: any = null) {

    super(data);
    this.randomNumber = Math.random();

    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.Parent = this.Parent ? new Article(this.Parent) : null;
    this.Category1 = this.Category1 ? new DropdownOption(this.Category1) : null;
    this.Unit = this.Unit ? new DropdownOption(this.Unit) : null;
    this.Packaging = this.Packaging ? new DropdownOption(this.Packaging) : null;
    this.PackageType = this.PackageType ? new DropdownOption(this.PackageType) : null;
    if (this.Children) {
      this.Children.forEach((item, index) => {
        this.Children[index] = new Article(item);
      });
    }
    if (this.Locations) {
      this.Locations.forEach((item, index) => {
        this.Locations[index] = new Location(item);
      });
    }
    if (this.Groups) {
      this.Groups.forEach((item, index) => {
        this.Groups[index] = new Group(item);
      });
    }
    if (this.Categories) {
      this.Categories.forEach((item, index) => {
        this.Categories[index] = new DropdownOption(item);
      });
    }
    if (this.PriceListDetails) {
      this.PriceListDetails.forEach((item, index) => {
        this.PriceListDetails[index] = new PriceListDetail(item);
      });
    }

    if (!this.Keywords || !(this.Keywords instanceof Array)) {
      this.Keywords = [];
    }
  }

  getImage(): string {
    // return environment.apiUrl + '/Attachments/download/' + encodeURIComponent(this.Image);
    return this.Image ? 'https://api-aorta.edgardcooper.com/v1/Attachments/download/' + encodeURIComponent(this.Image) : null;
  }

}
