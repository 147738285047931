import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {Schedule} from './schedule.object';

@Injectable()
export class ScheduleService {
  defaultInclude = [{'Document': ['Relation', 'Locale', 'SourceWarehouse', 'DestinationWarehouse', 'ShippingMethod', 'Classification']}, 'DocumentType'];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<Schedule[]> {
    return this.http
        .get<Schedule[]>(environment.apiUrl + '/Schedules', {params: ReqParams})
        .pipe(
            map(res => <Schedule[]>res),
            map(res => {
              return res.map(rel => new Schedule(rel));
            })
        );
  }

  upsert(Entry: Schedule): Observable<Schedule> {
    return this.http
        .put<Schedule>(environment.apiUrl + '/Schedules', JSON.stringify(Entry))
        .pipe(
            map(res => <Schedule>res),
            map(res => {
              // Modify original doc without destroying related objects (doclines)
              for (const prop in res) if (prop in Entry) Entry[prop] = res[prop];
              return new Schedule(Entry);
            })
        );

  }

  update(Data: object, ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .post<{ count: number }>(environment.apiUrl + '/Schedules/update', JSON.stringify(Data), {params: ReqParams});
  }

  remove(Entry: Schedule): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/Schedules/' + Entry.Id);
  }

  getChartData(ReqParams: HttpParams = null): Observable<any> {
    return this.http
        .get<any>(environment.apiUrl + '/Schedules/getChartData', {params: ReqParams});
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/Schedules/count', {params: ReqParams})
        .pipe(
            map(res => <{ count: number }>res)
        );
  }

}
