import {Locale} from '../Locales/locale.object';
import {Promotion} from '../Promotion/promotion.object';
import {PromotionOption} from '../PromotionOption/promotion-option.object';
import {Translation} from '../Translation/translation.object';

export class PromotionBlock {
  Id: string = null;
  Promotion: Promotion = null;
  MinRequired = 0;
  MaxRequired = 0;
  StepRequired = 1;
  OrderNumber = 0;
  Title: Translation[] = [];
  Options: PromotionOption[] = [];

  randomNumber: number;

  constructor(data: any = null) {
    for (const prop in data) if (prop in this) this[prop] = data[prop];
    this.Promotion = this.Promotion ? new Promotion(this.Promotion) : null;
    this.randomNumber = Math.random();

    this.Options.forEach((item, index) => {
      this.Options[index] = new PromotionOption(item);
    });
  }

  getTitle(loc: Locale = new Locale()): string {
    const found = this.Title.find(x => x.Locale.Id === loc.Id);
    return found ? found.Value : 'NO_TRANSLATION';
  }
}
