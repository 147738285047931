import {OverlayContainer} from '@angular/cdk/overlay';
import {Component, HostBinding, OnInit} from '@angular/core';
import {environment} from '../environments/environment';
import {Account} from './models/Account/account.object';
import {GlobalService} from './services/global.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  environment = environment;
  themes: { name: string, color: string, theme: string }[] = [];
  activeTheme: { name: string, color: string, theme: string } = null;
  @HostBinding('class') componentCssClass;

  constructor(public overlayContainer: OverlayContainer, private _Global: GlobalService) {
    this.themes.push({name: 'Deep Purple Amber', color: '#673AB7', theme: 'deeppurple-amber-theme'});
    this.themes.push({name: 'Indigo Pink', color: '#3F51B5', theme: 'indigo-pink-theme'});
    this.themes.push({name: 'Pink Bluegrey', color: '#E91E63', theme: 'pink-bluegrey-theme'});
    this.themes.push({name: 'Purple Green', color: '#9C27B0', theme: 'purple-green-theme'});
    this.themes.push({name: 'Edgard & Cooper', color: '#7ec9d1', theme: 'edgard-cooper-theme'});

    this.activeTheme = this.themes[1];

  }

  onSetTheme(theme) {
    this.activeTheme = theme;
    this.overlayContainer.getContainerElement().classList.add(theme.theme);
    this.componentCssClass = theme.theme;
  }

  ngOnInit() {
    if (localStorage.Account) {
      this._Global.Account = new Account(JSON.parse(localStorage.Account));
      if (this._Global.Account.DefaultDivision) this._Global.Division = this._Global.Account.DefaultDivision;
      else if (this._Global.Account.Divisions.length > 0) this._Global.Division = this._Global.Account.Divisions[0];
    }
  }

}
