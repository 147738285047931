import {DefaultModel} from '../Default/default.object';
import {Document} from '../Document/document.object';

export class DocumentPrint extends DefaultModel {

  Document: Document = null;
  Server: string = null;
  Path: string = null;

  constructor(data: any = null) {

    super(data);

    for (const prop in data) if (prop in this) this[prop] = data[prop];
  }
}
