import {HttpParams} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AttachmentService} from '../../models/Attachment/attachment.service';
import {DocumentService} from '../../models/Document/document.service';
import {Relation} from '../../models/Relation/relation.object';
import {RelationService} from '../../models/Relation/relation.service';
import {FilterObject} from '../../objects/filter-object';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-relation-picker',
  templateUrl: './relation-picker.component.html',
  providers: [RelationService, AttachmentService, DocumentService],
  styleUrls: ['./relation-picker.component.css']
})
export class RelationPickerComponent implements OnInit {

  // Array of all relations to show
  isLoading = false;
  filterObject: FilterObject = new FilterObject();
  showFilters = true;
  @Input() List: Relation[] = [];
  @Output() ListChange: EventEmitter<Relation[]> = new EventEmitter<Relation[]>(); // When ready we're generating and sending out an Document.

  Relations: Relation[] = []; // Full list with relations
  Selected: Relation[] = []; // List with selected relations and their amounts

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<RelationPickerComponent>;

  constructor(private _Dialog: MatDialog, private _Relation: RelationService, public _Global: GlobalService) {
  }

  ngOnInit() {
  }

  open(input: Relation[] = []) {

    // Assign selected
    this.Selected = input;

    this.filter();
    // Show dialog
    this.dialogRef = this._Dialog.open(this.templateRef, {width: '80vw'});
  }

  getData() {
    // Get list of relations
    this._Relation.get(new HttpParams().append('filter', JSON.stringify(this.filterObject))).subscribe(res => {
      this.Relations = res;
    });
    this._Relation.count(new HttpParams().append('where', JSON.stringify(this.filterObject.where))).subscribe(count => {
      this.filterObject.count = count.count;
    });
  }


  pageChange(e: { pageIndex: number, pageSize: number, length: number }) {
    this.filterObject.skip = e.pageIndex * e.pageSize;
    this.filterObject.limit = e.pageSize;
    this.getData();
  }

  save() {
    this.ListChange.emit(this.Selected);
    this._Dialog.getDialogById(this.dialogRef.id).close();
  }

  onToggleAll(e: MatCheckboxChange) {
    if (e.checked) {
      this.Relations.forEach(item => {
        if (!this.Selected.find(x => x.Id === item.Id)) this.Selected.push(item);
      });
    } else {
      this.Relations.forEach(item => {
        if (this.Selected.find(x => x.Id === item.Id)) this.Selected.splice(this.Selected.findIndex(x => x.Id === item.Id), 1);
      });
    }
  }

  onToggle(e: MatCheckboxChange, r: Relation) {
    if (e.checked) {
      if (!this.Selected.find(x => x.Id === r.Id)) this.Selected.push(r);
    } else {
      if (this.Selected.find(x => x.Id === r.Id)) this.Selected.splice(this.Selected.findIndex(x => x.Id === r.Id), 1);
    }
  }

  isSelected(item: Relation): boolean {
    return this.Selected.findIndex(x => x.Id === item.Id) !== -1;
  }

  filter(data: any = null) {
    // Build up and filter
    const and: object[] = [];

    // Add division filter
    if (data && data.Name) and.push({or: [{'Name': {like: '%' + data.Name + '%'}}, {'Code': {like: '%' + data.Name + '%'}}]});
    if (data && data.Representative) and.push({'RepresentativeId': data.Representative.Id});
    if (data && data.RetailChain && data.RetailChain.length > 0) and.push({RetailChainId: {inq: data.RetailChain}});

    if (data && data.Locale) and.push({LocaleId: data.Locale.Id});
    if (data && data.Groups && data.Groups.length > 0) {
      const or = [];
      data.Groups.forEach(g => {
        or.push({SearchGroups: {like: '%' + g.Id + '%'}});
      });
      and.push({or: or});
    }
    if (data && data.Country && data.Country.length > 0) {
      const or = [];
      data.Country.forEach(c => {
        or.push({SearchCountries: {like: '%' + c + '%'}});
      });
      and.push({or: or});
    }

    // If needed reset filter
    this.filterObject.where = {'and': and};
    this.filterObject.skip = 0; // Back to first page
    this.getData();
  }

}

