import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Country} from '../../models/Country/country.object';
import {CountryService} from '../../models/Country/country.service';

@Component({
  selector: 'app-country-editor',
  templateUrl: './editor.component.html',
  providers: [CountryService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountryEditorComponent implements OnInit {
  @Input() List: Country[] = [];
  @Output() ListChange: EventEmitter<Country[]> = new EventEmitter<Country[]>();
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<CountryEditorComponent>;
  Original: Country = null;
  Copy: Country = null;
  isLoading = false;

  constructor(private _Dialog: MatDialog, private _Country: CountryService) {
  }

  edit(input: Country = new Country()) {
    this.dialogRef = this._Dialog.open(this.templateRef);
    this.Original = input;
    this.Copy = new Country(JSON.parse(JSON.stringify(input)));
  }

  save(object = this.Copy) {
    this.isLoading = true;
    this._Country.upsert(object).subscribe(res => {
      this.isLoading = false;
      this.Copy = res;
      Object.assign(this.Original, this.Copy);
      const found = this.List.find(x => x.Id === this.Copy.Id && x.Id !== null);
      found ? this.List[this.List.indexOf(found)] = this.Copy : this.List.push(this.Copy);
      this._Dialog.getDialogById(this.dialogRef.id).close();
      this.ListChange.emit(this.List);
    });
  }

  remove(c: Country) {
    this._Country.remove(c).subscribe(res => {
      this.List.splice(this.List.indexOf(c), 1);
      this.ListChange.emit(this.List);
    });
  }

  ngOnInit() {
  }

  goToDocumentation() {
    window.open('https://regexr.com/');
  }
}
