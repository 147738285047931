import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import 'fullcalendar';
import {OptionsInput} from 'fullcalendar';
import * as $ from 'jquery';

@Component({
  selector: 'app-fullcalendar',
  templateUrl: 'full-calendar.component.html',
  providers: []
})
export class FullCalendarComponent implements AfterViewInit {

  /*!
   * This component is based on the ap-angular2-fullcalendar component
   * This component is extended to use with more views on one page
   */

  // Generate random ID
  id: string = Date.now().toString();


  @Input() options: OptionsInput;
  @Output() initialized: EventEmitter<FullCalendarComponent> = new EventEmitter<FullCalendarComponent>();

  public myView;

  constructor() {
  }

  // Initialize function separate could be useful when using tabs (initilalize when the tab is shown)
  initialize() {
    this.myView = $('#' + this.id);
    this.myView.fullCalendar(this.options);
    this.initialized.emit(this);
  }

  ngAfterViewInit() {
    this.initialize();
  }

  fullCalendar(...args: any[]): any {
    if (!args) {
      return this;
    }
    switch (args.length) {
      case 0:
        return;
      case 1:
        return this.myView.fullCalendar(args[0]);
      case 2:
        return this.myView.fullCalendar(args[0], args[1]);
      case 3:
        return this.myView.fullCalendar(args[0], args[1], args[2]);
    }
  }
}

