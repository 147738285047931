import {HttpParams} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatOptionSelectionChange} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {Division} from '../../models/Division/division.object';
import {DivisionService} from '../../models/Division/division.service';
import {FilterObject} from '../../objects/filter-object';

@Component({
  selector: 'app-division-selector',
  templateUrl: './selector.component.html',
  providers: [
    DivisionService,
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DivisionSelectorComponent), multi: true}
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DivisionSelectorComponent implements OnInit {

  _value: any = null; // Could be Division or Division[]

  Divisions: Division[] = [];
  @Input() placeholder = 'Division';
  @Input() multiple = false;
  @Input() getOnlyId = false;
  @Input() required = false;
  @Input() disabled = false;
  @Input() allOption = false;
  @ViewChild('selector') Selector: MatSelect;

  ChildControl = new UntypedFormControl();
  Selected: string = null; // Default selected
  filterObject: FilterObject = new FilterObject();

  get value() {
    return this._value;
  }

  set value(val) {
    if (!val || val instanceof Division || val instanceof Array) {
      this._value = val;
      this.onChange(val);
    }
  }

  // CODE BELOW IS REQUIRED TO REGISTER THE NG_VALUE_ACCESSOR
  onChange: any = () => {
  };

  ngOnInit() {
    const Params: HttpParams = new HttpParams().append('filter', JSON.stringify(this.filterObject));
    this._Division.get(Params).subscribe(res => {
      this.Divisions = res;
      this._CdRef.detectChanges();
    });
  }

  toggle(e: MatOptionSelectionChange) {
    if (e.source.selected) {
      this.Selector.options.forEach(option => {
        option['_selectViaInteraction']();
      });
    }
  }

  compareWith(o1: any, o2: any) {
    return o1 && o2 && o1.Id && o2.Id && o1.Id === o2.Id;
  }

  onTouched: any = () => {
  };

  constructor(private _Division: DivisionService, private _CdRef: ChangeDetectorRef) {
    this.filterObject.limit = null;
    this.filterObject.include = _Division.defaultInclude;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    this.value = value;
  }

}
