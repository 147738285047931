import {Account} from '../Account/account.object';
import {DefaultModel} from '../Default/default.object';
import {SupportTicket} from '../SupportTicket/support-ticket.object';

export class SupportMessage extends DefaultModel {

  SupportTicket: SupportTicket = null;
  Sender: Account = null;
  Message: string = null;
  Sent: Date = null;
  Read: Date = null;
  Replied: Date = null;

  constructor(data: any = null) {
    super(data);
    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    // Convert untyped to typed objects
    this.Sender = this.Sender ? new Account(this.Sender) : null;
  }
}
