import {HttpParams} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_ASYNC_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/internal/operators';
import {Document} from '../../models/Document/document.object';
import {DocumentService} from '../../models/Document/document.service';
import {FilterObject} from '../../objects/filter-object';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-document-selector',
  templateUrl: './selector.component.html',
  providers: [
    DocumentService,
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DocumentSelectorComponent), multi: true},
    {provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => DocumentSelectorComponent), multi: true}
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentSelectorComponent implements ControlValueAccessor, OnDestroy {

  // SELECTOR <=> this.ChildControl <=> CONTROLLER (THIS) <=> value (get/set) <=> END component

  Results: Document[];
  ChildControl = new UntypedFormControl();

  @Input('name') name: string = null; // verplicht ?
  @Input() placeholder = 'Document code';
  @Input() disabled: false;
  @Input() category: string = null;
  @Input() required = false; // Does not work with @param required
  @Input() hideCode = false; // Hides art code and only shows full description
  @Input('filter') filterObject: FilterObject = new FilterObject(); // To filter
  filterCopy: FilterObject = new FilterObject(this.filterObject);
  Selected: Document = null; // Default selected

  Subscription: Subscription;

  constructor(private _Document: DocumentService, private _Global: GlobalService) {

    this.Subscription = this.ChildControl.valueChanges
        .pipe(debounceTime(400))
        .subscribe(a => {
          if (a instanceof Document) { // Selected trough autocomplete
            this.value = a;
          } else if (a) { // String
            this.doSearch(a).subscribe(res => {
              this.Results = res;
              // Only one result found / multiple
              this.value = res && res.length === 1 ? res[0] : a;
            });
          } else { // Empty
            this.value = null;
          }
        });
  }

  get value() {
    return this.Selected instanceof Document ? this.Selected : null;
  }

  set value(val) {
    this.Selected = val;
    if (val !== this.ChildControl.value) this.ChildControl.setValue(val);
    this.onChange(this.Selected);
    this.onTouched();
  }

  ngOnDestroy() {
    this.Subscription.unsubscribe();
  }

  // Async validator, note the NG_ASYNC_VALIDATORS provider !
  validate(c: UntypedFormControl): Observable<any> {

    return new Observable(observer => {
      // Selected from autocomplete list = valid OR empty and NOT required
      if ((this.Selected instanceof Document || (!this.ChildControl.value && !this.required))) {
        this.ChildControl.setErrors(null);
        observer.next(null);
        observer.complete();
      } else {
        this.ChildControl.setErrors({err: 'err'});
        observer.next({'err': true});
        observer.complete();
      }
    });
  }

  // Asynch search observable
  doSearch(e: any): Observable<Document[]> {
    const tmpWhere = {and: [{DivisionId: this._Global.Division.Id}, {'Number': {'like': '%' + e + '%'}}]};
    this.filterCopy.where = this.filterObject.where ? {'and': [this.filterObject.where, tmpWhere]} : tmpWhere;
    const Params = new HttpParams().append('filter', JSON.stringify(this.filterCopy));
    return this._Document.get(Params);
  }

  displayFn(input: Document) {
    return input instanceof Document ? input.Number : input;
  }

  // CODE BELOW IS REQUIRED TO REGISTER THE NG_VALUE_ACCESSOR
  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    this.value = value;
  }
}
