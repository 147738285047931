import {HttpParams} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit, TemplateRef} from '@angular/core';
import {NG_VALUE_ACCESSOR, UntypedFormControl} from '@angular/forms';
import {Country} from '../../models/Country/country.object';
import {CountryService} from '../../models/Country/country.service';
import {FilterObject} from '../../objects/filter-object';

@Component({
  selector: 'app-country-selector',
  templateUrl: './selector.component.html',
  providers: [
    CountryService,
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CountrySelectorComponent), multi: true}
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountrySelectorComponent implements OnInit {

  Countries: Country[] = [];
  @Input() placeholder = 'Country';
  @Input() multiple = false;
  @Input() getOnlyId = false;
  @Input() required = false;
  @Input() disabled = false;
  @Input() matSuffix: TemplateRef<any>;

  ChildControl = new UntypedFormControl();
  Selected: string = null; // Default selected
  filterObject: FilterObject = new FilterObject();

  // CODE BELOW IS REQUIRED TO REGISTER THE NG_VALUE_ACCESSOR
  _value: any = null; // Could be Country or Country[]

  get value() {
    return this._value;
  }

  set value(val) {
    if (!val || val instanceof Country || val instanceof Array) {
      this._value = val;
      this.ChildControl.setValue(val);
      this.onChange(val);
    }
  }

  onChange: any = () => {
  };

  ngOnInit() {
    const Params: HttpParams = new HttpParams().append('filter', JSON.stringify(this.filterObject));
    this._Country.get(Params).subscribe(res => {
      this.Countries = res;
      this._CdRef.detectChanges();
    });
  }

  compareWith(o1: any, o2: any) {
    return o1 && o2 && o1.Id && o2.Id && o1.Id === o2.Id;
  }

  onTouched: any = () => {
  };

  constructor(private _Country: CountryService, private _CdRef: ChangeDetectorRef) {
    this.filterObject.limit = null;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    this.value = value;
  }

}
