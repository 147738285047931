import {HttpParams} from '@angular/common/http';
import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Attachment} from '../../models/Attachment/attachment.object';
import {AttachmentService} from '../../models/Attachment/attachment.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-file-folder-editor',
  templateUrl: './file-folder-editor.component.html',
  styleUrls: ['./file-folder-editor.component.css'],
  providers: [AttachmentService]
})
export class FileFolderEditorComponent implements OnInit {

  @Input() currentDirectory: Attachment = null;
  newDirectory: Attachment = new Attachment();

  constructor(private _Global: GlobalService,
              private _Dialog: MatDialog,
              private _Attachment: AttachmentService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<FileFolderEditorComponent>) {
    this.currentDirectory = data.currentDirectory;
  }

  ngOnInit() {
    this.newDirectory = new Attachment();
  }

  save() {
    const params = new HttpParams().append('path', this.currentDirectory.Path + '/' + this.newDirectory.Name);
    this._Attachment.mkDir(params).subscribe(res => {
      this.currentDirectory.Children.push(res);
      this.dialogRef.close();
    });
  }
}
