import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AccountService} from '../models/Account/account.service';
import {GlobalService} from './global.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(private _Router: Router, private _Account: AccountService, private _Global: GlobalService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>(
        observer => {
          if (this._Global.Account) {
            observer.next(true);
            observer.complete();
          } else {

            if (localStorage.AccountId) {
              // Get account info
              const Params = new HttpParams().append('filter', JSON.stringify({include: this._Account.defaultInclude}));
              this._Account.getById(localStorage.AccountId, Params).subscribe(acc => {
                this._Global.Account = acc;
                if (acc.DefaultDivision) this._Global.Division = acc.DefaultDivision;
                else if (acc.Divisions.length > 0) this._Global.Division = acc.Divisions[0];
                observer.next(true);
                observer.complete();
              });
            } else {
              observer.next(false);
              this._Router.navigate(['/login']);
              observer.complete();
            }
          }
        }
    );
  }
}
