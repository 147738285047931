import {HttpParams} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_ASYNC_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/internal/operators';
import {Article} from '../../models/Article/article.object';
import {ArticleService} from '../../models/Article/article.service';
import {Locale} from '../../models/Locales/locale.object';
import {FilterObject} from '../../objects/filter-object';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-article-selector',
  templateUrl: './selector.component.html',
  providers: [
    ArticleService,
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ArticleSelectorComponent), multi: true},
    {provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => ArticleSelectorComponent), multi: true}
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleSelectorComponent implements ControlValueAccessor, OnDestroy {

  // SELECTOR <=> this.ChildControl <=> CONTROLLER (THIS) <=> value (get/set) <=> END component

  Results: Article[];
  ChildControl = new UntypedFormControl();

  @Input() name: string = null; // verplicht ?
  @Input() placeholder = 'Article code';
  @Input() disabled: false;
  @Input() category: string = null;
  @Input() required = false; // Does not work with @param required
  @Input() hideCode = false; // Hides art code and only shows full description
  @Input('filter') filterObject: FilterObject = new FilterObject(); // To filter
  filterCopy: FilterObject = new FilterObject(this.filterObject);
  Selected: Article = null; // Default selected
  Locale: Locale = new Locale({Id: 'en-GB'});

  // V2
  modelChanged: Subject<any> = new Subject<any>();
  childValue: Article = null; // Default selected
  listResults: Article[];
  childControl = new UntypedFormControl();

  // CODE BELOW IS REQUIRED TO REGISTER THE NG_VALUE_ACCESSOR
  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  constructor(private _Article: ArticleService, public _Global: GlobalService) {

    // Subscribe to changes with a debounceTime
    this.modelChanged.pipe(
        debounceTime(500),
        distinctUntilChanged()
    ).subscribe(val => {

      if (val instanceof Article || !val) {
        this.value = val;
      } else {
        this.doSearch(val).subscribe(res => {
          this.listResults = res;

          // Only one result found / multiple
          if (res && res.length === 1) {
            this.childValue = res[0];
            this.value = res[0];
          } else {
            this.validate().subscribe(); // Run validator manually
            this.value = null;
          }
        })
      }
    });
  }

  get value() {
    return this.childValue instanceof Article ? this.childValue : null;
  }

  set value(val) {
    if (!this.childValue && val instanceof Article) this.childValue = val; // Set once on init
    this.onChange(val);
    this.onTouched();
  }

  ngOnDestroy() {
  }

  // Async validator, note the NG_ASYNC_VALIDATORS provider !
  validate(c: UntypedFormControl = null): Observable<any> {

    return new Observable(observer => {
      // Selected from autocomplete list = valid OR empty and NOT required
      if ((this.Selected instanceof Article || (!this.ChildControl.value && !this.required))) {
        this.ChildControl.setErrors(null);
        observer.next(null);
        observer.complete();
      } else {
        this.ChildControl.setErrors({err: 'err'});
        observer.next({'err': true});
        observer.complete();
      }
    });
  }

  // Asynch search observable
  doSearch(e: any): Observable<Article[]> {
    if (e && e.indexOf('%') === -1) e = ['%', e, '%'].join(''); // Add wildcards if not set
    this.filterCopy.include = this.filterObject && this.filterObject.include ? this.filterObject.include : ['Category1', 'Categories', 'Unit', 'Packaging', 'Parent'];
    // const tmpWhere = {and: [{'or': [{'Code': {'like': e}}, {'Description': {'like': e}}, {'search': {'like': e}}]}, {or: [{IsBlocked: false}, {IsBlocked: null}]}]};
    const tmpWhere = {'or': [{'Code': {'like': e}}, {'Description': {'like': e}}, {'search': {'like': e}}]};
    this.filterCopy.where = this.filterObject.where ? {'and': [this.filterObject.where, tmpWhere]} : tmpWhere;
    this.filterCopy.order = 'Order DESC';
    const Params = new HttpParams().append('filter', JSON.stringify(this.filterCopy));
    return this._Article.get(Params);
  }

  updateNgModel(val) {
    this.modelChanged.next(val); // To subscribe on debounce
  }

  displayFn(input: Article) {
    return input && input.Code ? input.Code : input;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    this.value = value;
  }

  checkSelected(): boolean {
    return this.Selected && this.Selected instanceof Article;
  }
}
