import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ArticleService} from '../../models/Article/article.service';
import {BasketLine} from '../../models/BasketLine/basket-line.object';
import {Locale} from '../../models/Locales/locale.object';
import {Promotion} from '../../models/Promotion/promotion.object';
import {PromotionService} from '../../models/Promotion/promotion.service';
import {PromotionBlock} from '../../models/PromotionBlock/promotion-block.object';
import {PromotionOption} from '../../models/PromotionOption/promotion-option.object';
import {GlobalService} from '../../services/global.service';
import {AttachmentControllerComponent} from '../attachment-controller/attachment-controller.component';
import {Attachment} from '../../models/Attachment/attachment.object';

@Component({
  selector: 'app-promotion-editor',
  templateUrl: './promotion-editor.component.html',
  styleUrls: ['./promotion-editor.component.css'],
  providers: [PromotionService, ArticleService]
})
export class PromotionEditorComponent implements OnInit {

  // Original & deep copy
  Original: Promotion = new Promotion;
  Copy: Promotion = new Promotion;
  Locale: Locale = new Locale({Id: 'en-GB'});
  isLoading = false;

  @Input() List: Promotion[] = [];
  @Output() ListChange: EventEmitter<Promotion[]> = new EventEmitter<Promotion[]>();
  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<PromotionEditorComponent>;

  constructor(public _Global: GlobalService, private _Dialog: MatDialog, private _Promotion: PromotionService, private _Article: ArticleService) {
  }

  ngOnInit() {
  }

  updateOrder() {
    let blocks = [];
    this.Copy.Blocks.forEach((block, i) => {
      blocks[Math.round(block.OrderNumber) > 0 ? Math.round(block.OrderNumber - 1) : i] ? blocks.push(block) : blocks[Math.round(block.OrderNumber) > 0 ? Math.round(block.OrderNumber) - 1 : i] = block;
      let options = [];
      block.Options.forEach((option, x) => {
        options[Math.round(option.OrderNumber) > 0 ? Math.round(option.OrderNumber - 1) : x] ? options.push(option) : options[Math.round(option.OrderNumber) > 0 ? Math.round(option.OrderNumber) - 1 : x] = option;
        let lines = [];
        option.BasketLines.forEach((line, y) => {
          lines[Math.round(line.OrderNumber) > 0 ? Math.round(line.OrderNumber - 1) : y] ? options.push(line) : lines[Math.round(line.OrderNumber) > 0 ? Math.round(line.OrderNumber) - 1 : y] = line;
        });
        lines = lines.filter(l => !!l);
        option.BasketLines = lines;
      });
      options = options.filter(o => !!o);
      block.Options = options;
    });
    blocks = blocks.filter(b => !!b);
    this.Copy.Blocks = blocks;
    this.Copy.Blocks.forEach((block, i) => {
      block.OrderNumber = i + 1;
      block.Options.forEach((option, x) => {
        option.OrderNumber = x + 1;
        option.BasketLines.forEach((line, y) => {
          line.OrderNumber = y + 1;
        });
      });
    });
  }

  // Show editor & assign vars
  edit(entry: Promotion = new Promotion) {
    this.Original = entry; // Assign original
    this.Copy = new Promotion(JSON.parse(JSON.stringify(entry))); // Deep copy
    this.dialogRef = this._Dialog.open(this.templateRef, {minWidth: '1500px'});
  }

  save() {
    this.updateOrder();
    this.isLoading = true;
    this._Promotion
        .upsert(this.Copy)
        .subscribe(res => {
          this.Copy = res;
          const found = this.List.find(x => x.Id === this.Copy.Id && x.Id !== null);
          found ? this.List[this.List.indexOf(found)] = this.Copy : this.List.push(this.Copy);
          this.ListChange.emit(this.List);
          this.isLoading = false;
          this._Dialog.getDialogById(this.dialogRef.id).close();
        }, () => this.isLoading = false);
  }

  remove(entry: Promotion) {
    this.isLoading = true;
    this._Promotion
        .remove(entry)
        .subscribe(res => {
          if (this.List) this.List.splice(this.List.indexOf(entry), res.count);
          this.ListChange.emit(this.List);
          this.isLoading = false;
          if (this._Dialog.getDialogById(this.dialogRef.id)) this._Dialog.getDialogById(this.dialogRef.id).close();
        }, () => this.isLoading = false);
  }

  duplicate(promo: Promotion) {
    promo.Id = null;
    promo.Blocks.forEach(b => {
      b.Id = null;
      b.Title.forEach(t => {
        t.Id = null;
        t.ForeignId = null;
      });
      b.Options.forEach(o => {
        o.Id = null;
        o.BasketLines.forEach(l => {
          l.Id = null;
        });
      });
    });
    promo.Title.forEach(t => {
      t.Id = null;
      t.ForeignId = null;
    });
    this.edit(promo);
  }

  addBlock() {
    this.Copy.Blocks.push(new PromotionBlock());
  }

  removeBlock(block: PromotionBlock) {
    this.Copy.Blocks.splice(this.Copy.Blocks.indexOf(block), 1);
  }

  addOption(block: PromotionBlock) {
    block.Options.push(new PromotionOption());
  }

  removeOption(block: PromotionBlock, option: PromotionOption) {
    block.Options.splice(block.Options.indexOf(option), 1);
  }

  addLine(option: PromotionOption) {
    option.BasketLines.push(new BasketLine({Ordered: 1}));
  }

  removeLine(option: PromotionOption, line: BasketLine) {
    option.BasketLines.splice(option.BasketLines.indexOf(line), 1);
  }

  setPath(e: { path: string, Id: string }) {
    this.Copy.Blocks.forEach(x => {
      const found = x.Options.find(y => y.Id === e.Id);
      if (found) found.Image = e.path;
    });
  }

  openPromotionAttachmentSelector(blockIndex: number = null, optionIndex: number = null) {
    const dialog = this._Dialog.open(AttachmentControllerComponent, {
      data: {},
      minWidth: '60vw'
    });

    // If an attachment has been picked
    dialog.afterClosed().subscribe((res: Attachment) => {
      if (res && blockIndex !== null && optionIndex !== null) { // had to add the null values, otherwise if does not work
        this.Copy.Blocks[blockIndex].Options[optionIndex].Image = res.Path;
      } else if (res) this.Copy.Image = res.Path;
    });
  }
}
