import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {GlobalService} from '../../services/global.service';
import {Division} from '../Division/division.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';
import {SupportMessage} from '../SupportMessage/support-message.object';
import {SupportTicket} from './support-ticket.object';

@Injectable()
export class SupportTicketService {

  defaultInclude = [{
    relation: 'SupportMessages',
    scope: {include: {relation: 'Sender'}, where: {or: [{DivisionId: null}, {DivisionId: this._Global.Division.Id}]}}
  }, 'Type', 'Status', 'Account', 'Division'];

  constructor(private http: HttpClient, private _Global: GlobalService) {
  }

  get(ReqParams: HttpParams = null): Observable<SupportTicket[]> {
    return this.http
        .get<SupportTicket[]>(environment.apiUrl + '/SupportTickets', {params: ReqParams}).pipe(
            map(res => <SupportTicket[]>res),
            map(res => {
              return res.map(rel => new SupportTicket(rel));
            }));
  }

  getById(Id: string, ReqParams: HttpParams = null): Observable<SupportTicket> {
    return this.http
        .get<SupportTicket>(environment.apiUrl + '/SupportTickets/' + Id, {params: ReqParams}).pipe(
            map(res => <SupportTicket>res),
            map(res => {
              return new SupportTicket(res);
            }));
  }

  postByTicketId(Id: string, Message: SupportMessage, ReqParams: HttpParams = null) {
    return this.http
        .post(environment.apiUrl + '/SupportTickets/' + Id + '/SupportMessages', JSON.stringify(Message), {params: ReqParams}).pipe(
            map(res => <SupportMessage>res),
            map(res => {
              // Only update available props in relation. (Avoid to add irelevant props)
              for (const prop in res) prop in Message ? Message[prop] = res[prop] : false;
              return Message;
            }));
  }

  updateStatus(ticket: SupportTicket, status: DropdownOption, ReqParams: HttpParams = null) {
    return this.http
        .put(environment.apiUrl + '/SupportTickets/' + ticket.Id + '/Status', JSON.stringify(status), {params: ReqParams}).pipe(
            map(res => <DropdownOption>res),
            map(res => {
              // Only update available props in relation. (Avoid to add irelevant props)
              for (const prop in res) prop in status ? status[prop] = res[prop] : false;
              return status;
            }));
  }

  upsert(Relation: SupportTicket): Observable<SupportTicket> {
    return this.http
        .put(environment.apiUrl + '/SupportTickets', JSON.stringify(Relation)).pipe(
            map(res => <SupportTicket>res),
            map(res => {
              // Only update available props in relation. (Avoid to add irelevant props)
              for (const prop in res) if (prop in Relation) Relation[prop] = res[prop];
              return new SupportTicket(Relation);
            }));
  }

  upsertWithMessages(Relation: SupportTicket): Observable<SupportTicket> {
    return this.http
        .post(environment.apiUrl + '/SupportTickets/createWithMessages', JSON.stringify(Relation)).pipe(
            map(res => <SupportTicket>res),
            map(res => {
              // Only update available props in relation. (Avoid to add irelevant props)
              for (const prop in res) if (prop in Relation) Relation[prop] = res[prop];
              return Relation;
            }));
  }

  sendToMultiple(payload: { ticket: SupportTicket, divisions: Division[] }): Observable<SupportTicket[]> {
    return this.http
        .post(environment.apiUrl + '/SupportTickets/sendToMultiple', JSON.stringify(payload)).pipe(
            map(res => <SupportTicket[]>res),
            map(res => {
              // Only update available props in relation. (Avoid to add irelevant props)
              return res.map(rel => new SupportTicket(rel));
            }));
  }

  remove(Relation: SupportTicket): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/SupportTickets/' + Relation.Id);
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/SupportTickets/count', {params: ReqParams}).pipe(
            map(res => <{ count: number }>res));
  }

}
