import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['button.style.css']
})
export class ButtonComponent implements OnInit {

  @Input() disabled = false;
  @Input() warning = false;
  @Input() warningTooltip = null;
  @Input() isLoading = false;
  @Input() title = 'Save';
  @Input() type = 'submit';
  @Input() nameAttr: string = null;
  @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  emitOnClick(e: MouseEvent) {
    this.onClick.emit(e);
  }
}
