import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {Article} from './article.object';

@Injectable()
export class ArticleService {

  defaultInclude = [
    'Categories', 'Unit', 'Packaging', 'PackageType', 'Category1', 'Parent', 'Modifier', 'Creator',
    {'Locations': ['SupplyPlant', 'Article']}, 'Division', 'Groups',
    {
      relation: 'Children',
      scope: {
        order: 'Order ASC',
        include: ['Unit', 'Categories', 'Packaging', 'PackageType', {'Locations': ['SupplyPlant', 'Article']}]
      }
    }];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<Article[]> {
    return this.http
      .get<Article[]>(environment.apiUrl + '/Articles/search', {params: ReqParams})
      .pipe(
        map(res => <Article[]>res),
        map(res => {
          return res.map(rel => new Article(rel));
        })
      );
  }

  getWithRelationPrices(ReqParams: HttpParams = null): Observable<Article[]> {
    return this.http
      .get<Article[]>(environment.apiUrl + '/Articles/searchPrices', {params: ReqParams})
      .pipe(
        map(res => <Article[]>res),
        map(res => {
          return res.map(rel => new Article(rel));
        })
      );
  }

  searchRepresentativePrices(ReqParams: HttpParams = null): Observable<Article[]> {
    return this.http
      .get<Article[]>(environment.apiUrl + '/Articles/searchRepresentativePrices', {params: ReqParams})
      .pipe(
        map(res => <Article[]>res),
        map(res => {
          return res.map(rel => new Article(rel));
        })
      );
  }

  upsert(Entry: Article): Observable<Article> {
    return this.http
      .put<Article>(environment.apiUrl + '/Articles', JSON.stringify(Entry))
      .pipe(
        map(res => <Article>res),
        map(res => {
          // Modify original doc without destroying related nexted objects
          for (const prop in res) if (prop in Entry) Entry[prop] = res[prop];
          return new Article(Entry);
        })
      );
  }

  patch(Entry: any): Observable<any> {
    return this.http
      .patch<any>(environment.apiUrl + '/Articles', JSON.stringify(Entry));
  }

  patchAll(input: Article[]): Observable<Article[]> {
    return this.http
      .put<Article[]>(environment.apiUrl + '/Articles/patchOrCreateAll', JSON.stringify(input))
      .pipe(
        map(res => <Article[]>res),
        map(res => {
          // Modify original doc without destroying related nexted objects
          for (const prop in res) {
            if (prop in input) {
              Object.assign(input[prop], res[prop]);
            }
          }
          return input;
        })
      );
  }

  remove(Entry: Article): Observable<{ count: number }> {
    return this.http
      .delete<{ count: number }>(environment.apiUrl + '/Articles/' + Entry.Id);
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
      .get(environment.apiUrl + '/Articles/count', {params: ReqParams})
      .pipe(
        map(res => <{ count: number }>res)
      );
  }

  countWithRepresentative(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
      .get(environment.apiUrl + '/Articles/searchRepresentativeCount', {params: ReqParams})
      .pipe(
        map(res => <{ count: number }>res)
      );
  }

  exportXlsx(ReqParams: HttpParams = null): Observable<Blob> {
    return this.http
      .get(environment.apiUrl + '/Articles/export', {params: ReqParams, responseType: 'blob'})
      .pipe(
        map(res => {
          return new Blob([res], {type: 'application/vnd.ms-excel'});
        })
      );
  }

  pushToExact(input: Article): Observable<Article> {
    return this.http
      .post(environment.apiUrl + '/Articles/pushToExact', JSON.stringify(input))
      .pipe(
        map(res => {
          for (const prop in res) if (prop in input) input[prop] = res[prop];
          return new Article(input);
        })
      );
  }

  pullFromExact(input: Article): Observable<Article> {
    return this.http
      .post(environment.apiUrl + '/Articles/pullFromExact', JSON.stringify(input))
      .pipe(
        map(res => {
          for (const prop in res) if (prop in input) input[prop] = res[prop];
          return new Article(input);
        })
      );
  }

  sortArticlesByOrdered() {
    return this.http.put(environment.apiUrl + '/Articles/sortArticlesByOrdered', null);
  }
}
