import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {GroupSubscription} from './group-subscription.object';

@Injectable()
export class GroupSubscriptionService {

  defaultInclude: any[] = ['MaritalStatus', {'Locale': {'Country': 'ShipmentArticle'}}, {'SubscriptionDivisions': ['Division', 'GroupSubscription']}, {'ActivationSubscriptions': ['Status', {'Activation': ['Relation', 'Type', 'Status']}]}, 'Type', {'Address': {'Country': 'ShipmentArticle'}}, 'Role', {'DefaultDivision': {'Locale': {'Country': 'ShipmentArticle'}}}, {'Divisions': {'Locale': {'Country': 'ShipmentArticle'}}}];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<GroupSubscription[]> {
    return this.http
        .get<GroupSubscription[]>(environment.apiUrl + '/GroupSubscriptions', {params: ReqParams}).pipe(
            map(res => <GroupSubscription[]>res),
            map(res => {
              return res.map(rel => new GroupSubscription(rel));
            }),);
  }

  getById(Id: string, ReqParams: HttpParams = null): Observable<GroupSubscription> {
    return this.http
        .get<GroupSubscription>(environment.apiUrl + '/GroupSubscriptions/' + Id, {params: ReqParams}).pipe(
            map(res => <GroupSubscription>res),
            map(res => {
              return new GroupSubscription(res);
            }),);
  }

  upsert(Entry: GroupSubscription): Observable<GroupSubscription> {
    return this.http
        .put<GroupSubscription>(environment.apiUrl + '/GroupSubscriptions', JSON.stringify(Entry)).pipe(
            map(res => <GroupSubscription>res),
            map(res => {
              // Modify original doc without destroying related objects (doclines)
              for (const prop in res) if (prop in Entry) Entry[prop] = res[prop];
              return new GroupSubscription(Entry);
            }),);

  }

  remove(Entry: GroupSubscription): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/GroupSubscriptions/' + Entry.Id);
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/GroupSubscriptions/count', {params: ReqParams})
        .pipe(
            map(res => <{ count: number }>res)
        );
  }
}
