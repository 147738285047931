import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Division} from './division.object';

@Injectable()
export class DivisionService {

  defaultInclude = [{'AccountDivisions': ['Account', 'Division']}, {'Locale': {'Country': 'ShipmentArticle'}}, 'Address', 'Accounts'];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<Division[]> {
    return this.http
        .get<Division[]>(environment.apiUrl + '/Divisions', {params: ReqParams}).pipe(
            map(res => <Division[]>res),
            map(res => {
              return res.map(div => new Division(div));
            }));
  }

  getById(Id: string, ReqParams: HttpParams = null): Observable<Division> {
    return this.http
        .get<Division>(environment.apiUrl + '/Divisions/' + Id, {params: ReqParams}).pipe(
            map(res => <Division>res),
            map(res => {
              return new Division(res);
            }));
  }

  upsert(Entry: Division) {
    return this.http
        .put(environment.apiUrl + '/Divisions', JSON.stringify(Entry)).pipe(
            map(res => <Division>res),
            map(res => {
              // Only update available props in relation. (Avoid to add irelevant props)
              for (const prop in res) if (prop in Entry) Entry[prop] = res[prop];
              return new Division(Entry);
            }),
        );
  }

  register(Entry: Division) {
    return this.http
        .post(environment.apiUrl + '/Divisions/register', JSON.stringify(Entry)).pipe(
            map(res => <Division>res),
            map(res => {
              // Only update available props in relation. (Avoid to add irelevant props)
              for (const prop in res) if (prop in Entry) Entry[prop] = res[prop];
              return Entry;
            }),
        );
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/Divisions/count', {params: ReqParams})
        .pipe(
            map(res => <{ count: number }>res)
        );
  }

  remove(Entry: Division): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/Divisions/' + Entry.Id);
  }

}
