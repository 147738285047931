import {Attachment} from '../Attachment/attachment.object';
import {DefaultModel} from '../Default/default.object';
import {Locale} from '../Locales/locale.object';
import {Mail} from '../Mail/mail.object';

export class MailTranslation extends DefaultModel {

  Locale: Locale = null;
  Mail: Mail = null;
  Subject: string = null;
  SenderName: string = null;
  SenderMail: string = null;
  Content: string = null;
  Attachments: Attachment[] = [];
  Sent = 0;
  randomNumber: number = null;


  constructor(data: any = null) {
    super();
    this.randomNumber = Math.random();

    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];
    this.Locale = this.Locale ? new Locale(this.Locale) : null;
    this.Mail = this.Mail ? new Mail(this.Mail) : null;

    this.Attachments.forEach((item, index) => {
      this.Attachments[index] = new Attachment(item);
    });
  }
}
