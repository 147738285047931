import {Article} from '../Article/article.object';
import {DefaultModel} from '../Default/default.object';
import {Division} from '../Division/division.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';

export class Location extends DefaultModel {

  Division: Division = null;
  Article: Article = null;
  SupplyPlant: DropdownOption = null;
  InStockDate: Date = null;

  constructor(data: any = null) {

    super(data);

    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.Division = this.Division ? new Division(this.Division) : null;
    this.Article = this.Article ? new Article(this.Article) : null;
    this.SupplyPlant = this.SupplyPlant ? new DropdownOption(this.SupplyPlant) : null;
  }
}
