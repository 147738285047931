import {ACL} from '../ACL/acl.object';

export class Role {

  Id: string = null;
  Description: string = null;
  API_ACLs: ACL[] = [];
  GUI_ACLs: ACL[] = [];

  constructor(data: any = null) {
    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    if (this.API_ACLs) {
      this.API_ACLs.forEach((item, index) => {
        this.API_ACLs[index] = new ACL(item);
      });
    }

    if (this.GUI_ACLs) {
      this.GUI_ACLs.forEach((item, index) => {
        this.GUI_ACLs[index] = new ACL(item);
      });
    }
  }
}
