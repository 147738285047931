import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {ACL} from './acl.object';

@Injectable()
export class ACLService {

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<ACL[]> {
    return this.http
        .get<ACL[]>(environment.apiUrl + '/ACLs', {params: ReqParams})
        .pipe(
            map(res => <ACL[]>res),
            map(res => {
              return res.map(rel => new ACL(rel));
            })
        );
  }

  getById(Id: string, ReqParams: HttpParams = null): Observable<ACL> {
    return this.http
        .get<ACL>(environment.apiUrl + '/ACLs/' + Id, {params: ReqParams})
        .pipe(
            map(res => <ACL>res),
            map(res => {
              return new ACL(res);
            })
        );
  }

  upsert(Relation: ACL): Observable<ACL> {
    return this.http
        .put(environment.apiUrl + '/ACLs', JSON.stringify(Relation))
        .pipe(
            map(res => <ACL>res),
            map(res => {
              // Only update available props in relation. (Avoid to add irelevant props)
              for (const prop in res) if (prop in Relation) Relation[prop] = res[prop];
              return new ACL(Relation);
            })
        );
  }

  remove(Relation: ACL): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/ACLs/' + Relation.Id);
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/ACLs/count', {params: ReqParams})
        .pipe(
            map(res => <{ count: number }>res)
        );
  }

}
