import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ACL} from '../../models/ACL/acl.object';
import {ACLService} from '../../models/ACL/acl.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-acl-editor',
  templateUrl: './acl-editor.component.html',
  styleUrls: ['./acl-editor.component.css'],
  providers: [ACLService]
})
export class AclEditorComponent implements OnInit {


  // Original list to modify
  @Input() List: ACL[] = [];
  @Output() ListChange: EventEmitter<ACL[]> = new EventEmitter<ACL[]>();

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<AclEditorComponent>;

  // Original and copy;
  Original: ACL = new ACL();
  Copy: ACL = new ACL();

  isLoading = false;

  constructor(private _Dialog: MatDialog, private _ACL: ACLService, private _CdRef: ChangeDetectorRef, public _Global: GlobalService) {
  }

  ngOnInit() {

  }

  edit(input: ACL = new ACL) {
    this.Original = input; // Store original
    this.Copy = new ACL(JSON.parse(JSON.stringify(input)));
    this.dialogRef = this._Dialog.open(this.templateRef);
    this._CdRef.detectChanges();
  }

  remove(object: ACL) {
    this.isLoading = true;
    this._ACL.remove(object).subscribe(res => {
      if (this.List) this.List.splice(this.List.findIndex(x => x.Id === object.Id), res.count);
      this.ListChange.emit(this.List);
      this.isLoading = false;
    }, () => this.isLoading = false);
  }

  save(object: ACL = this.Copy) {
    this.isLoading = true;
    this._ACL.upsert(object).subscribe(res => {
      this.isLoading = false;
      this.Copy = res;
      Object.assign(this.Original, res);
      const found = this.List.find(x => x.Id === res.Id);
      found ? Object.assign(found, res) : this.List.push(res); // Modify or push array list
      this.ListChange.emit(this.List);
      this._Dialog.getDialogById(this.dialogRef.id).close();
    }, err => {
      this.isLoading = false;
    });
  }

  compareWith(o1: any, o2: any) {
    return o1 === o2 || o1 && o2 && o1.Id && o2.Id && o1.Id === o2.Id;
  }


}
