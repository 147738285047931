import {Article} from '../Article/article.object';
import {BasketLine} from '../BasketLine/basket-line.object';
import {DefaultModel} from '../Default/default.object';
import {Document} from '../Document/document.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';
import {Promotion} from '../Promotion/promotion.object';
import {PromotionCode} from '../PromotionCode/promotion-code.object';

export class DocumentLines extends DefaultModel {

  Document: Document = null;
  Article: Article = null;
  Discount = 0;
  Description: string = null;
  LineNumber: number = null;
  Ordered: number = null;
  Delivered = 0;
  Unit: DropdownOption = null;
  UnitPrice: number = null;
  DeliveryDate: Date = null;
  AmountDC: number = null;
  VatAmountDC: number = null;
  VATCode: DropdownOption = new DropdownOption({Id: '32a685b9-71b1-4fc2-8d74-a9d9398c8f64', FreeNummericField1: 0.21, Value: 'Omzet 21%'}); // Default 21%
  ArticleCode: string = null;
  CompanCode: string = null;
  DueDate: Date = null;
  DocNumber: string = null;
  DiscountReason: DropdownOption = null;
  Promotion: Promotion = null;
  PromotionCode: PromotionCode = null;

  // For NgModelgroups
  randomNumber: number;

  constructor(data: any = null) {

    super(data);

    for (const prop in data) if (prop in this) this[prop] = data[prop];

    // ALWAYS generate random number (for ngModel group primary key (identifier))
    this.randomNumber = Math.random();

    this.PromotionCode = this.PromotionCode ? new PromotionCode(this.PromotionCode) : null;
    this.Promotion = this.Promotion ? new Promotion(this.Promotion) : null;
    this.Article = this.Article ? new Article(this.Article) : null;
    this.VATCode = this.VATCode ? new DropdownOption(this.VATCode) : null;
    this.DiscountReason = this.DiscountReason ? new DropdownOption(this.DiscountReason) : null;
    this.Unit = this.Unit ? new DropdownOption(this.Unit) : null;
  }

  // Maps the ActivationArticle into a BasketLine object
  asBasketLine(): BasketLine {
    return new BasketLine({
      Id: this.Id,
      Original: this,
      Article: this.Article,
      Ordered: this.Ordered,
      UnitPrice: this.UnitPrice,
      PromotionCode: this.PromotionCode
    });
  }
}
