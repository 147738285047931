import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {Widget} from '../../models/Widget/widget.object';

@Component({
  selector: 'app-chartjs-wrapper',
  templateUrl: './chartjs-wrapper.component.html',
  styleUrls: ['./chartjs-wrapper.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ChartjsWrapperComponent), multi: true}
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartjsWrapperComponent implements OnInit {

  @Input() title = '';
  @Input() description = '';
  @Input() type = 'bar';
  @Input() info: string = null;
  @Input() options: object = null;
  @Input() disabledGraphs: string[] = [];
  @Output() getData: EventEmitter<any> = new EventEmitter<any>();
  showTable = false;
  showInformation = false;
  Widget: Widget = null;
  data: any = null;

  constructor(private _CdRef: ChangeDetectorRef) {
  }

  _widget: Widget = null;

  get widget() {
    return this.Widget;
  }

  @Input()
  set widget(val) {
    this.Widget = val;
  }

  ngOnInit() {
  }


  rerender() { // This is a workaround to get the chart updated (redraw chart)
    this.data = null;
    const arr = [];
    if (this.type !== 'line' && this.type !== 'bar' && Number.isInteger(this.widget.datasets[0].data[0])) {
      this.widget.datasets.forEach(d => {
        let index = 0;
        d.data.forEach(res => {
          if (!arr[index]) arr[index] = 0;
          arr[index] += res;
          index++;
        });
      });
      this.data = [{data: arr}];
    }
    this.showTable = !this.showTable;
    setTimeout(() => {
      this.showTable = !this.showTable;
      this._CdRef.detectChanges();
    });
  }

  downloadXlsx() {

    // Create 2dimensional JSON string
    const tmpJson = [];

    let i = 0;
    this.Widget.labels.forEach(label => {
      const tmpRow = {};
      tmpRow['x'] = label;
      this.Widget.datasets.forEach(ds => {
        tmpRow[ds.label] = ds.data[i];
      });
      i++;
      tmpJson.push(tmpRow);
    });


    // Create excell from JSON string
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tmpJson);
    const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    const data: Blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    });
    FileSaver.saveAs(data, '_export_' + new Date().getTime() + '.xlsx');
  }
}
