export class FilterObject {

  where: object = {};
  skip = 0;
  limit = 25; // Default pagesize
  order: string = null;
  loading: boolean = false;
  include: any = [];
  show: boolean = true;
  count = 0; // This is not a part of LB filterobject, but is used for the paginator.

  constructor(data: any = null) {
    for (const prop in data) if (prop in this) this[prop] = data[prop];
  }
}
