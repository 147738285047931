import {HttpParams} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Locale} from '../../models/Locales/locale.object';
import {LocaleService} from '../../models/Locales/locale.service';
import {Translation} from '../../models/Translation/translation.object';

@Component({
  selector: 'app-translation-field-editor',
  templateUrl: './editor.component.html',
  providers: [LocaleService]
})
export class TranslationFieldEditorComponent implements OnInit {

  Locales: Locale[] = [];
  @Input() placeholder: string = null;
  @Input() List: Translation[] = [];
  @Output() ListChange: EventEmitter<Translation[]> = new EventEmitter<Translation[]>();

  constructor(private _Locale: LocaleService) {
  }

  ngOnInit() {
    const Params = new HttpParams().append('filter', JSON.stringify({order: 'Id ASC', include: 'Country', where: {IsActive: true}}));
    this._Locale.get(Params).subscribe(res => this.Locales = res); // Get all locales
  }

  getTranslation(input: Locale): string {
    const found = this.List.find(x => x.Locale.Id === input.Id);
    return found ? found.Value : null;
  }

  setTranslation(locale: Locale, value: string) {
    const found = this.List.find(x => x.Locale.Id === locale.Id);
    found ? found.Value = value : this.List.push(new Translation({Locale: locale, Value: value}));
    this.ListChange.emit(this.List);
  }
}
