import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeNlBe from '@angular/common/locales/nl-BE';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DragulaModule} from 'ng2-dragula';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ArticleSortWizardComponent} from './dialogs/article-sort-wizard/article-sort-wizard.component';
import {AccountService} from './models/Account/account.service';
import {SharedModule} from './modules/shared/shared.module';
import {AuthenticationGuard} from './services/authentication.guard.service';
import {HttpService} from './services/http.service';
import {HTTPListenerService, HTTPStatusService} from './services/loader.service';

const RxJS_Services = [HTTPListenerService, HTTPStatusService];
registerLocaleData(localeNlBe);

@NgModule({
  declarations: [
    // Basic parts
    AppComponent,
    ArticleSortWizardComponent,
  ],
  imports: [
    DragulaModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FlexLayoutModule,
    SharedModule
  ],
  providers: [
    AuthenticationGuard,
    AccountService,
    {provide: LOCALE_ID, useValue: 'nl-BE'},
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: HttpService, multi: true},
    RxJS_Services,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPListenerService,
      multi: true
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: true}
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
