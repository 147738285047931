export class WidgetList {

  Id: number = 0;
  Title: string = null;
  Description: string = null;

  constructor(data: any = null) {
    for (let prop in data) prop in this ? this[prop] = data[prop] : false;
  }
}
