import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {Contact} from './contact.object';

@Injectable()
export class ContactService {

  defaultInclude = ['Relation', 'Type'];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<Contact[]> {

    return this.http
        .get(environment.apiUrl + '/Contacts', {params: ReqParams})
        .pipe(
            map(res => <Contact[]>res),
            map(res => {
              return res.map(rel => new Contact(rel));
            })
        );
  }

  upsert(contact: Contact): Observable<Contact> {
    return this.http
        .put(environment.apiUrl + '/Contacts', JSON.stringify(contact))
        .pipe(
            map(res => <Contact>res),
            map(res => {
              // Only update available props in relation. (Avoid to add irelevant props)
              for (const prop in res) prop in contact ? contact[prop] = res[prop] : false;
              return new Contact(contact);
            })
        );
  }

  remove(Contact: Contact): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/Contacts/' + Contact.Id);
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/Contacts/count', {params: ReqParams})
        .pipe(
            map(res => <{ count: number }>res)
        );
  }

  exportXlsx(ReqParams: HttpParams = null): Observable<Blob> {
    return this.http
        .get(environment.apiUrl + '/Contacts/export', {params: ReqParams, responseType: 'blob'})
        .pipe(
            map(res => {
              return new Blob([res], {type: 'application/vnd.ms-excel'});
            })
        );
  }
}
