import {Account} from '../Account/account.object';
import {ActivationAccount} from '../ActivationAccount/activation-account.object';
import {ActivationArticle} from '../ActivationArticle/activation-article.object';
import {ActivationEntry} from '../ActivationEntry/activation-entry.object';
import {Address} from '../Address/addresses.object';
import {Attachment} from '../Attachment/attachment.object';
import {BasketLine} from '../BasketLine/basket-line.object';
import {CalendarObject} from '../Calender/calendar.object';
import {Contact} from '../Contact/contact.object';
import {DefaultModel} from '../Default/default.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';
import {Locale} from '../Locales/locale.object';
import {Relation} from '../Relation/relation.object';
import {Score} from '../Score/score.object';

export class Activation extends DefaultModel {

  Relation: Relation = null;
  Type: DropdownOption = null;
  Address: Address = null;
  Contact: Contact = null;
  Representative: Account = null;
  Status: DropdownOption = null;
  Locale: Locale = null;
  Start: Date = null;
  End: Date = null;
  Instructions: string = null;
  ShopRemarks: string = null;
  AmountOfStudents = 1;
  IsAutomaticallyNotified = true;
  ExtraEmails: string[] = [];

  ActivationAccounts: ActivationAccount[] = [];
  ActivationArticles: ActivationArticle[] = [];
  ActivationEntries: ActivationEntry[] = [];
  Attachments: Attachment[] = [];
  Scores: Score[] = [];

  constructor(data: any = null) {

    super(data);

    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.Relation = this.Relation ? new Relation(this.Relation) : null;
    this.Type = this.Type ? new DropdownOption(this.Type) : null;
    this.Status = this.Status ? new DropdownOption(this.Status) : null;
    this.Locale = this.Locale ? new Locale(this.Locale) : null;
    this.Address = this.Address ? new Address(this.Address) : null;
    this.Contact = this.Contact ? new Contact(this.Contact) : null;
    this.Representative = this.Representative ? new Account(this.Representative) : null;

    this.ActivationAccounts.forEach((item, index) => {
      this.ActivationAccounts[index] = new ActivationAccount(item);
    });

    this.ActivationArticles.forEach((item, index) => {
      this.ActivationArticles[index] = new ActivationArticle(item);
    });

    this.ActivationEntries.forEach((item, index) => {
      this.ActivationEntries[index] = new ActivationEntry(item);
    });

    this.Attachments.forEach((item, index) => {
      this.Attachments[index] = new Attachment(item);
    });

    this.Scores.forEach((item, index) => {
      this.Scores[index] = new Score(item);
    });

    if (!this.ExtraEmails || !(this.ExtraEmails instanceof Array)) {
      this.ExtraEmails = [];
    }
  }

  asCalendarObject(): CalendarObject {
    return new CalendarObject({
      id: this.Id,
      start: this.Start,
      end: this.End,
      title: this.Relation ? this.Relation.Name : '',
      color: this.Status.Color,
      instance: new Activation(this)
    });
  }

  // Convert ActivationArticles to BasketLines
  // Type is the ActivationArticle type (free / sample / sold) to filter the list
  // If type is not set, there is no filter applied
  getActivationArticlesAsBasketLines(type: DropdownOption = null): BasketLine[] {
    const tmpArr: BasketLine[] = [];
    this.ActivationArticles.forEach(article => {
      if (!type || article.Type.Id === type.Id) tmpArr.push(article.asBasketLine());
    });
    console.warn(tmpArr);
    return tmpArr;
  }

  // If type isset, only update the type, otherwise update all
  setActivationArticlesByBasketLines(input: BasketLine[] = [], type: DropdownOption = null): void {

    // Step 1: Create a temp array, fill them (without the removed items), and sets them
    const tmpArr: ActivationArticle[] = [];
    this.ActivationArticles.forEach(line => {
      if (input.find(x => x.Original === line) || line.Type.Id !== type.Id) tmpArr.push(line);
    });
    this.ActivationArticles = tmpArr;

    // Step 2: Iterate each line, search for an update, if not found, add as new line.
    input.forEach(line => {
      const found = this.ActivationArticles.find(x => x === line.Original);
      found ? Object.assign(found, line.asActivationArticle()) : this.ActivationArticles.push(line.asActivationArticle());
    });
  }


}
