import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {Group} from '../../models/Group/group.object';
import {GroupService} from '../../models/Group/group.service';
import {GroupSubscription} from '../../models/GroupSubscription/group-subscription.object';
import {GroupSubscriptionService} from '../../models/GroupSubscription/group-subscription.service';

@Component({
  selector: 'app-group-subscription-wrapper',
  templateUrl: './group-wrapper.component.html',
  styleUrls: ['./group-wrapper.component.css'],
  providers: [GroupSubscriptionService]
})
export class GroupWrapperComponent implements OnInit {

  @Input() DefaultSubscription: GroupSubscription = new GroupSubscription();

  @Input() List: Group[];
  @Output() ListChange: EventEmitter<Group[]> = new EventEmitter<Group[]>();

  observableOfGroups: Observable<Group[]>;

  constructor(private _Group: GroupService, private _GroupSubscription: GroupSubscriptionService) {
    this.observableOfGroups = _Group.get();
  }

  ngOnInit() {

  }

  notInList(input: Group): boolean {
    return this.List.findIndex(x => x.Id === input.Id) === -1;
  }

  addGroup(input: Group) {
    const tmpSubscription: GroupSubscription = new GroupSubscription(JSON.parse(JSON.stringify(this.DefaultSubscription)));
    tmpSubscription.Group = input;

    this._GroupSubscription.upsert(tmpSubscription).subscribe(res => {
      this.List.push(tmpSubscription.Group);
      this.ListChange.emit(this.List);
    });
  }

  removeGroup(input: Group) {

    // Delete by Relation
    if (this.DefaultSubscription.Relation) {
      return this._Group.removeByRelation(input, this.DefaultSubscription.Relation).subscribe(() => {
        this.List.splice(this.List.indexOf(input), 1);
        this.ListChange.emit(this.List);
      });
    }

    // Delete by Article
    if (this.DefaultSubscription.Article) {
      return this._Group.removeByArticle(input, this.DefaultSubscription.Article).subscribe(() => {
        this.List.splice(this.List.indexOf(input), 1);
        this.ListChange.emit(this.List);
      });
    }

  }
}
