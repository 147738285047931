import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AccountDivision} from './account-division.object';

@Injectable()
export class AccountDivisionService {

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<AccountDivision[]> {
    return this.http
        .get<AccountDivision[]>(environment.apiUrl + '/AccountDivisions', {params: ReqParams}).pipe(
            map(res => <AccountDivision[]>res),
            map(res => {
              return res.map(rel => new AccountDivision(rel));
            }));
  }

  upsert(Entry: AccountDivision): Observable<AccountDivision> {
    return this.http
        .put<AccountDivision>(environment.apiUrl + '/AccountDivisions', JSON.stringify(Entry)).pipe(
            map(res => <AccountDivision>res),
            map(res => {
              // Modify original doc without destroying related objects (doclines)
              for (const prop in res) if (prop in Entry) Entry[prop] = res[prop];
              return new AccountDivision(Entry);
            }));

  }

  remove(Entry: AccountDivision): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/AccountDivisions/' + Entry.Id);
  }

  removeWithParams(Reqparams: HttpParams) {
    return this.http
      .delete(environment.apiUrl + '/AccountDivisions/', {params: Reqparams});
  }
}
