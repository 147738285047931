import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {Widget} from '../Widget/widget.object';
import {Activation} from './activation.object';

@Injectable()
export class ActivationService {

  defaultInclude = [
    'Division',
    'Accounts',
    'Representative',
    'Scores',
    'Modifier',
    'Creator',
    {'ActivationAccounts': ['Account', 'Status', {'Activation': [{'Contact': ['Relation', 'Type', {'Locale': {'Country': 'ShipmentArticle'}}]}, {'Address': [{'Country': 'ShipmentArticle'}, 'Relation', 'Type']}]}]},
    {'Articles': ['Unit', 'Packaging']},
    {'ActivationArticles': ['Account', {'Article': ['Unit', 'Packaging']}, 'Type', 'Activation']},
    {'ActivationEntries': ['Type', 'Activation']},
    {'Address': [{'Country': 'ShipmentArticle'}, 'Relation', 'Type']},
    {'Locale': {'Country': 'ShipmentArticle'}},
    {'Contact': ['Relation', 'Type', {'Locale': {'Country': 'ShipmentArticle'}}]},
    'Status',
    'Type',
    {
      'Relation': [
        {
          relation: 'Addresses',
          scope: {
            include: ['Relation', 'CarrierServiceLevel', 'SupplyPlant', 'CarrierServiceLevelOther', 'Type', {'Country': 'ShipmentArticle'}],
            where: {TypeId: {neq: 'd5b0344f-e908-4a59-953f-a9ae1bbbcaad'}}
          }
        }, 'Contacts']
    }];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<Activation[]> {
    return this.http
        .get<Activation[]>(environment.apiUrl + '/Activations/search', {params: ReqParams})
        .pipe(
            map(res => <Activation[]>res),
            map(res => {
              return res.map(rel => new Activation(rel));
            })
        );
  }

  getById(Id: string, ReqParams: HttpParams = null): Observable<Activation> {
    return this.http
        .get<Activation>(environment.apiUrl + '/Activations/' + Id, {params: ReqParams})
        .pipe(
            map(res => <Activation>res),
            map(res => {
              return new Activation(res);
            })
        );
  }

  upsert(input: Activation): Observable<Activation> {
    return this.http
        .put<Activation>(environment.apiUrl + '/Activations', JSON.stringify(input))
        .pipe(
            map(res => <Activation>res),
            map(res => {
              // Modify original doc without destroying related nexted objects
              for (const prop in res) if (prop in input) input[prop] = res[prop];
              return new Activation(input);
            })
        );
  }

  remove(input: Activation): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/Activations/' + input.Id);
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/Activations/count', {params: ReqParams})
        .pipe(
            map(res => <{ count: number }>res)
        );
  }

  /* Widgets */
  getActivationsPerMonth(ReqParams: HttpParams = null): Observable<Widget> {
    return this.http
        .get<Widget>(environment.apiUrl + '/Activations/getActivationsPerMonth', {params: ReqParams});
  }

  getActivationsPerCountry(ReqParams: HttpParams = null): Observable<Widget> {
    return this.http
        .get<Widget>(environment.apiUrl + '/Activations/getActivationsPerCountry', {params: ReqParams});
  }

  getActiveStudentsPerCountry(ReqParams: HttpParams = null): Observable<Widget> {
    return this.http
        .get<Widget>(environment.apiUrl + '/Activations/getActiveStudentsPerCountry', {params: ReqParams});
  }

  getDogBrands(ReqParams: HttpParams = null): Observable<Widget> {
    return this.http
        .get<Widget>(environment.apiUrl + '/Activations/getDogBrands', {params: ReqParams});
  }

  getDogBreeds(ReqParams: HttpParams = null): Observable<Widget> {
    return this.http
        .get<Widget>(environment.apiUrl + '/Activations/getDogBreeds', {params: ReqParams});
  }

  getAcquisitionPerMonth(ReqParams: HttpParams = null): Observable<Widget> {
    return this.http
        .get<Widget>(environment.apiUrl + '/Activations/getAcquisitionPerMonth', {params: ReqParams});
  }

  getArticlesByType(ReqParams: HttpParams = null): Observable<Widget> {
    return this.http
        .get<Widget>(environment.apiUrl + '/Activations/getArticlesByType', {params: ReqParams});
  }

  exportXlsx(ReqParams: HttpParams = null): Observable<Blob> {
    return this.http
        .get(environment.apiUrl + '/Activations/export', {params: ReqParams, responseType: 'blob'})
        .pipe(
            map(res => {
              return new Blob([res], {type: 'application/vnd.ms-excel'});
            })
        );
  }
}
