import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {ActivationArticle} from './activation-article.object';

@Injectable()
export class ActivationArticleService {

  defaultInclude = ['Activation', {'Article': ['Unit', 'Packaging']}, 'Type', {'Locations': ['SupplyPlant', 'Article']}];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<ActivationArticle[]> {
    return this.http
        .get<ActivationArticle[]>(environment.apiUrl + '/ActivationArticles', {params: ReqParams})
        .pipe(
            map(res => <ActivationArticle[]>res),
            map(res => {
              return res.map(rel => new ActivationArticle(rel));
            })
        );
  }

  upsert(input: ActivationArticle): Observable<ActivationArticle> {
    return this.http
        .put<ActivationArticle>(environment.apiUrl + '/ActivationArticles', JSON.stringify(input))
        .pipe(
            map(res => <ActivationArticle>res),
            map(res => {
              // Modify original doc without destroying related nexted objects
              for (const prop in res) if (prop in input) input[prop] = res[prop];
              return new ActivationArticle(input);
            })
        );
  }

  updateAndRemove(payload: { input: ActivationArticle[], id: string }): Observable<ActivationArticle[]> {
    return this.http
        .put<ActivationArticle[]>(environment.apiUrl + '/ActivationArticles/updateAndRemove', JSON.stringify(payload))
        .pipe(
            map(res => <ActivationArticle[]>res),
            map(res => {
              // Modify original doc without destroying related nexted objects
              for (const prop in res) {
                if (prop in payload.input) {
                  Object.assign(payload.input[prop], res[prop]);
                }
              }
              return payload.input;
            })
        );
  }

  remove(input: ActivationArticle): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/ActivationArticles/' + input.Id);
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/ActivationArticles/count', {params: ReqParams})
        .pipe(
            map(res => <{ count: number }>res)
        );
  }
}
