import {Account} from '../Account/account.object';
import {Activation} from '../Activation/activation.object';
import {Address} from '../Address/addresses.object';
import {Contact} from '../Contact/contact.object';
import {DefaultModel} from '../Default/default.object';
import {Document} from '../Document/document.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';
import {Group} from '../Group/group.object';
import {Locale} from '../Locales/locale.object';
import {PriceList} from '../PriceList/price-list.object';
import {Timeline} from '../Timeline/timeline.object';

export class Relation extends DefaultModel {

  ExternalId: string = null;
  Type: DropdownOption = null;
  SubType: DropdownOption = null;
  RetailChain: DropdownOption = null;
  Code: string = null;
  Name: string = null;
  LastPricelist: Date = null;
  CommercialName: string = null;
  DefaultAddress: Address = null;
  DefaultContact: Contact = null;
  DefaultCurrency: DropdownOption = null;
  DefaultVATCode: DropdownOption = null;
  DefaultSupplyPlant: DropdownOption = null;
  VATLiability: DropdownOption = null;
  CustomerGroup: DropdownOption = null;
  Representative: Account = null;
  PriceList: PriceList = null;
  PaymentConditionSales: DropdownOption = null;
  InvoicingMethod: DropdownOption = null;
  Phone: string = null;
  Website: string = null;
  Email: string = null;
  Tags: string = null;
  DiscountSales: number = null;
  DiscountPurchase: number = null;
  VATNumber: string = null;
  StartDate: Date = null;
  EndDate: Date = null;
  IsApproved = false; // Backend overwrites
  IsBlocked: boolean = null;
  IsReseller: boolean = null;
  IsSales: boolean = null;
  IsSupplier: boolean = null;
  Locale: Locale = null;
  Remarks: string = null;
  DocumentDueDays: number = null;
  FrancoShipment: number = null;
  Addresses: Address[] = [];
  Contacts: Contact[] = [];
  Timeline: Timeline[] = [];
  Documents: Document[] = [];
  Activations: Activation[] = [];
  Groups: Group[] = [];
  Accounts: Account[] = [];
  FreeField1: string = null;
  FreeField2: string = null;

  constructor(data: any = null) {

    super(data);

    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    // Convert date (without offset)
    this.StartDate = this.StartDate ? new Date(this.StartDate) : null;
    this.EndDate = this.EndDate ? new Date(this.EndDate) : null;

    // Convert untyped to typed objects
    this.Representative = this.Representative ? new Account(this.Representative) : null;
    this.PriceList = this.PriceList ? new PriceList(this.PriceList) : null;
    this.PaymentConditionSales = this.PaymentConditionSales ? new DropdownOption(this.PaymentConditionSales) : null;
    this.InvoicingMethod = this.InvoicingMethod ? new DropdownOption(this.InvoicingMethod) : null;
    this.Type = this.Type ? new DropdownOption(this.Type) : null;
    this.SubType = this.SubType ? new DropdownOption(this.SubType) : null;
    this.RetailChain = this.RetailChain ? new DropdownOption(this.RetailChain) : null;
    this.DefaultCurrency = this.DefaultCurrency ? new DropdownOption(this.DefaultCurrency) : null;
    this.DefaultVATCode = this.DefaultVATCode ? new DropdownOption(this.DefaultVATCode) : null;
    this.DefaultSupplyPlant = this.DefaultSupplyPlant ? new DropdownOption(this.DefaultSupplyPlant) : null;
    this.VATLiability = this.VATLiability ? new DropdownOption(this.VATLiability) : null;
    this.CustomerGroup = this.CustomerGroup ? new DropdownOption(this.CustomerGroup) : null;
    this.DefaultAddress = this.DefaultAddress ? new Address(this.DefaultAddress) : null;
    this.DefaultContact = this.DefaultContact ? new Contact(this.DefaultContact) : null;
    this.Locale = this.Locale ? new Locale(this.Locale) : null;

    // Assign compan & address
    if (this.Addresses) {
      this.Addresses.forEach((item, index) => {
      this.Addresses[index] = new Address(item);
    });
    }
    if (this.Contacts) {
      this.Contacts.forEach((item, index) => {
      this.Contacts[index] = new Contact(item);
    });
    }
    if (this.Documents) {
      this.Documents.forEach((item, index) => {
      this.Documents[index] = new Document(item);
    });
    }
    if (this.Timeline) {
      this.Timeline.forEach((item, index) => {
      this.Timeline[index] = new Timeline(item);
    });
    }
    if (this.Activations) {
      this.Activations.forEach((item, index) => {
      this.Activations[index] = new Activation(item);
    });
    }
    if (this.Groups) {
      this.Groups.forEach((item, index) => {
      this.Groups[index] = new Group(item);
    });
    }
    if (this.Accounts) {
      this.Accounts.forEach((item, index) => {
        this.Accounts[index] = new Account(item);
      });
    }
  }

}
