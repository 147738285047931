import {HttpParams} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, TemplateRef} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_ASYNC_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/internal/operators';
import {Locale} from '../../models/Locales/locale.object';
import {PriceList} from '../../models/PriceList/price-list.object';
import {PriceListService} from '../../models/PriceList/price-list.service';
import {FilterObject} from '../../objects/filter-object';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-price-list-selector',
  templateUrl: './selector.component.html',
  providers: [
    PriceListService,
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PriceListSelectorComponent), multi: true},
    {provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => PriceListSelectorComponent), multi: true}
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceListSelectorComponent implements ControlValueAccessor, OnDestroy {

  // SELECTOR <=> this.ChildControl <=> CONTROLLER (THIS) <=> value (get/set) <=> END component

  Results: PriceList[];
  ChildControl = new UntypedFormControl();

  @Input() name: string = null; // verplicht ?
  @Input() placeholder = 'Price List';
  @Input() disabled: false;
  @Input() category: string = null;
  @Input() matSuffix: TemplateRef<any>;
  @Input() required = false; // Does not work with @param required
  @Input() hideCode = false; // Hides art code and only shows full description
  @Input('filter') filterObject: FilterObject = new FilterObject(); // To filter
  filterCopy: FilterObject = new FilterObject(this.filterObject);
  Selected: PriceList = null; // Default selected
  Locale: Locale = new Locale({Id: 'en-GB'});

  // V2
  modelChanged: Subject<any> = new Subject<any>();
  childValue: PriceList = null; // Default selected
  listResults: PriceList[];
  childControl = new UntypedFormControl();

  constructor(private _PriceList: PriceListService, public _Global: GlobalService) {

    // Subscribe to changes with a debounceTime
    this.modelChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(val => {

      if (val instanceof PriceList || !val) {
        this.value = val;
      } else {
        this.doSearch(val).subscribe(res => {
          this.listResults = res;

          // Only one result found / multiple
          if (res && res.length === 1) {
            this.childValue = res[0];
            this.value = res[0];
          } else {
            this.validate().subscribe(); // Run validator manually
            this.value = null;
          }
        });
      }
    });
  }

  get value() {
    return this.childValue instanceof PriceList ? this.childValue : null;
  }

  set value(val) {
    if (!this.childValue && val instanceof PriceList) this.childValue = val; // Set once on init
    this.onChange(val);
    this.onTouched();
  }

  // CODE BELOW IS REQUIRED TO REGISTER THE NG_VALUE_ACCESSOR
  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  ngOnDestroy() {
  }

  // Async validator, note the NG_ASYNC_VALIDATORS provider !
  validate(c: UntypedFormControl = null): Observable<any> {

    return new Observable(observer => {
      // Selected from autocomplete list = valid OR empty and NOT required
      if ((this.Selected instanceof PriceList || (!this.ChildControl.value && !this.required))) {
        this.ChildControl.setErrors(null);
        observer.next(null);
        observer.complete();
      } else {
        this.ChildControl.setErrors({err: 'err'});
        observer.next({'err': true});
        observer.complete();
      }
    });
  }

  // Asynch search observable
  doSearch(e: any): Observable<PriceList[]> {
    this.filterCopy.limit = 0;
    this.filterCopy.order = 'Code ASC';
    if (e && e.indexOf('%') === -1) e = ['%', e, '%'].join(''); // Add wildcards if not set
    // const tmpWhere = {and: [{'or': [{'Code': {'like': e}}, {'Description': {'like': e}}, {'search': {'like': e}}]}, {or: [{IsBlocked: false}, {IsBlocked: null}]}]};
    const tmpWhere = {'or': [{'Code': {'like': e}}, {'Description': {'like': e}}, {'search': {'like': e}}]};
    this.filterCopy.where = this.filterObject.where ? {'and': [this.filterObject.where, tmpWhere]} : tmpWhere;
    const Params = new HttpParams().append('filter', JSON.stringify(this.filterCopy));
    return this._PriceList.get(Params);
  }

  updateNgModel(val) {
    this.modelChanged.next(val); // To subscribe on debounce
  }

  displayFn(input: PriceList) {
    return input && input.Code ? input.Code : input;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    this.value = value;
  }

  checkSelected(): boolean {
    return this.Selected && this.Selected instanceof PriceList;
  }
}
