import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {Address} from './addresses.object';

@Injectable()
export class AddressService {

  defaultInclude = ['Relation', 'CarrierServiceLevel', 'Currency', 'CarrierServiceLevelOther', 'SupplyPlant', 'Type', 'Modifier', 'Creator', {'Country': 'ShipmentArticle'}];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<Address[]> {
    return this.http
        .get(environment.apiUrl + '/Addresses', {params: ReqParams})
        .pipe(
            map(res => <Address[]>res),
            map(res => {
              return res.map(rel => new Address(rel));
            })
        );
  }

  upsert(address: Address): Observable<Address> {
    return this.http
        .put(environment.apiUrl + '/Addresses', JSON.stringify(address))
        .pipe(
            map(res => <Address>res),
            map(res => {
              // Only update available props in relation. (Avoid to add irelevant props)
              for (const prop in res) if (prop in address) address[prop] = res[prop];
              return new Address(address);
            })
        );
  }

  remove(address: Address): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/Addresses/' + address.Id);
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/Addresses/count', {params: ReqParams})
        .pipe(
            map(res => <{ count: number }>res)
        );
  }

  exportXlsx(ReqParams: HttpParams = null): Observable<Blob> {
    return this.http
        .get(environment.apiUrl + '/Addresses/export', {params: ReqParams, responseType: 'blob'})
        .pipe(
            map(res => {
              return new Blob([res], {type: 'application/vnd.ms-excel'});
            })
        );
  }

  check(input: Address, reqParams: HttpParams = null): Observable<Address[]> {
    return this.http
      .post(environment.apiUrl + '/Addresses/check', JSON.stringify(input), {params: reqParams})
      .pipe(
        map(res => <Address[]>res),
        map(res => {
          return res.map(rel => new Address(rel));
        })
      );
  }

}
