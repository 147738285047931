import {Attachment} from '../Attachment/attachment.object';
import {Contact} from '../Contact/contact.object';
import {Document} from '../Document/document.object';
import {Relation} from '../Relation/relation.object';

export class Email {

  Relation: Relation = null; // for contact persons etc.
  Documents: Document[] = []; // If we want to attach documents
  Attachments: Attachment[] = [];
  Contacts: Contact[] = [];
  Subject: string = null;
  SenderName: string = null;
  SenderEmail: string = null;
  Body: string = null;
  Variables: { name: string, value: any }[] = [];

  constructor(data: any = null) {
    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.Relation = this.Relation ? new Relation(this.Relation) : null;
    this.Documents.forEach((item, index) => {
      this.Documents[index] = new Document(item);
    });

    this.Attachments.forEach((item, index) => {
      this.Attachments[index] = new Attachment(item);
    });

    this.Contacts.forEach((item, index) => {
      this.Contacts[index] = new Contact(item);
    });
  }
}
