import {OpeningHours} from '../../objects/opening-hours';
import {Country} from '../Country/country.object';
import {DefaultModel} from '../Default/default.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';
import {Relation} from '../Relation/relation.object';

export class Address extends DefaultModel {

  Name: string = null;
  ExternalId: string = null;
  ExternalRelationId: string = null; // for SAP
  Type: DropdownOption = null; // Don't hard-code this on invoice because ExternalId needs to be loaded.
  ServicePointId: string = null; // Don't hard-code this on invoice because ExternalId needs to be loaded.
  Street: string = null;
  Number: string = null;
  BusNumber: number = null;
  Postcode: string = null;
  City: string = null;
  Company: string = null;
  Country: Country = null;
  Email: string = null;
  PhoneNumber: string = null;
  Remarks: string = null;
  OpeningHours: OpeningHours = null;
  LatLng: { lat: number, lng: number } = null;
  Relation: Relation = null;
  IsActive = true;
  IsDefault = false;
  ShippingInfo: string = null;
  CarrierServiceLevel: DropdownOption = null;
  CarrierServiceLevelOther: DropdownOption = null;
  Currency: DropdownOption = null;
  SupplyPlant: DropdownOption = null;
  FreeField1: string = null;
  FreeField2: string = null;
  AddressLine1: string = null;
  AddressLine2: string = null;
  AddressLine3: string = null;
  InterfacingError: string = null;

  constructor(data: any = null) {

    super(data);

    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.Country = this.Country ? new Country(this.Country) : null;
    this.Type = this.Type ? new DropdownOption(this.Type) : null;
    this.CarrierServiceLevel = this.CarrierServiceLevel ? new DropdownOption(this.CarrierServiceLevel) : null;
    this.CarrierServiceLevelOther = this.CarrierServiceLevelOther ? new DropdownOption(this.CarrierServiceLevelOther) : null;
    this.Currency = this.Currency ? new DropdownOption(this.Currency) : null;
    this.SupplyPlant = this.SupplyPlant ? new DropdownOption(this.SupplyPlant) : null;
    this.Relation = this.Relation ? new Relation(this.Relation) : null;
    this.OpeningHours = this.OpeningHours ? new OpeningHours(this.OpeningHours) : null;

  }

  getFullAddress(): string {
    return this.Street + ' ' + this.Number + ', ' + this.Postcode + ' ' + this.City + ' - ' + (this.Country && this.Country.Name ? this.Country.Name : '');
  }

  distanceTo(point: { lat: number, lng: number }): number {
    return this.LatLng ? Math.round(Math.sqrt(Math.pow((point.lat - this.LatLng.lat), 2) + Math.pow((point.lng - this.LatLng.lng), 2)) * 10000) / 100 : null;
  }
}
