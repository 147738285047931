import {Country} from '../Country/country.object';
import {DefaultModel} from '../Default/default.object';

export class Locale extends DefaultModel {

  Language: string = null;
  LanguageCode: string = null;
  Translation: number = null;
  Active: number = null;
  Order: number = null;
  ExternalId: string = null;

  Country: Country = null;


  constructor(data: any = null) {

    super(data);

    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];
    this.Country = this.Country ? new Country(this.Country) : null;

    // Set ID default on NL
    if (!data || !data.Id) this.Id = 'en-GB';

  }

  getFullLocale(): string {
    return this.Country ? this.Language + ' (' + this.Country.Name + ')' : this.Language;
  }

}
