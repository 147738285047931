import {Component, ComponentRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent implements OnInit {
  @Input() data: { Title: string, Message: string } = {Title: null, Message: null};
  @Output() confirm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('templateRef', {static: true}) templateRef;
  Dialog: MatDialogRef<ConfirmDialogComponent>;
  cRef: ComponentRef<any>;

  constructor(private _Dialog: MatDialog) {
  }

  ngOnInit() {
  }

  open(title: string, message: string, cRef: ComponentRef<any>) {
    this.data.Title = title;
    this.data.Message = message;
    this.cRef = cRef;
    this.Dialog = this._Dialog.open(this.templateRef, {role: 'alertdialog'});
  }

  close(result: boolean) {
    this._Dialog.getDialogById(this.Dialog.id).close();
    if (result) this.confirm.emit(result);
  }
}
