import {HttpParams} from '@angular/common/http';
import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Group} from '../../models/Group/group.object';
import {GroupService} from '../../models/Group/group.service';

@Component({
  selector: 'app-group-selector',
  templateUrl: './selector.component.html',
  providers: [
    GroupService,
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => GroupSelectorComponent), multi: true}]
})
export class GroupSelectorComponent implements OnInit {

  Groups: Group[] = [];
  Selected: Group[] = [];
  @Input() multiple = false;
  @Input() placeholder = 'Reseller groups';
  @Input() getOnlyId = false;
  // CODE BELOW IS REQUIRED TO REGISTER THE NG_VALUE_ACCESSOR
  onChange: any = () => {
  }
  onTouched: any = () => {
  }

  constructor(private _Group: GroupService) {
  }

  get value() {
    return this.Selected;
  }

  set value(val) {
    this.Selected = val;
    this.onChange(val); // emit to ngModel
  }

  compareWith(o1: any, o2: any): boolean {
    return o1 === o2 || o1 && o2 && o1.Id && o2.Id && o1.Id === o2.Id;
  }

  ngOnInit() {
    const httpParams = new HttpParams().append('filter', JSON.stringify({order: 'Title ASC'}));

    this._Group.get(httpParams).subscribe(res => this.Groups = res);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    this.value = value;
  }
}
