import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {Widget} from '../Widget/widget.object';
import {Relation} from './relation.object';

@Injectable()
export class RelationService {

  defaultInclude: any[] = [
    'Type', 'DefaultCurrency', 'SubType', 'RetailChain', 'VATLiability', 'PaymentConditionSales', 'Representative', 'DefaultVATCode', 'Groups', 'Locale', 'InvoicingMethod',
    {'Contacts': ['Relation', {'Locale': {'Country': 'ShipmentArticle'}}, 'Type']}, 'DefaultSupplyPlant', 'CustomerGroup',
    {'Addresses': ['Relation', 'Currency', 'Type', 'CarrierServiceLevel', 'SupplyPlant', 'CarrierServiceLevelOther', {'Country': 'ShipmentArticle'}]},
    {'Activations': ['Type', 'Status', {'Address': [{'Country': 'ShipmentArticle'}, 'Type']}, 'Contact', {'ActivationAccounts': ['Account', 'Status', 'Activation']}]},
    {'DefaultAddress': [{'Country': 'ShipmentArticle'}, 'Currency', 'Type', 'Relation']},
    {'DefaultContact': ['Relation', {'Locale': {'Country': 'ShipmentArticle'}}, 'Type']}];

  WithoutGoogleMapsInclude: any[] = [
    'Groups', 'DefaultCurrency', 'DefaultSupplyPlant', 'CustomerGroup',
    {
      relation: 'Contacts',
      scope: {
        include: ['Relation', 'Type'],
        where: {and: [{ExternalRelationId: {neq: null}}]}
      }
    },
    {
      relation: 'Addresses',
      scope: {
        include: ['Relation', 'Type', 'CarrierServiceLevel', 'SupplyPlant', 'CarrierServiceLevelOther', {'Country': 'ShipmentArticle'}],
        where: {and: [{TypeId: '4870f240-1c60-46a2-af41-1b135e46b1ee'}, {IsActive: true}]}
      }
    }, 'Locale', 'Contacts', {'DefaultAddress': [{'Country': 'ShipmentArticle'}, 'Type']}, {'DefaultContact': {'Locale': 'Country'}}, 'DefaultVATCode'];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<Relation[]> {
    return this.http
        .get<Relation[]>(environment.apiUrl + '/Relations/search', {params: ReqParams})
        .pipe(
            map(res => <Relation[]>res),
            map(res => {
              return res.map(rel => new Relation(rel));
            })
        );
  }

  getById(Id: string, ReqParams: HttpParams = null): Observable<Relation> {
    return this.http
        .get<Relation>(environment.apiUrl + '/Relations/' + Id, {params: ReqParams})
        .pipe(
            map(res => <Relation>res),
            map(res => {
              return new Relation(res);
            })
        );
  }

  upsert(Entry: Relation): Observable<Relation> {
    return this.http
        .put(environment.apiUrl + '/Relations', JSON.stringify(Entry))
        .pipe(
            map(res => <Relation>res),
            map(res => {
              // Only update available props in relation. (Avoid to add irelevant props)
              for (const prop in res) if (prop in Entry) Entry[prop] = res[prop];
              return new Relation(Entry);
            })
        );
  }

  remove(Entry: Relation): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/Relations/' + Entry.Id);
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/Relations/count', {params: ReqParams})
        .pipe(
            map(res => <{ count: number }>res)
        );
  }

  exportXlsx(ReqParams: HttpParams = null): Observable<Blob> {
    return this.http
        .get(environment.apiUrl + '/Relations/export', {params: ReqParams, responseType: 'blob'})
        .pipe(
            map(res => {
              return new Blob([res], {type: 'application/vnd.ms-excel'});
            })
        );
  }

  getArticles(ReqParams: HttpParams = null): Observable<Widget> {
    return this.http
        .get<Widget>(environment.apiUrl + '/Relations/getArticles', {params: ReqParams});
  }

  pushToExact(input: Relation): Observable<Relation> {
    return this.http
        .post(environment.apiUrl + '/Relations/pushToExact', JSON.stringify(input))
        .pipe(
            map(res => {
              for (const prop in res) if (prop in input) input[prop] = res[prop];
              return new Relation(input);
            })
        );
  }

  pullFromExact(input: Relation): Observable<Relation> {
    return this.http
      .post(environment.apiUrl + '/Relations/pullFromExact', JSON.stringify(input))
      .pipe(
        map(res => {
          for (const prop in res) if (prop in input) input[prop] = res[prop];
          return new Relation(input);
        })
      );
  }

  pullPriceList(input: Relation): Observable<any> {
    const httpParams = new HttpParams().append('relationId', input.Id);
    return this.http.post(environment.apiUrl + '/PriceListDetails/salesOrderSimulate', null, {params: httpParams});
  }
}
