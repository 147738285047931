import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {ActivationAccount} from './activation-account.object';

@Injectable()
export class ActivationAccountService {

  defaultInclude = ['Account', 'Activation'];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<ActivationAccount[]> {
    return this.http
        .get<ActivationAccount[]>(environment.apiUrl + '/ActivationAccounts/search', {params: ReqParams})
        .pipe(
            map(res => <ActivationAccount[]>res),
            map(res => {
              return res.map(rel => new ActivationAccount(rel));
            })
        );
  }

  upsert(input: ActivationAccount): Observable<ActivationAccount> {
    return this.http
        .put<ActivationAccount>(environment.apiUrl + '/ActivationAccounts', JSON.stringify(input))
        .pipe(
            map(res => <ActivationAccount>res),
            map(res => {
              // Modify original doc without destroying related nexted objects
              for (const prop in res) if (prop in input) input[prop] = res[prop];
              return new ActivationAccount(input);
            })
        );
  }

  remove(input: ActivationAccount): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/ActivationAccounts/' + input.Id);
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/ActivationAccounts/count', {params: ReqParams})
        .pipe(
            map(res => <{ count: number }>res)
        );
  }
}
