import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {WidgetList} from './widget-list.object';
import {Widget} from './widget.object';

@Injectable()
export class WidgetService {

  constructor(private http: HttpClient) {
  }

  getSalesVolumePerDocumentType(ReqParams: HttpParams = null): Observable<Widget> {
    return this.http
      .get<Widget>(environment.apiUrl + '/Widgets/getSalesVolumePerDocumentType', {params: ReqParams});
  }

  getSalesVolumePerRelation(ReqParams: HttpParams = null): Observable<Widget> {
    return this.http
      .get<Widget>(environment.apiUrl + '/Widgets/getSalesVolumePerRelation', {params: ReqParams});
  }

  getWorkedHoursPerProject(ReqParams: HttpParams = null): Observable<Widget> {
    return this.http
      .get<Widget>(environment.apiUrl + '/Widgets/getWorkedHoursPerProject', {params: ReqParams});
  }

  getWorkedHoursPerRelation(ReqParams: HttpParams = null): Observable<Widget> {
    return this.http
      .get<Widget>(environment.apiUrl + '/Widgets/getWorkedHoursPerRelation', {params: ReqParams});
  }

  getSalesStudent(ReqParams: HttpParams = null): Observable<Widget> {
    return this.http
      .get<Widget>(environment.apiUrl + '/Widgets/getSalesStudent', {params: ReqParams});
  }

  getReport(ReqParams: HttpParams = null): Observable<Blob> {
    return this.http
      .get(environment.apiUrl + '/Widgets/getReport', {params: ReqParams, responseType: 'blob'})
      .pipe(
        map(res => {
          return new Blob([res], {type: 'application/vnd.ms-excel'});
        })
      );
  }

  getReportList(ReqParams: HttpParams = null): Observable<WidgetList[]> {
    return this.http
      .get<WidgetList[]>(environment.apiUrl + '/Widgets/getReportList', {params: ReqParams}).pipe(
        map(res => <WidgetList[]>res),
        map(res => {
          return res.map(rel => new WidgetList(rel));
        }),);
  }

  getGroupInfoExport(ReqParams: HttpParams = null): Observable<Blob> {
    return this.http
      .get(environment.apiUrl + '/Widgets/getGroupInfoExport', {params: ReqParams, responseType: 'blob'})
      .pipe(
        map(res => {
          return new Blob([res], {type: 'application/vnd.ms-excel'});
        })
      );
  }

}
