import {Article} from '../Article/article.object';
import {DefaultModel} from '../Default/default.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';
import {Group} from '../Group/group.object';
import {Locale} from '../Locales/locale.object';
import {Translation} from '../Translation/translation.object';

export class PromotionCode extends DefaultModel {
  Code: string = null;
  AmountRequired: number = null;
  Amount: number = null;
  MaxAmountPerOrder: number = 1;
  MaxRelationUses: number = 0;
  StartDate: Date = null;
  EndDate: Date = null;
  Image: string = null;
  IsPromotionsIncluded: boolean = false;
  Type: DropdownOption = null;
  Article: Article = null;
  Categories: DropdownOption[] = [];
  Groups: Group[] = [];
  Description: Translation[] = [];

  constructor(data: any = null) {
    super();
    for (const prop in data) if (prop in this) this[prop] = data[prop];
    this.Type = this.Type ? new DropdownOption(this.Type) : null;
    this.Article = this.Article ? new Article(this.Article) : null;

    if (this.Groups) {
      this.Groups.forEach((item, index) => {
        this.Groups[index] = new Group(item);
      });
    }

    if (this.Description) {
      this.Description.forEach((item, index) => {
        this.Description[index] = new Translation(item);
      });
    }

    if (this.Categories) {
      this.Categories.forEach((item, index) => {
        this.Categories[index] = new DropdownOption(item);
      });
    }
  }

  getDescription(loc: Locale = new Locale()): string {
    const found = this.Description.find(x => x.Locale.Id === loc.Id);
    return found ? found.Value : 'NO_TRANSLATION';
  }

  getImage(): string {
    let url = '';
    if (this.Image) {
      url = 'https://api-aorta.edgardcooper.com/v1/Attachments/download/' + encodeURIComponent(this.Image);
    } else if (this.Type && this.Type.FreeBooleanField1 && this.Article) {
      url = 'https://api-aorta.edgardcooper.com/v1/Attachments/download/' + encodeURIComponent(this.Article.Image);
    } else if (this.Type && this.Type.FreeImageField1) {
      url = 'https://api-aorta.edgardcooper.com/v1/Attachments/download/' + encodeURIComponent(this.Type.FreeImageField1);
    }
    return url;
  }

}
