import {DatePipe} from '@angular/common';
import {HttpParams} from '@angular/common/http';
import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {AbstractControl, NgForm, UntypedFormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as FileSaver from 'file-saver';
import {Moment} from 'moment';
import * as moment from 'moment-business-days';
import {environment} from '../../../environments/environment';
import {Address} from '../../models/Address/addresses.object';
import {Article} from '../../models/Article/article.object';
import {ArticleService} from '../../models/Article/article.service';
import {Division} from '../../models/Division/division.object';
import {Document} from '../../models/Document/document.object';
import {DocumentService} from '../../models/Document/document.service';
import {DocumentLines} from '../../models/DocumentLine/documentline.object';
import {DocumentType} from '../../models/DocumentType/document-type.object';
import {DropdownOption} from '../../models/DropdownOption/dropdown-option.object';
import {DeliverDates} from '../../models/LeadTime/delivery-dates.object';
import {LeadTimeService} from '../../models/LeadTime/lead-time.service';
import {PriceList} from '../../models/PriceList/price-list.object';
import {PriceListService} from '../../models/PriceList/price-list.service';
import {Relation} from '../../models/Relation/relation.object';
import {RelationService} from '../../models/Relation/relation.service';
import {FilterObject} from '../../objects/filter-object';
import {AlertService} from '../../services/alert.service';
import {GlobalService} from '../../services/global.service';


@Component({
  selector: 'app-document-editor',
  templateUrl: './document-editor.component.html',
  styleUrls: ['./document-editor.component.css'],
  providers: [{
    provide: MAT_DIALOG_DATA,
    useValue: ''
  }, DocumentService, PriceListService, LeadTimeService, ArticleService, RelationService, MatSnackBar, DatePipe]

})
export class DocumentEditorComponent {

  // Original list to modify
  @Input() List: Document[] = [];
  @Output() ListChange: EventEmitter<Document[]> = new EventEmitter<Document[]>();
  @ViewChild('editorForm') editorForm: NgForm;

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<DocumentEditorComponent>;

  Original: Document = null;
  Copy: Document = null;
  PriceList: PriceList = null;
  Total: { tot_ex_vat: number, tot_vat: number, tot_incl_vat: number } = {tot_ex_vat: 0, tot_vat: 0, tot_incl_vat: 0};
  FilteredAddresses: Address[] = [];
  AddressControl: UntypedFormControl = new UntypedFormControl(null, [this.addressValidator]);
  DeliverDates: DeliverDates = null;
  ShowDeliveryDebugMsg: boolean = false;
  SalesOrderFilter = {Id: {inq: ['8c2d806d-6aba-11eb-8cac-fa163ece5563', '8c65a131-6aba-11eb-8cac-fa163ece5563', '8bfcbf47-6aba-11eb-8cac-fa163ece5563']}};


  sendMail: boolean = false;
  isLoading = false;
  isLoadingShipping = false;
  filterObjectShipping: FilterObject = new FilterObject(); // Keep track of page, skip, limit, ...

  constructor(private _Dialog: MatDialog,
              private snackBar: MatSnackBar,
              private _Document: DocumentService,
              private _Alert: AlertService,
              private _Article: ArticleService,
              public _Global: GlobalService,
              public _Relation: RelationService,
              private datePipe: DatePipe,
              private leadTime: LeadTimeService
  ) {
    this.filterObjectShipping.include = _Article.defaultInclude;
    this.filterObjectShipping.where = {Code: 'Verzendkosten'};
    this.filterObjectShipping.limit = 1;
  }

  edit(input: Document) {
    this.FilteredAddresses = [];
    this.dialogRef = this._Dialog.open(this.templateRef, {maxWidth: 'none', width: '90%', maxHeight: 'none'});
    this.dialogRef.disableClose = true;
    this.dialogRef.afterOpened().subscribe(result => {
      this.calcTotals();
      this.editorForm.valueChanges.subscribe(res => this.calcTotals());
      this.editorForm.controls.DeliveryDate.markAsTouched(); // Mark DeliveryDate as touched to make the color red when invalid.
    });

    // Assign Division if not assigned
    if (!input.Division) input.Division = this._Global.Division;

    // start
    this.Original = input;
    this.Copy = new Document(JSON.parse(JSON.stringify(input)));

    // Prefill defaults
    if (!this.Copy.Contact && this.Copy.Relation && this.Copy.Relation.DefaultContact) this.Copy.Contact = this.Copy.Relation.DefaultContact;
    if (!this.Copy.Address && this.Copy.Relation) {
      const address = this.Copy.Relation.Addresses.find(x => x.IsDefault && x.Type && x.Type.Id === '097107f0-8bea-4a96-8536-8703f552ebae');
      this.Copy.Address = address ? address : null;
    }
    if (!this.Copy.Account) this.Copy.Account = this._Global.Account;
    this.sendMail = !this.Copy.NotifyEmailOnApprove;

    // Calculate duedays, default 30 days.
    const dueDays = this.Copy.Relation && this.Copy.Relation.DocumentDueDays ? this.Copy.Relation.DocumentDueDays : 30; // default 30 days
    if (this.Copy.Type && this.Copy.Type.Id !== 'cbaab5fc-50e5-4969-b27c-6c02a6bfe3fa') this.Copy.DueDate = new Date(Date.now() + dueDays * 24 * 60 * 60 * 1000);
  }

  checkDocLines() {
    this.Copy.DocumentLines.forEach(line => {
      if (line.Article && !line.Article.ExternalId) this._Alert.sendMessage('Article ' + line.Article.Code + ' Has no ExternalId!', 'danger');
    });
  }

  save(object: { Id: string, Value: string }) {

    // clean up the payload before sending to the server
    if (this.Copy.Relation) this.Copy.Relation = new Relation({Id: this.Copy.Relation.Id});
    if (this.Copy.Division) this.Copy.Division = new Division({Id: this.Copy.Division.Id});
    this.Copy.DocumentLines.forEach(line => {
      if (line.Article) line.Article = new Article({Id: line.Article.Id});
    });

    // this.checkDocLines();
    if (object) this.Copy.Status = new DropdownOption(object);
    this.Copy.NotifyEmailOnApprove = this.sendMail ? null : this.Original.NotifyEmailOnApprove;
    this.isLoading = true;
    this._Document.upsert(this.Copy).subscribe(res => {
        this.isLoading = false;
        // Modify original
        Object.assign(this.Original, this.Copy);
        this.Copy = res;
        const found = this.List.find(x => x.Id === res.Id);
        found ? Object.assign(found, res) : this.List.push(res); // Modify or push array list
        this.ListChange.emit(this.List);
        this._Dialog.getDialogById(this.dialogRef.id).close();
      },
      err => {
        this.isLoading = false;
      });
  }

  compareWith(o1: any, o2: any) {
    return o1 && o2 && o1.Id && o2.Id && o1.Id === o2.Id;
  }

  remove(Doc: Document) {
    this.isLoading = true;
    this._Document
      .remove(Doc)
      .subscribe(res => {
        if (this.List) this.List.splice(this.List.indexOf(Doc), res.count);
        this.ListChange.emit(this.List);
        this.isLoading = false;
        this._Dialog.getDialogById(this.dialogRef.id).close();
      });
  }

  addLine() {
    const newLine: DocumentLines = new DocumentLines;
    this.Copy.DocumentLines.push(newLine);
  }

  duplicate(index: number) {
    const newLine = new DocumentLines(JSON.parse(JSON.stringify(this.Copy.DocumentLines[index])));
    newLine.Id = null; // to avoid overwriting
    this.Copy.DocumentLines.push(newLine);
  }

  duplicateDocument(document: Document, type: DocumentType) {
    const newDocument = new Document(JSON.parse(JSON.stringify(document))); // Deep copy

    // Clear values
    newDocument.Generator = new Document({Id: document.Id});
    newDocument.Type = type;
    newDocument.ImportedInExact = null;
    newDocument.ImportedError = null;
    newDocument.Id = null;
    newDocument.Number = null;
    newDocument.Date = new Date();
    newDocument.ShippingMethod = null;
    newDocument.DocumentPrints = [];
    newDocument.DocumentLines.forEach(line => line.Id = null);

    this.edit(newDocument);

  }

  // Check if document Relation is using the correct packaging
  // Relations with CustomerGroup.ExternalId from 01 till 10 are not recommended to use individual packaging (show warning if any are found)
  checkPackaging(): string[] {
    if (!['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '16'].includes(this.Copy.Relation?.CustomerGroup?.ExternalId)) return [];
    return this.Copy.DocumentLines
      .filter(line => line.Article?.PackageType?.Id === '3d8fb3e6-bd97-4e3e-9537-0043fd37aaa3') // INDIVIDUAL
      .filter(line => {  // ASSEMBLIES
        console.log(line.Article?.Categories);
        return !line.Article?.Categories.find(x => x.Id === 'a4445242-01c0-4661-b6a3-28b0ff59de2c');
      })
      .filter(line => {  // POINT OF SALES MATERIAL
        return !line.Article?.Categories.find(x => x.Id === 'c7f5dee1-cb8f-43b5-bbc1-d16657a36279');
      })
      .map(line => line?.Article?.Code);
  }

  async removeLine(Line: DocumentLines) {
    this.Copy.DocumentLines.splice(this.Copy.DocumentLines.indexOf(Line), 1);
    await this.getLeadTime();
  }

  setRelation(e: any) {
    this.PriceList = null;
    if (e instanceof Relation && (!this.Copy.Relation || (this.Copy.Relation && this.Copy.Relation.Id !== e.Id))) {
      this.Copy.Contact = e.DefaultContact;
      if (!this.Copy.SupplyPlant) this.Copy.SupplyPlant = e.DefaultSupplyPlant;

      // Prefill the address
      const shipTos = e.Addresses.filter(x => x.Type?.Id === '4870f240-1c60-46a2-af41-1b135e46b1ee');
      if (shipTos.length === 1) this.Copy.Address = shipTos[0];

      const dueDays = e.DocumentDueDays ? e.DocumentDueDays : 30; // default 30 days
      if (this.Copy.Type && this.Copy.Type.Id !== 'cbaab5fc-50e5-4969-b27c-6c02a6bfe3fa') this.Copy.DueDate = new Date(Date.now() + dueDays * 24 * 60 * 60 * 1000);
      this.Copy.Currency = e.DefaultCurrency || new DropdownOption({
        Id: '6a87614d-3210-44a6-b64a-ecec786e5eaa',
        FreeTextField1: '€'
      }); // Default EUR
      if (e.Locale) this.Copy.Locale = e.Locale;
    } else if (!(e instanceof Relation)) {
      this.Copy.Contact = null;
      this.Copy.Address = null;
      this.Copy.Discount = 0;
      this.Copy.SupplyPlant = null;
    }
    this.FilteredAddresses = e ? e.Addresses : [];
    this.Copy.Relation = e;
  }

  // Set default price etc..
  setArticle(article: Article, row: DocumentLines) {

    // If is new article.
    if (article) {
      if (article instanceof Article && ((row.Article && article.Id !== row.Article.Id)) || !row.Article) {
        row.UnitPrice = Number(article.SalesPrice);
        if (!row.Description) row.Description = article.CommercialName;
        row.Unit = article.Packaging ? article.Packaging : (article.Unit ? article.Unit : null);
        if (!row.Ordered) article.MinQTY ? row.Ordered = article.MinQTY : row.Ordered = 1;
      }
    }
    row.Article = article;
  }

  calcTotals() {
    setTimeout(() => {
      this.Total.tot_ex_vat = 0;
      this.Total.tot_vat = 0;
      this.Total.tot_incl_vat = 0;
      for (const line of this.Copy.DocumentLines) {
        const VatPerc = line.VATCode ? line.VATCode.FreeNummericField1 : 0;
        this.Total.tot_ex_vat += (line.Ordered * (1 - line.Discount)) * line.UnitPrice;
        this.Total.tot_vat += ((line.Ordered * (1 - line.Discount)) * line.UnitPrice) * VatPerc;
        this.Total.tot_incl_vat += ((line.Ordered * (1 - line.Discount)) * line.UnitPrice) * (1 + VatPerc);
      }
    }, 0);

  }

  openDocument(document: Document) {
    window.open(environment.apiUrl + '/Documents/' + document.Id + '/download?background=true', '_blank');
  }

  downloadDocument(Doc: Document) {
    this._Document.download(Doc).subscribe(res => FileSaver.saveAs(res, Doc.Number));
  }

  // Switch 2 elements from the array
  switchRow(old_index: number, new_index: number) {
    const oldLine = new DocumentLines(JSON.parse(JSON.stringify(this.Copy.DocumentLines[old_index])));
    const newLine = new DocumentLines(JSON.parse(JSON.stringify(this.Copy.DocumentLines[new_index])));

    this.Copy.DocumentLines[old_index] = newLine;
    this.Copy.DocumentLines[new_index] = oldLine;
  }

  pushToSAP() {
    this._Document.exportToSAP(this.Copy).subscribe(res => {
      this._Alert.sendMessage('This document has been pushed to SAP S4', 'info');
      this.Copy.ImportedInExact = res.ImportedInExact;
      this.Copy.ImportedError = res.ImportedError;
      this.Original.ImportedInExact = res.ImportedInExact;
      this.Original.ImportedError = res.ImportedError;
    });
  }

  round(e: any): number {
    e = parseFloat((e * 100).toFixed(2));
    return Math.round(e * 100) / 100;
  }

  // Removes all the shipping lines
  // If shipping is required, add shipping line to the documentlines.
  calculateShipping() {
    // Clear all the shipment lines
    this.Copy.DocumentLines = this.getDocumentLinesWithoutShipping();

    // Set where filter to country shipment article OR the default shipment Article
    this.filterObjectShipping.where = this.Copy.Address && this.Copy.Address.Country && this.Copy.Address.Country.ShipmentArticle ? {Code: this.Copy.Address.Country.ShipmentArticle.Code} : {Code: 'SS00000004'};

    // Check if Franco was reached, if not, add shipment article
    if (!this.hasReachedFranco()) {
      let Params = new HttpParams().append('filter', JSON.stringify(this.filterObjectShipping));
      if (this.Copy.Relation) Params = Params.append('relationId', this.Copy.Relation.Id).append('showAll', 'true').append('showBlocked', 'true');

      this.isLoadingShipping = true;
      this._Article.searchRepresentativePrices(Params).subscribe(res => {
        if (res.length > 0) {
          this.Copy.DocumentLines.push(new DocumentLines({
            Article: res[0],
            Description: res[0].CommercialName,
            Ordered: 1,
            UnitPrice: (res[0].SalesPrice ? res[0].SalesPrice : 0),
            Unit: res[0].Packaging,
            VATCode: this.Copy.Relation && this.Copy.Relation.DefaultVATCode ? this.Copy.Relation.DefaultVATCode : null
          }));
        } else {
          this._Alert.sendMessage('No Shipping Found! Please check the group of this Relation. Shipping was set to Default with price of € 12', 'danger');
          this.Copy.DocumentLines.push(new DocumentLines({
            Article: new Article({Id: 'fd29bf02-4b63-45cd-8c8f-525f53790e01'}),
            Ordered: 1,
            UnitPrice: 12,
            Unit: new DropdownOption({Id: 'cca3a167-d33d-45cb-8b84-cef57d613279', Value: 'Piece'}),
            VATCode: this.Copy.Relation && this.Copy.Relation.DefaultVATCode ? this.Copy.Relation.DefaultVATCode : null
          }));
        }
        this.isLoadingShipping = false;
      });
    } else {
      this._Alert.sendMessage('No shipping!', 'success');
    }
  }

  hasShippingLine(): boolean {
    return this.Copy.Type.Id === 'd5cc4fd9-1801-4527-81e1-b9de93076dab' || this.Copy.DocumentLines.findIndex(x => {
      return x.Article && x.Article.Categories && x.Article.Categories.findIndex(y => y.Id === 'ee13f738-6bf6-416b-9a2a-f769217290bc') !== -1;
    }) !== -1;
  }

  // Calculate the Franco amount based on relation and country
  // If not found => return null => warn user.
  getFrancoAmount(): number {
    if (this.Copy.Relation && this.Copy.Relation.FrancoShipment) {
      return this.Copy.Relation.FrancoShipment;
    } else if (this.Copy.Address && this.Copy.Address.Country && this.Copy.Address.Country.ShipmentFranco) {
      return this.Copy.Address.Country.ShipmentFranco;
    } else {
      return null;
    }
  }

  // Determines or user has reached franco
  hasReachedFranco(): boolean {
    return this.getTotalExclVAtExclShipment() >= this.getFrancoAmount();
  }

  // Total excl VAT and excl Shipment
  getTotalExclVAtExclShipment(): number {
    let total = 0;
    this.getDocumentLinesWithoutShipping().forEach(line => total += (line.Ordered * (1 - line.Discount)) * line.UnitPrice);
    return total;
  }

  // Get the document lines without shipping lines
  getDocumentLinesWithoutShipping(): DocumentLines[] {
    return this.Copy.DocumentLines.filter(x => {
      return !x.PromotionCode && x.Article && x.Article.Categories && x.Article.Categories.findIndex(y => y.Id === 'ee13f738-6bf6-416b-9a2a-f769217290bc') === -1;
    }).concat(this.Copy.DocumentLines.filter(x => x.PromotionCode));
  }

  updateLinesAsBulk(column: string) {
    this.Copy.DocumentLines.forEach((line, i) => {
      if (i !== 0) { // skip the first row
        line[column] = this.Copy.DocumentLines[0][column];
      }
    });
  }

  isValid(): boolean {
    return this.isLoading || this.isLoadingShipping || this.AddressControl.invalid || !this.editorForm.form.valid || (!this.hasReachedFranco() && !this.hasShippingLine()) || this.checkPackaging().length > 0;
  }

  getLocal(): { Date: Date, Value: string } {
    return JSON.parse(localStorage.getItem('localSavedDocument'));
  }

  saveLocal() {
    localStorage.setItem('localSavedDocument', JSON.stringify({Date: new Date, Value: this.Copy}));
  }

  loadLocal() {
    const local = <{ Date: Date, Value: string }>JSON.parse(localStorage.getItem('localSavedDocument'));
    if (local.Value) {
      this.Copy = new Document(local.Value);
    }

  }

  deleteLocal() {
    localStorage.removeItem('localSavedDocument');
  }

  addressValidator(control: AbstractControl) {
    return control.value instanceof Address ? null : {addressValid: false};
  }

  filterAddresses(e) {
    if (e === undefined) return;
    if (e instanceof Address) {
      this.Copy.Address = e;
      if (e.SupplyPlant) this.Copy.SupplyPlant = this.Copy.Address.SupplyPlant;
    } else if (this.Copy.Relation && typeof e === 'string') {
      this.FilteredAddresses = this.Copy.Relation.Addresses.filter(x => {
        return x.getFullAddress().toLowerCase().indexOf(e.toLowerCase()) !== -1 || x.Name.toLowerCase().indexOf(e.toLowerCase()) !== -1;
      });
    } else {
      this.FilteredAddresses = [];
    }
  }

  // Filter to disable the weekends
  dateFilter = (d: Moment | null): boolean => {
    const found = this.DeliverDates?.deliveryDates.find(x => moment(x).isSame(moment(d), 'day'));
    return !!found;
  }

  async setDeliveryDate() {
    await this.getLeadTime();
    if (!this.Copy.DeliveryDate && this.DeliverDates) {
      this.Copy.DeliveryDate = this.getMinDeliveryDate();
      let msg = 'The delivery date has been changed to  ' + this.datePipe.transform(this.Copy.DeliveryDate, 'shortDate') + '. ';
      msg += 'In order to estimate the delivery time as realistically as possible, we work with the following delivery times: an order placed before ' + this.DeliverDates.cutOffTime + ' hours will be delivered in ' + this.Copy?.Address?.Country?.Name + ' on ' + this.DeliverDates.originalLeadTime + ' working day(s); for an order that reaches us after ' + this.DeliverDates.cutOffTime + ' hours, 1 working day will be added to this.';
      this.snackBar.open(msg, 'OK');
    }
  }

  // Get the lead time based on the cutt-off time
  async getLeadTime() {
    this.DeliverDates = null;
    if (!this.Copy?.SupplyPlant?.Id) return;
    if (!this.Copy?.Address?.Country) return;
    if (this.Copy?.DocumentLines.length === 0) return;

    this.DeliverDates = await this.leadTime.getDeliveryDate(this.Copy.SupplyPlant.Id, this.Copy.Address.Country.Id, this.Copy.DocumentLines.filter(x => x.Article).map(x => x.Article.Id)).toPromise();
    this.Copy.DeliveryDate = this.getMinDeliveryDate();
    return Promise.resolve();
  }

  // Search for the date most in the future through the articles
  getMinDeliveryDate(): Date {
    return this.DeliverDates?.minDeliveryDate;
  }

  // The delivery date for a single row
  // Note in articles.component.ts there's a smilar algoritm, on changes also change it there!
  getDeliveryDate(line: DocumentLines) {
    if (!line.Article) return null; // No article filled in
    const found = this.DeliverDates?.articles.find(x => x.ArticleId === line.Article.Id);
    return found ? found.InStockDate : null;
  }

  // Logica to show the FOC alert message
  showFocMessage(): boolean {
    return this.Copy.Type?.Id === 'cbaab5fc-50e5-4969-b27c-6c02a6bfe3fa' &&
      this.Copy.DocumentLines.length > 0 &&
      this.Total.tot_ex_vat === 0;
  }

  // Switch to FOC
  switchToFoc(): void {
    this.Copy.Type = new DocumentType({
      Id: 'd5cc4fd9-1801-4527-81e1-b9de93076dab',
      Title: 'Free Of Charge'
    });
  }

  // Make sure the step has been respected, per 1, per 5, per 25,..
  onBlur(event, option: Article) {
    if (!option) return;
    // When not dividable by the step, round up to above
    const step: number = option.Step || 1;
    if (event.target.value % step !== 0) {
      event.target.value = Math.ceil(event.target.value / step) * step;
      const msg = 'Article ' + option?.Description + ' is only available per batch of ' + option?.Step + '. We changed the amount to ' + event.target.value + '!';
      this.snackBar.open(msg, 'OK');
    }
  }

  displayFn(a: Address): string {
    return a instanceof Address ? a.getFullAddress() : undefined;
  }

}
