import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSelectionListChange} from '@angular/material/list';
import {Article} from '../../models/Article/article.object';
import {ArticleService} from '../../models/Article/article.service';
import {Attachment} from '../../models/Attachment/attachment.object';
import {Group} from '../../models/Group/group.object';
import {GroupService} from '../../models/Group/group.service';
import {GlobalService} from '../../services/global.service';
import {AttachmentControllerComponent} from '../attachment-controller/attachment-controller.component';

export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-article-editor',
  templateUrl: './article-editor.component.html',
  styleUrls: ['./article-editor.component.css'],
  providers: [ArticleService, GroupService]
})
export class ArticleEditorComponent implements OnInit {

  // Original list to modify
  @Input() List: Article[] = [];
  @Output() ListChange: EventEmitter<Article[]> = new EventEmitter<Article[]>();

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<ArticleEditorComponent>;

  // Original and copy;
  Original: Article = new Article;
  Copy: Article = new Article;

  isLoading = false;
  isLoadingEO = false;
  Groups: Group[] = [];

  hasOrderChanged: boolean = false; // If the order has been changed, we need to patch the children before saving.

  constructor(private _Group: GroupService, private _Dialog: MatDialog, private _Article: ArticleService, private _CdRef: ChangeDetectorRef, private _Global: GlobalService) {
  }

  ngOnInit() {
  }

  edit(input: Article = new Article) {
    if (!input.Division) input.Division = this._Global.Division; // Assign Division if not assigned
    this.Original = input; // Store original
    this.Copy = new Article(JSON.parse(JSON.stringify(input)));
    this.dialogRef = this._Dialog.open(this.templateRef);
    this._Group.get().subscribe(res => {
      this.Groups = res;
    });
    if (this.Copy.ExternalId) this.pullFromExact();
    this._CdRef.detectChanges();
  }

  duplicate(input: Article = new Article) {
    this.Copy = new Article(JSON.parse(JSON.stringify(input)));
    this.Copy.Id = null;
    this.Copy.ExternalId = null;
    this.edit(this.Copy);
  }

  remove(object: Article) {
    this.isLoading = true;
    this._Article.remove(object).subscribe(res => {
      if (this.List) this.List.splice(this.List.findIndex(x => x.Id === object.Id), res.count);
      this.ListChange.emit(this.List);
      this.isLoading = false;
    }, () => this.isLoading = false);
  }

  calcVolume() {
    this.Copy.Volume = this.Copy.Length && this.Copy.Height && this.Copy.Width ? this.Copy.Volume = this.Copy.Length * this.Copy.Width * this.Copy.Height / 1000 : null;
  }

  async save() {
    this.isLoading = true;
    if (this.hasOrderChanged) await this.patchOrder();
    this._Article.upsert(this.Copy).subscribe(res => {
      this.isLoading = false;
      this.Copy = res;
      Object.assign(this.Original, res);
      const found = this.List.find(x => x.Id === res.Id);
      found ? Object.assign(found, res) : this.List.push(res); // Modify or push array list
      this.ListChange.emit(this.List);
      this._Dialog.getDialogById(this.dialogRef.id).close();
    }, err => {
      this.isLoading = false;
    });
  }

  isChecked(e: any): boolean {
    return this.Copy.Groups.indexOf(this.Copy.Groups.find(x => x.Id === e.Id)) !== -1;
  }

  update(e: MatSelectionListChange) {
    const value = e.options[0].value;
    const i = this.Copy.Groups.indexOf(this.Copy.Groups.find(x => x.Id === value.Id));
    i !== -1 ? this.Copy.Groups.splice(i, 1) : this.Copy.Groups.push(new Group(value));
  }

  pushToExact(object: Article = this.Copy) {
    this.isLoadingEO = true;
    this._Article.pushToExact(object).subscribe(res => {
      this.isLoadingEO = false;
      Object.assign(object, res);
    }, err => this.isLoadingEO = false);
  }

  pullFromExact(object: Article = this.Copy) {
    this.isLoadingEO = true;
    this._Article.pullFromExact(object).subscribe(res => {
      this.isLoadingEO = false;
      Object.assign(object, res);
    }, err => this.isLoadingEO = false);
  }

  // Swap position (order)
  swapPosition(art: Article, direction: number) {
    this.hasOrderChanged = true;
    const i = this.Copy.Children.indexOf(art);
    const temp = this.Copy.Children[i + direction];
    this.Copy.Children[i + direction] = art;
    this.Copy.Children[i] = temp;
    this.recalculate();
  }

  // Recalculate the order
  recalculate() {
    let i = 10;
    this.Copy.Children.forEach(child => {
      child.Order = i;
      i += 10;
    });

    // Assign the correct order to the parent
    this.Copy.Order = this.Copy.Children.find(x => x.Id === this.Copy.Id).Order;
  }

  // Save all the orders of the children
  patchOrder() {
    return Promise.all(this.Copy.Children
      .filter(art => art.Id !== this.Copy.Id)
      .map(art => {
        return {Id: art.Id, Order: art.Order};
      })
      .map(art => this._Article.patch(art).toPromise()));
  }

  openAttachmentController() {
    const dialog = this._Dialog.open(AttachmentControllerComponent, {
      data: {},
      minWidth: '60vw'
    });

    // If there an attachment has been picked
    dialog.afterClosed().subscribe((res: Attachment) => {
      if (res) this.Copy.Image = res.Path;
    });
  }

}
