import {BasketLine} from '../BasketLine/basket-line.object';
import {DefaultModel} from '../Default/default.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';
import {Group} from '../Group/group.object';
import {Locale} from '../Locales/locale.object';
import {PromotionBlock} from '../PromotionBlock/promotion-block.object';
import {Translation} from '../Translation/translation.object';

export class Promotion extends DefaultModel {
  Title: Translation[] = [];
  Code: string = null;
  DeliveryDate: Date = null;
  PreviewDate: Date = null;
  StartDate: Date = null;
  EndDate: Date = null;
  Type: DropdownOption = null;
  Promotion: Promotion = null;
  Operator = 'and'; // and | or
  Image: string = null;
  IsSalesOnly = false;
  IsVisibleOnHeader = false;
  IsPopUpOnLogin = false;
  IsVisibleBetweenProducts = false;
  Blocks: PromotionBlock[] = [];
  BasketLines: BasketLine[] = []; // Lines added when all conditions have been fullfilled
  Groups: Group[] = [];

  constructor(data: any = null) {
    super(data);
    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];
    this.Type = this.Type ? new DropdownOption(this.Type) : null;
    this.Promotion = this.Promotion ? new Promotion(this.Promotion) : null;

    this.Blocks.forEach((item, index) => {
      this.Blocks[index] = new PromotionBlock(item);
    });

    this.Groups.forEach((item, index) => {
      this.Groups[index] = new Group(item);
    });
  }

  getTitle(loc: Locale = new Locale()): string {
    const found = this.Title.find(x => x.Locale.Id === loc.Id);
    return found ? found.Value : 'NO_TRANSLATION';
  }

  getImage(): string {
    // return environment.apiUrl + '/Attachments/download/' + encodeURIComponent(this.Image);
    return this.Image ? 'https://api-aorta.edgardcooper.com/v1/Attachments/download/' + encodeURIComponent(this.Image) : null;
  }
}
