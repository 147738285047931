import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {SupportMessage} from './support-message.object';

@Injectable()
export class SupportMessageService {

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<SupportMessage[]> {
    return this.http
        .get<SupportMessage[]>(environment.apiUrl + '/SupportMessages', {params: ReqParams}).pipe(
            map(res => <SupportMessage[]>res),
            map(res => {
              return res.map(rel => new SupportMessage(rel));
            }));
  }

  getRecent(ReqParams: HttpParams = null): Observable<SupportMessage[]> {
    return this.http
        .get<SupportMessage[]>(environment.apiUrl + '/SupportMessages/getRecent', {params: ReqParams}).pipe(
            map(res => <SupportMessage[]>res),
            map(res => {
              return res.map(rel => new SupportMessage(rel));
            }));
  }

  getById(Id: string, ReqParams: HttpParams = null): Observable<SupportMessage> {
    return this.http
        .get<SupportMessage>(environment.apiUrl + '/SupportMessages/' + Id, {params: ReqParams}).pipe(
            map(res => <SupportMessage>res),
            map(res => {
              return new SupportMessage(res);
            }));
  }

  upsert(Relation: SupportMessage): Observable<SupportMessage> {
    return this.http
        .put(environment.apiUrl + '/SupportMessages', JSON.stringify(Relation)).pipe(
            map(res => <SupportMessage>res),
            map(res => {
              // Only update available props in relation. (Avoid to add irelevant props)
              for (const prop in res) if (prop in Relation) Relation[prop] = res[prop];
              return new SupportMessage(Relation);
            }));
  }

  remove(Relation: SupportMessage): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/SupportMessages/' + Relation.Id);
  }

  getCount(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/SupportMessages/count', {params: ReqParams}).pipe(
            map(res => <{ count: number }>res));
  }

}
