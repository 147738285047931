import {Directive, Input, OnChanges, SimpleChanges} from '@angular/core';
import {NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
  selector: '[errorValidator]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: ErrorValidatorDirective, multi: true}
  ]
})
export class ErrorValidatorDirective implements Validator, OnChanges {

  @Input('ngModel') inputValue;
  @Input('validation') validation: boolean;
  private _onChange: () => void;

  constructor() {
  }

  validate() {
    if (this.validation && this.inputValue !== null) {
      return {
        validation: {condition: this.validation}
      };
    }
    return null;
  }


  registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }


  ngOnChanges(changes: SimpleChanges): void {
    if ('validation' in changes) {
      if (this._onChange) this._onChange();
    }
  }
}