import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Address} from '../../models/Address/addresses.object';
import {DocumentService} from '../../models/Document/document.service';
import {Document} from '../../models/Document/document.object';
import {AddressService} from '../../models/Address/addresses.service';

@Component({
  selector: 'app-address-merger',
  templateUrl: './address-merger.component.html',
  styleUrls: ['./address-merger.component.css'],
  providers: [DocumentService, AddressService]
})
export class AddressMergerComponent implements OnInit {
  addresses: { address: Address, main: boolean }[] = [];
  documentsToMerge: Document[] = [];

  // for mat-spinners and presentation
  mergeLoading: boolean = false;
  mergeFinished: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private documentService: DocumentService, private dialogRef: MatDialogRef<AddressMergerComponent>) {
  }

  async ngOnInit() {
    this.getAddressData();
  }

  getAddressData() {
    this.addresses = this.data.addresses.map(a => {
      return {address: new Address(a), main: false};
    });
    // set first address to main address (address to keep)
    this.addresses[0].main = true;
  }

  // switch main address
  setMainAddress() {
    this.addresses[0].main = !this.addresses[0].main;
    this.addresses[1].main = !this.addresses[1].main;
  }

  async merge() {
    this.mergeLoading = true;
    const searchForAddress = this.addresses.find(x => !x.main).address;
    const replaceByAddress = this.addresses.find(x => x.main).address;
    this.documentService.moveAndDeleteAddress(searchForAddress.Id, replaceByAddress.Id).subscribe(res => {
      this.mergeLoading = false;
      this.mergeFinished = true;
    }, error => this.mergeLoading = false);
  }

  close() {
    // merge completed? send Id to remove address from parent dialog.
    this.dialogRef.close(this.mergeFinished ? this.addresses.find(x => !x.main).address.Id : null);
  }


}
