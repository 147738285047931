import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BasketLine} from '../../models/BasketLine/basket-line.object';

@Component({
  selector: 'app-basket-line-editor',
  templateUrl: './basket-line-editor.component.html',
  styleUrls: ['./basket-line-editor.component.css'],
  providers: []
})
export class BasketLineEditorComponent implements OnInit {

  // Original & deep copy
  Original: BasketLine = new BasketLine;
  Copy: BasketLine = new BasketLine;

  isLoading: boolean;

  @Input() List: BasketLine[] = [];
  @Output() ListChange: EventEmitter<BasketLine[]> = new EventEmitter<BasketLine[]>();

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<BasketLineEditorComponent>;

  constructor(private _Dialog: MatDialog, private _CdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  // Show editor & assign vars
  edit(entry: BasketLine = new BasketLine) {
    this.Original = entry; // Assign original
    this.Copy = new BasketLine(JSON.parse(JSON.stringify(entry))); // Deep copy
    this.dialogRef = this._Dialog.open(this.templateRef);
    this._CdRef.detectChanges();
  }

  save() {
    this.isLoading = true;
    Object.assign(this.Original, this.Copy);
    const found = this.List.find(x => x.Id === this.Copy.Id);
    found ? Object.assign(found, this.Copy) : this.List.push(this.Copy); // Modify or push array list
    this.ListChange.emit(this.List);
    this.isLoading = false;
    this._Dialog.getDialogById(this.dialogRef.id).close();
  }

  remove(entry: BasketLine) {
    const found = this.List.find(x => x.Id === entry.Id);
    found ? this.List.splice(this.List.indexOf(entry), 1) : false;
    this.ListChange.emit(this.List);
  }

}
