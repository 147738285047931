import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Activation} from '../../models/Activation/activation.object';
import {ActivationEntry} from '../../models/ActivationEntry/activation-entry.object';
import {ActivationEntryService} from '../../models/ActivationEntry/activation-entry.service';

@Component({
  selector: 'app-activation-entry-editor',
  templateUrl: './activation-entry-editor.component.html',
  providers: [ActivationEntryService],
  styleUrls: ['./activation-entry-editor.component.css']
})
export class ActivationEntryEditorComponent implements OnInit {

  // Array of all articles to show
  isLoading = false;
  Copy: ActivationEntry[] = []; // The deep copy
  @Input() Activation: Activation = null;
  @Output() ListChange: EventEmitter<ActivationEntry[]> = new EventEmitter<ActivationEntry[]>(); // When ready we're generating and sending out an Document.

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<ActivationEntryEditorComponent>;

  constructor(private _Dialog: MatDialog, private _ActivationEntry: ActivationEntryService) {
  }

  ngOnInit() {
  }

  // Create a deep copy and open
  open() {
    this.Copy = [];
    this.Activation.ActivationEntries.forEach(entry => this.Copy.push(new ActivationEntry(JSON.parse(JSON.stringify(entry))))); // Create a deep copy
    this.dialogRef = this._Dialog.open(this.templateRef, {width: '80vw'});
  }

  save() {
    this.isLoading = true;
    this._ActivationEntry.upsertAll(this.Copy).subscribe(res => {
      this.isLoading = false;
      this.ListChange.emit(res);
      this._Dialog.getDialogById(this.dialogRef.id).close();
    });
  }

  removeLine(object: ActivationEntry) {
    if (object.Id) {
      this.isLoading = true;
      this._ActivationEntry.remove(object).subscribe(res => {
        this.isLoading = false;
        this.removeOriginal(object);
      });
    } else {
      this.removeOriginal(object);
    }
  }

  removeOriginal(object: ActivationEntry) {
    this.Copy.splice(this.Copy.indexOf(object), 1);
    this.ListChange.emit(this.Copy);
  }

  newLine() {
    this.Copy.push(new ActivationEntry({Activation: this.Activation}));
  }

}
