import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Widget} from '../Widget/widget.object';
import {Account} from './account.object';

@Injectable()
export class AccountService {

  defaultInclude: any[] = [{'Relation': ['CustomerGroup']}, {'BasketLines': [{'PromotionOption': {'Block': {'Promotion': 'Title'}}}, {'Article': ['Unit', 'Packaging']}, 'Promotion', 'DiscountReason']}, 'MaritalStatus', {'Locale': {'Country': 'ShipmentArticle'}}, {'AccountDivisions': ['Division', 'Account']}, 'Type', {'Address': [{'Country': 'ShipmentArticle'}, 'Type']}, 'Role', {'DefaultDivision': [{'Locale': {'Country': 'ShipmentArticle'}}, 'DocumentTypes']}, {'Divisions': [{'Locale': {'Country': 'ShipmentArticle'}}, 'DocumentTypes']}, 'Modifier', 'Creator'];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<Account[]> {
    return this.http
        .get<Account[]>(environment.apiUrl + '/Accounts', {params: ReqParams}).pipe(
            map(res => <Account[]>res),
            map(res => {
              return res.map(rel => new Account(rel));
            }));
  }

  getById(Id: string, ReqParams: HttpParams = null): Observable<Account> {
    return this.http
        .get<Account>(environment.apiUrl + '/Accounts/' + Id, {params: ReqParams}).pipe(
            map(res => <Account>res),
            map(res => {
              return new Account(res);
            }));
  }

  registerStudent(payload: { account: Account, files: any[] }): Observable<Account> {
    return this.http
        .post<Account>(environment.apiUrl + '/Accounts/register', JSON.stringify(payload)).pipe(
            map(res => <Account>res),
            map(res => {
              // Modify original doc without destroying related objects (doclines)
              for (const prop in res) if (prop in payload.account) payload.account[prop] = res[prop];
              return new Account(payload.account);
            }));

  }

  upsert(Entry: Account): Observable<Account> {
    return this.http
        .put<Account>(environment.apiUrl + '/Accounts', JSON.stringify(Entry)).pipe(
            map(res => <Account>res),
            map(res => {
              // Modify original doc without destroying related objects (doclines)
              for (const prop in res) if (prop in Entry) Entry[prop] = res[prop];
              return new Account(Entry);
            }));

  }

  updateById(Entry: Account): Observable<Account> {
    return this.http
        .put<Account>(environment.apiUrl + '/Accounts/' + Entry.Id, JSON.stringify(Entry)).pipe(
            map(res => <Account>res),
            map(res => {
              // Modify original doc without destroying related objects (doclines)
              for (const prop in res) if (prop in Entry) Entry[prop] = res[prop];
              return Entry;
            }));

  }

  search(ReqParams: HttpParams = null): Observable<Account[]> {
    return this.http
        .get<Account[]>(environment.apiUrl + '/Accounts/search', {params: ReqParams}).pipe(
            map(res => <Account[]>res),
            map(res => {
              return res.map(rel => new Account(rel));
            }));
  }

  remove(Entry: Account): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/Accounts/' + Entry.Id);
  }

  getCount(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/Accounts/count', {params: ReqParams}).pipe(
            map(res => <{ count: number }>res));
  }

  login(Auth: { Email: string, Password: string }, ReqParams: HttpParams = null): Observable<any> {
    return this.http
        .post<any>(environment.apiUrl + '/Accounts/login', JSON.stringify(Auth), {
          withCredentials: true,
          params: ReqParams
        }).pipe(
            map(res => <Account>res),
            map(res => {
              return new Account(res);
            })
        );
  }

  logout(): Observable<any> {
    return this.http
        .post<any>(environment.apiUrl + '/Accounts/logout', null);
  }

  changePassword(Payload: { oldPassword: string, newPassword: string }): Observable<any> {
    return this.http
        .post<any>(environment.apiUrl + '/Accounts/change-password', JSON.stringify(Payload));
  }

  retrievePassword(Payload: { Email: string, Url: string }): Observable<any> {
    return this.http
        .post<any>(environment.apiUrl + '/Accounts/request-password', JSON.stringify(Payload));
  }

  resetPassword(Payload: { Email: string, Password: string, Password2: string, Hash: string }): Observable<any> {
    return this.http
        .post<any>(environment.apiUrl + '/Accounts/reset-password', JSON.stringify(Payload));
  }

  signinWithSecret(Id: string, ReqParams: HttpParams = null): Observable<any> {
    return this.http
        .post<any>(environment.apiUrl + '/Accounts/signinWithSecret/' + Id, {params: ReqParams});
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/Accounts/count', {params: ReqParams})
        .pipe(
            map(res => <{ count: number }>res)
        );
  }

  exportXlsx(ReqParams: HttpParams = null): Observable<Blob> {
    return this.http
        .get(environment.apiUrl + '/Accounts/export', {params: ReqParams, responseType: 'blob'})
        .pipe(
            map(res => {
              return new Blob([res], {type: 'application/vnd.ms-excel'});
            })
        );
  }

  getConvictionRate(ReqParams: HttpParams = null): Observable<Widget> {
    return this.http
        .get<Widget>(environment.apiUrl + '/Accounts/getConvictionRate', {params: ReqParams});
  }

  getScoreAverage(ReqParams: HttpParams = null): Observable<Widget> {
    return this.http
        .get<Widget>(environment.apiUrl + '/Accounts/getScoreAverage', {params: ReqParams});
  }

  linkWithExact(input: Account): Observable<Account> {
    return this.http
        .post(environment.apiUrl + '/Accounts/linkWithExact', JSON.stringify(input))
        .pipe(
            map(res => {
              for (const prop in res) if (prop in input) input[prop] = res[prop];
              return new Account(input);
            })
        );
  }
}
