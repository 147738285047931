import {Division} from '../Division/division.object';
import {Locale} from '../Locales/locale.object';
import {Translation} from '../Translation/translation.object';

export class DropdownOption {

  Id: string = null;
  ExternalId: string = null;
  Division: Division = null;
  Parent: DropdownOption = null;
  Model: string = null;
  Discriminator: string = null;
  Value: string = null;
  FreeTextField1: string = null;
  FreeNummericField1: number = null;
  FreeTextField2: string = null;
  FreeNummericField2: number = null;
  FreeBooleanField1 = false;
  FreeImageField1: string = null;
  Order: number = null;
  Color: string = null;
  IsTitle: boolean = null;
  IsActive: boolean = true;
  IsDisabled: boolean = true;
  DropdownOptions: DropdownOption[] = [];

  Translations: Translation[] = [];

  constructor(data: any = null) {
    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    if (this.Division) this.Division = new Division(this.Division);
    if (this.Parent) this.Parent = new DropdownOption(this.Parent);

    this.DropdownOptions.forEach((item, index) => {
      this.DropdownOptions[index] = new DropdownOption(item);
    });
    this.Translations.forEach((item, index) => {
      this.Translations[index] = new Translation(item);
    });
    if (this.FreeNummericField1) this.FreeNummericField1 = Number(this.FreeNummericField1); // Convert string to number.

  }

  getTranslation(loc: Locale = new Locale()): string {
    const found = this.Translations.find(x => loc && x.Locale && x.Locale.Id === loc.Id);
    return found ? found.Value : 'NO_TRANSLATION';
  }

  getImage(): string {
    // return environment.apiUrl + '/Attachments/download/' + encodeURIComponent(this.Image);
    return 'https://api-aorta.edgardcooper.com/v1/Attachments/download/' + encodeURIComponent(this.FreeImageField1);
  }
}
