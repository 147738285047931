import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Promotion} from './promotion.object';

@Injectable()
export class PromotionService {

  defaultInclude: any[] = [
    'Groups', 'Title', {'Type': 'Translations'}, 'Modifier', 'Creator',
    {
      relation: 'Blocks',
      scope: {
        include: [
          'Title',
          {
            relation: 'Options',
            scope: {
              include: [
                {
                  relation: 'BasketLines',
                  scope: {
                    include: [
                      {'Article': ['Unit', 'Packaging', 'Parent']},
                      'DiscountReason'
                    ],
                    order: 'OrderNumber ASC'
                  }
                }
              ],
              order: 'OrderNumber ASC'
            }
          }],
        order: 'OrderNumber ASC'
      }
    }
  ];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<Promotion[]> {
    return this.http
        .get<Promotion[]>(environment.apiUrl + '/Promotions/search', {params: ReqParams}).pipe(
            map(res => <Promotion[]>res),
            map(res => {
              return res.map(rel => new Promotion(rel));
            }),);
  }

  getById(Id: string, ReqParams: HttpParams = null): Observable<Promotion> {
    return this.http
        .get<Promotion>(environment.apiUrl + '/Promotions/' + Id, {params: ReqParams}).pipe(
            map(res => <Promotion>res),
            map(res => {
              return new Promotion(res);
            }));
  }

  upsert(Entry: Promotion): Observable<Promotion> {
    return this.http
        .put<Promotion>(environment.apiUrl + '/Promotions', JSON.stringify(Entry)).pipe(
            map(res => <Promotion>res),
            map(res => {
              // Modify original doc without destroying related objects (doclines)
              for (const prop in res) if (prop in Entry) Entry[prop] = res[prop];
              return new Promotion(Entry);
            }));

  }

  remove(Entry: Promotion): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/Promotions/' + Entry.Id);
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/Promotions/count', {params: ReqParams})
        .pipe(
            map(res => <{ count: number }>res)
        );
  }

  exportXlsx(ReqParams: HttpParams = null): Observable<Blob> {
    return this.http
        .get(environment.apiUrl + '/Groups/export', {params: ReqParams, responseType: 'blob'})
        .pipe(
            map(res => {
              return new Blob([res], {type: 'application/vnd.ms-excel'});
            })
        );
  }

  searchRepresentativePromotions(ReqParams: HttpParams = null): Observable<Promotion[]> {
    return this.http
        .get<Promotion[]>(environment.apiUrl + '/Promotions/searchRepresentativePromotions', {params: ReqParams}).pipe(
            map(res => <Promotion[]>res),
            map(res => {
              return res.map(rel => new Promotion(rel));
            }),
        );
  }
}
