import {HttpParams} from '@angular/common/http';
import {ChangeDetectorRef, Component, forwardRef, Input, OnInit} from '@angular/core';
import {UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Mail} from '../../models/Mail/mail.object';
import {MailService} from '../../models/Mail/mail.service';
import {FilterObject} from '../../objects/filter-object';

@Component({
  selector: 'app-mail-template-selector',
  templateUrl: './selector.component.html',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MailTemplateSelectorComponent), multi: true},
  ],
})
export class MailTemplateSelectorComponent implements OnInit {

  _value: any = null; // Could be Mail or Mail[]

  Mails: Mail[] = [];
  @Input() placeholder = 'Country';
  @Input() multiple = false;
  @Input() getOnlyId = false;
  @Input() required = false;
  @Input() disabled = false;

  ChildControl = new UntypedFormControl();
  Selected: string = null; // Default selected
  filterObject: FilterObject = new FilterObject();

  get value() {
    return this._value;
  }

  set value(val) {
    if (!val || val instanceof Mail || val instanceof Array || this.Mails.indexOf(val) > -1) {
      this._value = val;
      this.onChange(val);
    }
  }

  onChange: any = () => {
  };

  ngOnInit() {
    const Params: HttpParams = new HttpParams().append('filter', JSON.stringify(this.filterObject));
    this._Mail.get(Params).subscribe(res => {
      this.Mails = res;
      this._CdRef.detectChanges();
    });
  }

  compareWith(o1: any, o2: any) {
    return o1 && o2 && o1.Id && o2.Id && o1.Id === o2.Id;
  }

  onTouched: any = () => {
  };

  constructor(private _Mail: MailService, private _CdRef: ChangeDetectorRef) {
    this.filterObject.include = _Mail.defaultInclude;
    this.filterObject.limit = null;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    this.value = value;
  }

}
