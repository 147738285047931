import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppComponent} from '../../app.component';
import {DocumentEditorComponent} from '../../dialogs/document-editor/document-editor.component';
import {VisualArticlePickerComponent} from '../../dialogs/visual-article-picker/visual-article-picker.component';
import {BasketLine} from '../../models/BasketLine/basket-line.object';
import {Document} from '../../models/Document/document.object';
import {DocumentService} from '../../models/Document/document.service';
import {DocumentType} from '../../models/DocumentType/document-type.object';
import {DropdownOption} from '../../models/DropdownOption/dropdown-option.object';
import {DropdownOptionService} from '../../models/DropdownOption/dropdown-option.service';
import {Relation} from '../../models/Relation/relation.object';
import {SupportMessage} from '../../models/SupportMessage/support-message.object';
import {SupportMessageService} from '../../models/SupportMessage/support-message.service';
import {AlertService} from '../../services/alert.service';
import {GlobalService} from '../../services/global.service';
import {HTTPStatusService} from '../../services/loader.service';


@Component({
  selector: 'app-authenticated',
  templateUrl: './authenticated.component.html',
  styleUrls: ['./authenticated.component.css'],
  providers: [DropdownOptionService, SupportMessageService, DocumentService]
})
export class AuthenticatedComponent implements OnInit {

  leftSidebarOpened = true;
  rightSidebarOpened = false;
  recentMessages: SupportMessage[] = [];
  HttpActivity = 0;

  @ViewChild('docEditor') docEditor: DocumentEditorComponent;
  @ViewChild('aPicker') ArticlePicker: VisualArticlePickerComponent;

  breadcrumbs: BreadCrumb[] = [];
  dropdownOptionsOrganisation: DropdownOption[] = [];

  constructor(private _Route: ActivatedRoute,
              private _Router: Router,
              public _App: AppComponent,
              public _Global: GlobalService,
              private _DropdownOptionService: DropdownOptionService,
              private _Document: DocumentService,
              private _Alert: AlertService,
              public _SupportMessage: SupportMessageService,
              private _httpStatus: HTTPStatusService,
              private _CdRef: ChangeDetectorRef) {
  }


  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<BreadCrumb> = []): Array<BreadCrumb> {
    const label = route.routeConfig && route.routeConfig.data && route.routeConfig.data.breadcrumb ? route.routeConfig.data.breadcrumb : 'Home';
    const path = route.routeConfig ? route.routeConfig.path : '';
    const nextUrl = `${url}${path}/`;

    if (route.routeConfig && route.routeConfig.data && route.routeConfig.data['breadcrumb']) {
      breadcrumbs.push({
        label: label,
        url: nextUrl,
        params: []
      });
    }
    return route.firstChild ? this.buildBreadCrumb(route.firstChild, nextUrl, breadcrumbs) : breadcrumbs;
  }

  ngOnInit() {
    // Do it for the first time, since there has been any navigation yet
    this.breadcrumbs = this.buildBreadCrumb(this._Route.root);
    // Do it for every navigation after that
    this._Router.events.subscribe(event => {
      this.breadcrumbs = this.buildBreadCrumb(this._Route.root);
    });

    this.initLoadingBar();

  }

  logout() {
    this._Router.navigate(['/login']);
  }

  initLoadingBar() {

    // Loading Bar
    this._httpStatus.requests.subscribe(res => {
      if (!(res.closed === 0 && res.open === 0)) {
        res.closed === 0 && res.open === 1 ? this.HttpActivity = 50 : this.HttpActivity = (res.closed / res.open) * 100;
        if (!this._CdRef['destroyed']) {
          this._CdRef.detectChanges();
        }
      } else {
        this.HttpActivity = 0;
      }
    });
  }

  makeOrder(e: { lines: BasketLine[], relation: Relation }) {

    // Create a new OrderConfirmation document
    const doc = new Document({Division: this._Global.Division, Type: new DocumentType({Id: 'cbaab5fc-50e5-4969-b27c-6c02a6bfe3fa'})});

    // Generate the documentlines
    doc.addDocumentLinesByBasketLines(e);

    if (e.relation) {
      doc.Relation = e.relation;
      if (e.relation.DefaultContact) doc.Contact = e.relation.DefaultContact;
      if (e.relation.DefaultAddress) doc.Address = e.relation.DefaultAddress;
      if (e.relation.Locale) doc.Locale = e.relation.Locale;
    }
    doc.Source = 'Visual Article Picker';
    doc.Account = this._Global.Account;

    this.docEditor.edit(doc);
  }

  filteredDocumentTypes(): DocumentType[] {
    return this._Global.Division.DocumentTypes.filter(x => ['cbaab5fc-50e5-4969-b27c-6c02a6bfe3fa', 'd5cc4fd9-1801-4527-81e1-b9de93076dab'].indexOf(x.Id) !== -1);
  }

}

export interface BreadCrumb {
  label: string;
  url: string;
  params: string[];
}
