import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthenticatedComponent} from './layouts/authenticated/authenticated.component';
import {ConditionsComponent} from './modules/unauthenticated/conditions/conditions.component';
import {PrivacyComponent} from './modules/unauthenticated/privacy/privacy.component';
import {AuthenticationGuard} from './services/authentication.guard.service';

const routes: Routes = [

  {
    path: '',
    component: AuthenticatedComponent,
    children: [
      {
        canActivate: [AuthenticationGuard],
        path: '',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),

      },
      {
        canActivate: [AuthenticationGuard],
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),

      },
      {
        canActivate: [AuthenticationGuard],
        path: 'crm',
        loadChildren: () => import('./modules/crm/crm.module').then(m => m.CrmModule),

      },
      {
        canActivate: [AuthenticationGuard],
        path: 'activation',
        loadChildren: () => import('./modules/activation/activation.module').then(m => m.ActivationModule),

      },
      {
        canActivate: [AuthenticationGuard],
        path: 'activation-student',
        loadChildren: () => import('./modules/activation-student/activation-student.module').then(m => m.ActivationStudentModule),

      },
      {
        canActivate: [AuthenticationGuard],
        path: 'documents',
        loadChildren: () => import('./modules/document/document.module').then(m => m.DocumentModule),

      },
      {
        canActivate: [AuthenticationGuard],
        path: 'warehouse',
        loadChildren: () => import('./modules/warehouse/warehouse.module').then(m => m.WarehouseModule),
      },
      {
        canActivate: [AuthenticationGuard],
        path: 'reseller',
        loadChildren: () => import('./modules/reseller/reseller.module').then(m => m.ResellerModule),
      },
      {
        canActivate: [AuthenticationGuard],
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
      },
      {
        canActivate: [AuthenticationGuard],
        path: 'support',
        loadChildren: () => import('./modules/support/support.module').then(m => m.SupportModule),
      },
      {
        canActivate: [AuthenticationGuard],
        path: 'shared/conditions',
        component: ConditionsComponent,
        data: {breadcrumb: 'Voorwaarden'}
      },
      {
        canActivate: [AuthenticationGuard],
        path: 'shared/privacy',
        component: PrivacyComponent,
        data: {breadcrumb: 'Privacy policy'}
      }
    ]
  },
  {
    path: '',
    loadChildren: () => import('./modules/unauthenticated/unauthenticated.module').then(m => m.UnauthenticatedModule),
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
