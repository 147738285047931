import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {PriceList} from './price-list.object';

@Injectable()
export class PriceListService {

  defaultInclude: any[] = [{'PriceListDetails': 'Article'}, 'Currency'];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<PriceList[]> {
    return this.http
        .get<PriceList[]>(environment.apiUrl + '/PriceLists', {params: ReqParams}).pipe(
            map(res => <PriceList[]>res),
            map(res => {
              return res.map(rel => new PriceList(rel));
            })
        );
  }

  getById(Id: string, ReqParams: HttpParams = null): Observable<PriceList> {
    return this.http
        .get<PriceList>(environment.apiUrl + '/PriceLists/' + Id, {params: ReqParams}).pipe(
            map(res => <PriceList>res),
            map(res => {
              return new PriceList(res);
            })
        );
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/PriceLists/count', {params: ReqParams})
        .pipe(
            map(res => <{ count: number }>res)
        );
  }

  pullFromExact(input: PriceList): Observable<PriceList> {
    return this.http
        .post(environment.apiUrl + '/PriceLists/pullFromExact', JSON.stringify(input),)
        .pipe(
            map(res => {
              for (const prop in res) if (prop in input) input[prop] = res[prop];
              return input;
            })
        );
  }
}
