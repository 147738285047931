import {DefaultModel} from '../Default/default.object';
import {Document} from '../Document/document.object';
import {DocumentType} from '../DocumentType/document-type.object';

export class Schedule extends DefaultModel {

  Document: Document = null; // Default open
  Seconds: number = null;
  Minutes: number = null;
  Hours: number = null;
  MonthOfYear: number = null;
  DayOfMonth: number = null;
  DayOfWeek: number = null;
  IsActive = true;
  DocumentType: DocumentType = null;

  constructor(data: any = null) {

    super(data);

    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];
    this.Document = this.Document ? new Document(this.Document) : null;
    this.DocumentType = this.DocumentType ? new DocumentType(this.DocumentType) : null;


  }

  getTimeString(): string {
    const Months: Array<string> = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'];
    const Days: Array<string> = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
    let timeString = '';
    if (this.DayOfWeek || this.DayOfWeek === 0) timeString += 'Iedere ' + Days[this.DayOfWeek];
    if (this.DayOfMonth || this.DayOfMonth === 0) timeString += 'Iedere ' + (this.DayOfMonth > 19 || this.DayOfMonth === 1 ? this.DayOfMonth + 'ste' : this.DayOfMonth + 'de') + ' van de maand';
    if (this.MonthOfYear || this.MonthOfYear === 0) timeString += ', in ' + Months[this.MonthOfYear];
    return timeString;
  }
}
