import {
  ApplicationRef,
  ComponentFactoryResolver,
  Directive,
  EmbeddedViewRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  Output
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from './confirm-dialog.component';

@Directive({
  selector: '[appConfirmDialog]',
})
export class ConfirmDialogDirective {
  @Input() title = 'Are you sure?';
  @Input() message = 'Are you sure you want delete this?';
  @Input() action: any = null;
  @Output() confirm: EventEmitter<boolean> = new EventEmitter<boolean>();
  dialog: ConfirmDialogComponent;

  constructor(private _Dialog: MatDialog,
              private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector) {
  }

  @HostListener('click') onClick() {
    // 1. Create a component reference from the component
    const componentRef = this.componentFactoryResolver
        .resolveComponentFactory(ConfirmDialogComponent)
        .create(this.injector);

    // 2. Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // 3. Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;

    // 4. Append DOM element to the body
    document.body.appendChild(domElem);

    componentRef.instance.open(this.title, this.message, componentRef);
    componentRef.instance.confirm.subscribe(res => {
      this.confirm.emit(res);
    });
  }


}
