import {Article} from '../Article/article.object';
import {DefaultModel} from '../Default/default.object';
import {Locale} from '../Locales/locale.object';
import {Translation} from '../Translation/translation.object';

export class Country extends DefaultModel {

  IsoCode: string = null;
  IsoCode3166: string = null;
  Name: string = null;
  Number: number = null;
  ShipmentArticle: Article = null;
  ShipmentFranco: number = null;
  PalletShipmentFrom: number = null;
  IsEurope: boolean = null;
  IsContactable: boolean = false;
  ContactMobile: string = null;
  ContactEmail: string = null;
  Locales: Locale[] = [];
  Translations: Translation[] = [];
  PostcodePattern: string = null;
  PostcodeExample: string = null;

  constructor(data: any = null) {

    super(data);

    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.ShipmentArticle = this.ShipmentArticle ? new Article(this.ShipmentArticle) : null;

    if (this.Locales) {
      this.Locales.forEach((item, index) => {
        this.Locales[index] = new Locale(item);
      });
    }
    if (this.Translations) {
      this.Translations.forEach((item, index) => {
        this.Translations[index] = new Translation(item);
      });
    }
  }

  getTranslation(loc: Locale = new Locale()): string {
    const found = this.Translations.find(x => x.Locale.Id === loc.Id);
    return found ? found.Value : 'NO_TRANSLATION';
  }

}
