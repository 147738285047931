import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'ppToDate'
})
export class ppToDate implements PipeTransform {

  transform(value: string): any {
    return value ? moment.utc(value).local().toDate() : null;
  }

}
