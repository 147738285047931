import {Article} from '../Article/article.object';
import {DefaultModel} from '../Default/default.object';
import {Group} from '../Group/group.object';
import {Relation} from '../Relation/relation.object';

export class GroupSubscription extends DefaultModel {
  Group: Group = null;
  Article: Article = null;
  Relation: Relation = null;

  constructor(data: any = null) {
    super(data);
    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];
    this.Group = this.Group ? new Group(this.Group) : null;
    this.Article = this.Article ? new Article(this.Article) : null;
    this.Relation = this.Relation ? new Relation(this.Relation) : null;

  }
}
