import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {Attachment} from '../Attachment/attachment.object';
import {Contact} from '../Contact/contact.object';
import {Document} from '../Document/document.object';
import {Email} from '../Email/email.object';
import {Mail} from './mail.object';

@Injectable()
export class MailService {
  defaultInclude = [{'MailTranslations': {'Locale': {'Country': 'ShipmentArticle'}}}, 'Division', 'Modifier', 'Creator'];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<Mail[]> {
    return this.http
        .get<Mail[]>(environment.apiUrl + '/Mails', {params: ReqParams})
        .pipe(
            map(res => <Mail[]>res),
            map(res => {
              return res.map(rel => new Mail(rel));
            })
        );
  }

  getById(Id: string, ReqParams: HttpParams = null): Observable<Mail> {
    return this.http
        .get<Mail>(environment.apiUrl + '/Mails/' + Id, {params: ReqParams})
        .pipe(
            map(res => <Mail>res),
            map(res => {
              return new Mail(res);
            })
        );
  }

  upsert(Entry: Mail): Observable<Mail> {
    return this.http
        .put<Mail>(environment.apiUrl + '/Mails', JSON.stringify(Entry))
        .pipe(
            map(res => <Mail>res),
            map(res => {
              // Modify original doc without destroying related nexted objects
              for (const prop in res) if (prop in Entry) Entry[prop] = res[prop];
              return new Mail(Entry);
            })
        );
  }

  remove(Entry: Mail): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/Mails/' + Entry.Id);
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/Mails/count', {params: ReqParams})
        .pipe(
            map(res => <{ count: number }>res)
        );
  }

  sendMail(input: Email): Observable<Email> {
    return this.http
        .post(environment.apiUrl + '/Mails/sendMail', JSON.stringify(input))
        .pipe(
            map(res => {
              return input;
            })
        );
  }

  sendMailWithoutTemplate(input: { contacts: Contact[], fromName: string, fromEmail: string, subject: string, mailHTML: string, attachments: Attachment[], documents: Document[] }): Observable<Email> {
    return this.http
        .post(environment.apiUrl + '/Mails/sendMailWithoutTemplate', JSON.stringify(input))
        .pipe(
            map(res => {
              return new Email(input);
            })
        );
  }
}
