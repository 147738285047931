import {DefaultModel} from '../Default/default.object';
import {Locale} from '../Locales/locale.object';
import {MailTranslation} from '../MailTranslations/mail-translations.object';

export class Mail extends DefaultModel {

  Locale: Locale = null;
  DefaultTranslation: MailTranslation = null;
  Title: string = null;
  Description: string = null;
  Variables: string[] = []; // Default values
  Body: string = null;

  MailTranslations: MailTranslation[] = [];

  constructor(data: any = null) {
    super();


    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.Locale = this.Locale ? new Locale(this.Locale) : null;
    this.DefaultTranslation = this.DefaultTranslation ? new MailTranslation(this.DefaultTranslation) : null;
    this.MailTranslations.forEach((item, index) => {
      this.MailTranslations[index] = new MailTranslation(item);
    });
  }
}
