import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {Timeline} from './timeline.object';

@Injectable()
export class TimelineService {

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<Timeline[]> {
    return this.http
        .get<Timeline[]>(environment.apiUrl + '/Timelines', {params: ReqParams})
        .pipe(
            map(res => <Timeline[]>res),
            map(res => {
              return res.map(rel => new Timeline(rel));
            })
        );
  }

  upsert(Entry: Timeline): Observable<Timeline> {
    return this.http
        .put<Timeline>(environment.apiUrl + '/Timelines', JSON.stringify(Entry))
        .pipe(
            map(res => <Timeline>res),
            map(res => {
              // Modify original doc without destroying related objects (doclines)
              for (const prop in res) if (prop in Entry) Entry[prop] = res[prop];
              return new Timeline(Entry);
            })
        );

  }

  remove(Entry: Timeline): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/Timelines/' + Entry.Id);
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/Timelines/count', {params: ReqParams})
        .pipe(
            map(res => <{ count: number }>res)
        );
  }

}
