import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError as observableThrowError} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';


@Injectable()
export class HTTPStatusService {
  public requestInFlight$: BehaviorSubject<{ open: number, closed: number }>;
  public requests = new Observable<{ open: number, closed: number }>();
  open = 0;
  closed = 0;

  constructor() {
    this.requestInFlight$ = new BehaviorSubject({open: 0, closed: 0});
    this.requests = this.requestInFlight$.asObservable();
  }

  setHttpStatus(request: { open: number, closed: number }) {
    this.open = this.open + request.open;
    this.closed = this.closed + request.closed;
    if (this.open === this.closed) {
      this.open = 0;
      this.closed = 0;
    }
    this.requestInFlight$.next({open: this.open, closed: this.closed});
  }
}

@Injectable()
export class HTTPListenerService implements HttpInterceptor {
  constructor(private status: HTTPStatusService) {
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
        map(event => {
          event.type === 0 ? this.status.setHttpStatus({open: 1, closed: 0}) : false;
          return event;
        }),
        catchError(error => {
          return observableThrowError(error);
        }),
        finalize(() => {
          this.status.setHttpStatus({open: 0, closed: 1});
        })
    );
  }
}
