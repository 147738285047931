import {HttpParams} from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import {Timeline} from '../../models/Timeline/timeline.object';
import {TimelineService} from '../../models/Timeline/timeline.service';
import {FilterObject} from '../../objects/filter-object';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css'],
  providers: [TimelineService]
})
export class TimelineComponent implements OnInit {

  @Input() Model: string = null;
  @Input() ParentId: string = null;
  @Input() Title = 'Timeline';

  List: Timeline[] = [];
  filterObject: FilterObject = new FilterObject();

  constructor(private _Timeline: TimelineService) {
    this.filterObject.include = ['Type'];
    this.filterObject.order = 'Start DESC';
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.filterObject.where = {'and': [{'ParentId': this.ParentId}, {'Model': this.Model}]};
    const Params = new HttpParams().append('filter', JSON.stringify(this.filterObject));
    this._Timeline.get(Params).subscribe(res => this.List = res);
  }

  getIcon(id: string): string {
    switch (id) {
      case '3aa5810a-5280-41ef-80cd-7dceac3427be': { // Meeting
        return 'group';
      }
      case '487be482-8103-4b55-a766-3ddb2a22fc5d': { // Call
        return 'phone';
      }
      case '9add2517-d6d7-4081-b666-76b4a03a93c8': { // Email
        return 'mail';
      }
      default: { // Other + Note
        return 'note';
      }
    }

  }
}
