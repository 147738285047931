import {HttpParams} from '@angular/common/http';
import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSelectionListChange} from '@angular/material/list';
import * as FileSaver from 'file-saver';
import {environment} from '../../../environments/environment';
import {FileFolderEditorComponent} from '../../dialogs/file-folder-editor/file-folder-editor.component';
import {FileUploaderComponent} from '../../dialogs/file-uploader/file-uploader.component';
import {Attachment} from '../../models/Attachment/attachment.object';
import {AttachmentService} from '../../models/Attachment/attachment.service';
import {AlertService} from '../../services/alert.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-attachment-browser',
  templateUrl: './attachment-browser.component.html',
  styleUrls: ['./attachment-browser.component.scss'],
  providers: [AttachmentService, {provide: MAT_DIALOG_DATA, useValue: ''}, {provide: MatDialogRef , useValue: {} }]
})
export class AttachmentBrowserComponent implements OnInit {

  @Input() MultiPathes: { virtualFolder: string, path: string }[] = []; // When Title is defined, the folders will be wrapped in a virtual folder with name Title.

  rootAttachment: Attachment = new Attachment({Name: '/'}); // Virtually attachment to wrap all the children into.
  currentDirectory: Attachment = this.rootAttachment; // the currenly work directory
  Checked: Attachment[] = []; // All selected files to export, download, zip, email,..
  isLoading = false; // the preloader
  showThumbnails = false;
  @Input() pickerMode: boolean = false; // Just pick one image
  @Output() change: EventEmitter<Attachment[]> = new EventEmitter<Attachment[]>();


  constructor(private _Dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private _Attachment: AttachmentService, private _Alert: AlertService, public _Global: GlobalService) {
  }

  getData() {
    this.currentDirectory = this.rootAttachment; // navigate back to root
    this.isLoading = true;
    const Params: HttpParams = new HttpParams().append('multiPathes', JSON.stringify(this.MultiPathes));
    this._Attachment.list(Params).subscribe(res => {
      this.isLoading = false;
      this.rootAttachment.Children = res;
    }, () => this.isLoading = false);
  }

  ngOnInit() {
    this.getData();
  }

  getURLencoded(AttachmentIn: Attachment) {
    return environment.apiUrl + '/Attachments/download/' + encodeURIComponent(AttachmentIn.Path);
  }

  setCurrentDirectory(input: Attachment) {
    input.IsOpen = !input.IsOpen;
    this.currentDirectory = input;
    this.Checked = [];
    this.change.emit(this.Checked);
  }

  // Checks or attachment exists in array or not
  isChecked(e: Attachment): boolean {
    return this.Checked.indexOf(e) !== -1;
  }

  // When checkbox gets triggered (status changed)
  update(e: MatSelectionListChange) {
    const value = e.options[0].value;
    const i = this.Checked.indexOf(value);
    i !== -1 ? this.Checked.splice(i, 1) : this.Checked.push(value);
  }

  openSelected() {
    this.Checked.forEach(selected => {
      this.openAttachment(selected);
    });
  }

  openAttachment(input: Attachment) {
    window.open(this.getURLencoded(input), '_blank');
  }

  downloadAttachment(input: Attachment) {
    this._Attachment.download(input).subscribe(res => FileSaver.saveAs(res, input.Name));
  }

  downloadSelected() {
    this.Checked.forEach(selected => {
      this.downloadAttachment(selected);
    });
  }

  deleteAttachment(input: Attachment) {
    this._Attachment.remove(input).subscribe(res => {
      this.currentDirectory.Children.splice(this.currentDirectory.Children.indexOf(input), res.count);
      this.Checked.splice(this.Checked.indexOf(input), res.count);
    });
  }

  copyToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._Alert.sendMessage('"' + val + '" werd gekopieerd.', 'success');
  }

  toggleCheck(e: MatCheckboxChange, f: Attachment) {
    e.checked ? this.Checked.push(f) : this.Checked.splice(this.Checked.indexOf(f), 1);
    this.change.emit(this.Checked);
  }

  deleteFiles() {
    this.Checked.forEach(attachment => {
      this.deleteAttachment(attachment);
    });
  }

  disableCheckbox(f: Attachment) {
    return this.pickerMode && this.Checked.length > 0 && this.Checked.indexOf(f) === -1;
  }

  // Dialogs
  openFileUploader() {
    const dialog = this._Dialog.open(FileUploaderComponent, {data: {path: this.currentDirectory.Path}, minWidth: '60vw'});
    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.currentDirectory.Children = this.currentDirectory.Children.concat(res);
      }
    });
  }

  openFolderEditor() {
    const dialog = this._Dialog.open(FileFolderEditorComponent, {data: {currentDirectory: this.currentDirectory}});
  }

}
