import {Article} from '../Article/article.object';
import {DefaultModel} from '../Default/default.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';
import {Promotion} from '../Promotion/promotion.object';
import {PromotionCode} from '../PromotionCode/promotion-code.object';
import {Relation} from '../Relation/relation.object';

export class Group extends DefaultModel {
  Type: DropdownOption = null;
  Title: string = null;
  Description: string = null;
  Relations: Relation[] = [];
  Articles: Article[] = [];
  Promotions: Promotion[] = [];
  PromotionCodes: PromotionCode[] = [];
  Start: Date = null;
  End: Date = null;
  IsMatch: boolean = false;

  constructor(data: any = null) {
    super(data);
    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];
    this.Type = this.Type ? new DropdownOption(this.Type) : null;
    this.Articles.forEach((item, index) => {
      this.Articles[index] = new Article(item);
    });
    this.Relations.forEach((item, index) => {
      this.Relations[index] = new Relation(item);
    });
    this.Promotions.forEach((item, index) => {
      this.Promotions[index] = new Promotion(item);
    });
    this.PromotionCodes.forEach((item, index) => {
      this.PromotionCodes[index] = new PromotionCode(item);
    });
  }
}
