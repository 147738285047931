export class CalendarObject {

  // Variables for calendar
  public id: string = null;
  public title: string = null;
  public start: Date = null;
  public end: Date = null;
  public color: string = null;
  public instance: any = null;
  public rendering: string = null;
  public allDay = false;

  constructor(data: any = null) {
    for (const prop in data) if (prop in this) this[prop] = data[prop];
  }

}
