import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';
import {ActivationAccount} from '../../models/ActivationAccount/activation-account.object';
import {ActivationAccountService} from '../../models/ActivationAccount/activation-account.service';
import {Contact} from '../../models/Contact/contact.object';
import {Email} from '../../models/Email/email.object';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-activation-account-editor',
  templateUrl: './activation-account-editor.component.html',
  styleUrls: ['./activation-account-editor.component.css'],
  providers: [ActivationAccountService]
})
export class ActivationAccountEditorComponent implements OnInit {

  // Original list to modify
  @Input() List: ActivationAccount[] = [];
  @Output() ListChange: EventEmitter<ActivationAccount[]> = new EventEmitter<ActivationAccount[]>();
  @Input() localOnly = false; // If on true, we won't upsert the database, only the local ngModel (used for CRM Relation editor)
  @Input() Student = false; // If on true, change lay-out to fit a student

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<ActivationAccountEditorComponent>;

  // Original and copy;
  Original: ActivationAccount = new ActivationAccount;
  Copy: ActivationAccount = new ActivationAccount;

  isLoading = false;

  constructor(private _Dialog: MatDialog, private _ActivationAccount: ActivationAccountService, public _Global: GlobalService) {
  }

  ngOnInit() {

  }

  edit(input: ActivationAccount = new ActivationAccount()) {
    this.Original = input; // Store original
    if (!input.Division) input.Division = this._Global.Division; // Assign Division if not assigned
    this.Copy = new ActivationAccount(JSON.parse(JSON.stringify(input)));
    this.dialogRef = this._Dialog.open(this.templateRef);
  }

  remove(object: ActivationAccount) {
    if (object.Id) {
      this._ActivationAccount.remove(object).subscribe(res => {
        this.removeOriginal(object);
      });
    } else {
      this.removeOriginal(object);
    }
  }

  removeOriginal(object: ActivationAccount = this.Copy) {
    this.List.splice(this.List.indexOf(object), 1);
    this.ListChange.emit(this.List);
    this.isLoading = false;
  }

  save(object: ActivationAccount = this.Copy) {
    this.isLoading = true;
    if (this.localOnly) {
      this.updateOriginal(object);
    } else {
      this._ActivationAccount.upsert(object).subscribe(res => {
        this.updateOriginal(res);
      });
    }
  }

  updateOriginal(res: ActivationAccount = this.Copy) {
    this.isLoading = false;
    this.Copy = res;
    Object.assign(this.Original, res);
    const found = this.List.find(x => (x.Id === this.Copy.Id && x.Id !== null) || (x.Id === null && x.randomNumber === this.Copy.randomNumber));
    found ? Object.assign(found, res) : this.List.push(res); // Modify or push array list
    this.ListChange.emit(this.List);
    this._Dialog.getDialogById(this.dialogRef.id).close();
  }

  // Generate e-mail layout
  generateEmail(object: ActivationAccount): Email {
    return new Email({
      Contacts: [new Contact({FirstName: object.Account.FirstName, LastName: object.Account.LastName, Email: object.Account.Email})],
      Variables: [
        {name: 'student:fullName', value: object.Account.FirstName + ' ' + object.Account.LastName},
        {
          name: 'activation:fullAddress',
          value: object.Activation && object.Activation.Address ? object.Activation.Address.getFullAddress() : ''
        }
      ]
    });
  }

  timeToSeconds(time: string): number {
    return moment.duration(time).asSeconds();
  }

  secondsToTime(): string {
    return moment.utc(this.Copy.Break * 1000).format('HH:mm');
  }

}
