export class Day {
  open = true;
  from: string = '08:00';
  till: string = '17:00';
  pauze: { from: string, till: string } = {from: '12:00', till: '12:00'};

  constructor(data: any = null) {
    for (const prop in data) if (prop in this) this[prop] = data[prop];
  }
}

export class OpeningHours {

  ma: Day = new Day();
  di: Day = new Day();
  wo: Day = new Day();
  do: Day = new Day();
  vr: Day = new Day();
  za: Day = new Day({open: false, from: null, till: null, pauze: {from: null, till: null}});
  zo: Day = new Day({open: false, from: null, till: null, pauze: {from: null, till: null}});

  constructor(data: any = null) {
    for (const prop in data) if (prop in this) this[prop] = data[prop];
    this.ma = this.ma ? new Day(this.ma) : null;
    this.di = this.di ? new Day(this.di) : null;
    this.wo = this.wo ? new Day(this.wo) : null;
    this.do = this.do ? new Day(this.do) : null;
    this.vr = this.vr ? new Day(this.vr) : null;
    this.za = this.za ? new Day(this.za) : null;
    this.zo = this.zo ? new Day(this.zo) : null;


  }

  getKeys(): Array<string> {
    const keys = Object.keys(this);
    return keys;
  }
}
