import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Timeline} from '../../models/Timeline/timeline.object';
import {TimelineService} from '../../models/Timeline/timeline.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-timeline-editor',
  templateUrl: './timeline-editor.component.html',
  styleUrls: ['./timeline-editor.component.css'],
  providers: [TimelineService]
})
export class TimelineEditorComponent implements OnInit {

  // Original list to modify
  @Input() List: Timeline[] = [];
  @Output() ListChange: EventEmitter<Timeline[]> = new EventEmitter<Timeline[]>();

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<TimelineEditorComponent>;

  // Original and copy;
  Original: Timeline = new Timeline;
  Copy: Timeline = new Timeline;

  isLoading = false;

  constructor(private _Dialog: MatDialog, private _Timeline: TimelineService, private _CdRef: ChangeDetectorRef, private _Global: GlobalService) {
  }

  ngOnInit() {

  }

  edit(input: Timeline = new Timeline) {
    if (!input.Division) input.Division = this._Global.Division; // Assign Division if not assigned
    this.Original = input; // Store original
    this.Copy = new Timeline(JSON.parse(JSON.stringify(input)));
    this.dialogRef = this._Dialog.open(this.templateRef);
    this.Copy.Start = this.Copy.Start ? this.Copy.Start : new Date();
    this.Copy.End = this.Copy.End ? this.Copy.End : new Date();
    this._CdRef.detectChanges();
  }

  remove(object: Timeline) {
    this.isLoading = true;

    this._Timeline.remove(object).subscribe(res => {
      if (this.List) this.List.splice(this.List.findIndex(x => x.Id === object.Id), res.count);
      this.ListChange.emit(this.List);
      this.isLoading = false;
      this._Dialog.getDialogById(this.dialogRef.id).close();
    }, () => this.isLoading = false);
  }

  save(object: Timeline = this.Copy) {

    this.isLoading = true;

    this._Timeline.upsert(object).subscribe(res => {
      this.isLoading = false;
      this.Copy = res;
      Object.assign(this.Original, res);
      const found = this.List.find(x => x.Id === res.Id);
      found ? Object.assign(found, res) : this.List.push(res); // Modify or push array list
      this.ListChange.emit(this.List);
      this._Dialog.getDialogById(this.dialogRef.id).close();
    }, err => {
      this.isLoading = false;
    });
  }

}
