import {Account} from '../Account/account.object';
import {DefaultModel} from '../Default/default.object';
import {Division} from '../Division/division.object';

export class AccountDivision extends DefaultModel {

  Account: Account = null;
  Division: Division = null;

  constructor(data: any = null) {
    super(data);
    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.Account = this.Account ? new Account(this.Account) : null;
    this.Division = this.Division ? new Division(this.Division) : null;
  }
}
