import {Buffer} from 'buffer';
import {Account} from '../Account/account.object';
import {AccountDivision} from '../AccountDivision/account-division.object';
import {Address} from '../Address/addresses.object';
import {DefaultModel} from '../Default/default.object';
import {DocumentType} from '../DocumentType/document-type.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';
import {Locale} from '../Locales/locale.object';

export class Division extends DefaultModel {

  CompanyName: string = null;
  Description: string = null;
  Locale: Locale = null;
  Currency: DropdownOption = null;
  Logo: Buffer = null;
  Address: Address = null;
  VATNumber: string = null;
  Owner: Account = null;
  MailName: string = null;
  MailReplyto: string = null;

  DocumentTypes: DocumentType[] = [];
  Accounts: Account[] = [];
  AccountDivisions: AccountDivision[] = [];

  constructor(data: any = null) {
    super(data);
    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.Currency = this.Currency ? new DropdownOption(this.Currency) : null;
    this.Address = this.Address ? new Address(this.Address) : null;
    this.Locale = this.Locale ? new Locale(this.Locale) : null;

    this.DocumentTypes.forEach((item, index) => {
      this.DocumentTypes[index] = new DocumentType(item);
    });

    this.Accounts.forEach((item, index) => {
      this.Accounts[index] = new Account(item);
    });

    this.AccountDivisions.forEach((item, index) => {
      this.AccountDivisions[index] = new AccountDivision(item);
    });

  }

  getLogoAsBase64(): string {
    return this.Logo ? 'data:image/png;base64,' + btoa(String.fromCharCode.apply(null, this.Logo)) : null;
  }
}
