import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {DropdownOption} from './dropdown-option.object';

@Injectable()
export class DropdownOptionService {

  defaultInclude = ['Parent', 'Translations', {'DropdownOptions': ['Translations']}];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<DropdownOption[]> {
    return this.http
        .get<DropdownOption[]>(environment.apiUrl + '/DropdownOptions', {params: ReqParams})
        .pipe(
            map(res => <DropdownOption[]>res),
            map(res => {
              return res.map(rel => new DropdownOption(rel));
            })
        );
  }

  getById(Id: string, ReqParams: HttpParams = null): Observable<DropdownOption> {
    return this.http
        .get<DropdownOption>(environment.apiUrl + '/DropdownOptions/' + Id, {params: ReqParams})
        .pipe(
            map(res => <DropdownOption>res),
            map(res => {
              return new DropdownOption(res);
            })
        );
  }

  upsert(Relation: DropdownOption): Observable<DropdownOption> {
    return this.http
        .put(environment.apiUrl + '/DropdownOptions', JSON.stringify(Relation))
        .pipe(
            map(res => <DropdownOption>res),
            map(res => {
              // Only update available props in relation. (Avoid to add irelevant props)
              for (const prop in res) if (prop in Relation) Relation[prop] = res[prop];
              return new DropdownOption(Relation);
            })
        );
  }

  upsertAll(options: DropdownOption[]): Observable<DropdownOption[]> {
    return this.http
        .put(environment.apiUrl + '/DropdownOptions/replaceOrCreateAll', JSON.stringify(options))
        .pipe(
            map(res => <DropdownOption[]>res),
            map(res => {
              return res.map(l => {
                return new DropdownOption(l);
              });
            })
        );
  }

  remove(Relation: DropdownOption): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/DropdownOptions/' + Relation.Id);
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/DropdownOptions/count', {params: ReqParams})
        .pipe(
            map(res => <{ count: number }>res)
        );
  }

}
