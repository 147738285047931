import {HttpParams} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import {ArticleService} from '../../models/Article/article.service';
import {WidgetService} from '../../models/Widget/widget.service';

@Component({
  selector: 'app-article-sort-wizard',
  templateUrl: './article-sort-wizard.component.html',
  styleUrls: ['./article-sort-wizard.component.css'],
  providers: [WidgetService, ArticleService]
})
export class ArticleSortWizardComponent implements OnInit {

  List: { obj: { Id: string, Code: string, Description: string, Order: number }, loading: boolean, imported: boolean, error: string }[] = [];
  showOnlyErrors: boolean = false; // Show only errors.

  constructor(private widgetService: WidgetService, private articleService: ArticleService) {
  }

  ngOnInit(): void {
  }

  readFile(e) {
    e.stopPropagation();
    e.preventDefault();

    this.List = [];

    const files = e.target.files;
    const reader = new FileReader();

    reader.onload = (e: any) => {

      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const test = XLSX.utils.sheet_to_json(ws, {header: 1});
      console.log(test);
      this.parseRows(test);
    };

    reader.readAsBinaryString(files[0]);

  }

  // Read rows and returns as an array of object with {key: value}
  async parseRows(rows: any[]) {
    rows.splice(0, 1); // Delete header row

    // Parse the rows
    rows.forEach((row, i) => {

      // Convert nummeric to string
      /*
      [0, 8, 10, 11, 9, 3].forEach(index => {
        if (isNumeric(row[index])) row[index] = row[index].toString();
      });

       */

      // Add to lines
      this.List.push({
        obj: {
          Id: row[0],
          Code: row[1],
          Description: row[2],
          Order: row[4],
        }, loading: false, imported: false, error: null
      });
    });
    console.log(this.List);
  }

  removeRow(row) {
    this.List.splice(this.List.indexOf(row), 1);
  }

  async import() {
    this.showOnlyErrors = false;
    for (const object of this.List.filter(x => !x.imported)) {
      object.loading = true;

      const deepCopy = JSON.parse(JSON.stringify(object.obj));

      // Prepare the payload
      delete deepCopy.Code;
      delete deepCopy.Description;
      if (!deepCopy.Order) deepCopy.Order = null; // set on NULL

      try {
        // Deep copy and patch
        await this.articleService.patch(deepCopy).toPromise();
        object.imported = true;
        object.error = null;
      } catch (e) {
        this.showOnlyErrors = true;
      }
      object.loading = false;
    }
  }

  export() {
    const httpParams = new HttpParams().append('reportIDs', JSON.stringify([55]));
    this.widgetService.getReport(httpParams).subscribe(res => {
      const filename = moment().format('YYYY_MM_DD HH_mm') + '-aorta-articles';
      FileSaver.saveAs(res, [filename, 'xlsx'].join('.'));
    });
  }

  numTogo(): number {
    return this.List.filter(x => !x.imported).length;
  }

  numErrors(): number {
    return this.List.filter(x => x.error).length;
  }

  numReady(): number {
    return this.List.filter(x => x.imported).length;
  }

  getProgression(): number {
    return Math.round(this.numReady() * 100 / this.List.length);
  }

  isLoading(randomNumber?: number) {
    return this.List.filter(x => x.loading).length > 0;
  }

}
