import {HttpParams} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import * as _ from 'lodash';
import {isNumeric} from 'rxjs/internal-compatibility';
import * as XLSX from 'xlsx';
import {Address} from '../../models/Address/addresses.object';
import {AddressService} from '../../models/Address/addresses.service';
import {ArticleService} from '../../models/Article/article.service';
import {CountryService} from '../../models/Country/country.service';
import {DropdownOptionService} from '../../models/DropdownOption/dropdown-option.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-import-addresses-wizard',
  templateUrl: './import-addresses-wizard.component.html',
  styleUrls: ['./import-addresses-wizard.component.css'],
  providers: [ArticleService, CountryService]
})
export class ImportAddressesWizardComponent implements OnInit {
  // MatDialogs
  @ViewChild('templateRef') templateRef;
  @Input() Base: Address = null;
  List: { obj: Address, loading: boolean, imported: boolean, error: string }[] = [];
  @Output() ListChange: EventEmitter<Address[]> = new EventEmitter<Address[]>();
  dialogRef: MatDialogRef<ImportAddressesWizardComponent>;
  showOnlyErrors: boolean = false; // Show only errors.

  openinghoursExample = JSON.stringify({
    'ma': {'open': true, 'from': '08:00', 'till': '19:00', 'pauze': {'from': '12:00', 'till': '13:00'}},
    'di': {'open': true, 'from': '08:00', 'till': '19:00', 'pauze': {'from': '12:00', 'till': '13:00'}},
    'wo': {'open': true, 'from': '08:00', 'till': '19:00', 'pauze': {'from': '12:00', 'till': '13:00'}},
    'do': {'open': true, 'from': '08:00', 'till': '19:00', 'pauze': {'from': '12:00', 'till': '13:00'}},
    'vr': {'open': true, 'from': '08:00', 'till': '19:00', 'pauze': {'from': '12:00', 'till': '13:00'}},
    'za': {'open': true, 'from': '08:00', 'till': '19:00', 'pauze': {'from': '12:00', 'till': '13:00'}},
    'zo': {'open': false, 'from': null, 'till': null, 'pauze': {'from': null, 'till': null}}
  });


  constructor(private _Dialog: MatDialog, private countryService: CountryService, private dropdownOptionService: DropdownOptionService, private _Address: AddressService, private _Global: GlobalService) {
  }

  ngOnInit() {
  }

  // Show editor & assign vars
  edit(input: Address = new Address()) {
    this.List = [];
    this.Base = input;
    this.dialogRef = this._Dialog.open(this.templateRef, {minWidth: '90vw'});
    // this._Dialog.getDialogById(this.dialogRef.id).close();
  }

  async import() {
    this.showOnlyErrors = false;
    for (const object of this.List.filter(x => !x.imported)) {
      object.loading = true;
      try {
        const res = await this._Address.upsert(object.obj).toPromise();
        object.obj.Id = res.Id;
        this.ListChange.emit(this.List.map(x => x.obj));
        object.imported = true;
        object.error = null;
      } catch (e) {
        // SHow CPI error if exists
        if (_.get(e, 'error.error.error.Messagelog.Error[1].Message')) {
          object.error = _.get(e, 'error.error.error.Messagelog.Error[1].Message');
        } else {
          object.error = e.message;
        }

        // Assign the ID if exists
        const id = _.get(e, 'error.error.instance.Id');
        if (id) object.obj.Id = id;

        this.showOnlyErrors = true;
      }
      object.loading = false;
    }
  }

  numTogo(): number {
    return this.List.filter(x => !x.imported).length;
  }

  numErrors(): number {
    return this.List.filter(x => x.error).length;
  }

  numReady(): number {
    return this.List.filter(x => x.imported).length;
  }

  getProgression(): number {
    return Math.round(this.numReady() * 100 / this.List.length);
  }

  isLoading(randomNumber?: number) {
    return this.List.filter(x => x.loading).length > 0;
  }

  readFile(e) {
    e.stopPropagation();
    e.preventDefault();

    this.List = [];

    const files = e.target.files;
    const reader = new FileReader();

    reader.onload = (e: any) => {

      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      // Grab the sheet with name 'Import'
      const ws: XLSX.WorkSheet = wb.Sheets['Import'];

      /* save data */
      const test = XLSX.utils.sheet_to_json(ws, {header: 1});
      this.parseRows(test);
    };

    reader.readAsBinaryString(files[0]);

  }

  removeRow(row) {
    this.List.splice(this.List.indexOf(row), 1);
  }

  // Read rows and returns as an array of object with {key: value}
  async parseRows(rows: any[]) {
    rows.splice(0, 5); // Delete header row

    // Prepare the mapping some fields
    const arrPromises = [];

    // 0 - Types
    let httpParams = new HttpParams().append('filter', JSON.stringify({
      limit: null,
      where: {and: [{Model: 'Address'}, {Discriminator: 'Type'}]}
    }));
    arrPromises.push(this.dropdownOptionService.get(httpParams).toPromise());

    // 1 - Countries
    httpParams = new HttpParams().append('filter', JSON.stringify({limit: null}));
    arrPromises.push(this.countryService.get(httpParams).toPromise());

    // 2 - CarrierServiceLevel
    httpParams = new HttpParams().append('filter', JSON.stringify({
      limit: null,
      where: {and: [{Model: 'Address'}, {Discriminator: 'CarrierServiceLevel'}]}
    }));
    arrPromises.push(this.dropdownOptionService.get(httpParams).toPromise());

    // 3 - CarrierServiceLevelOther
    httpParams = new HttpParams().append('filter', JSON.stringify({
      limit: null,
      where: {and: [{Model: 'Address'}, {Discriminator: 'CarrierServiceLevelOther'}]}
    }));
    arrPromises.push(this.dropdownOptionService.get(httpParams).toPromise());

    // 4 - SupplyPlant
    httpParams = new HttpParams().append('filter', JSON.stringify({
      limit: null,
      where: {and: [{Model: 'Address'}, {Discriminator: 'SupplyPlant'}]}
    }));
    arrPromises.push(this.dropdownOptionService.get(httpParams).toPromise());

    // 5 - Currency
    httpParams = new HttpParams().append('filter', JSON.stringify({
      limit: null,
      where: {and: [{Model: 'Document'}, {Discriminator: 'Currency'}]}
    }));
    arrPromises.push(this.dropdownOptionService.get(httpParams).toPromise());

    // Get the mapping
    const mapping = await Promise.all(arrPromises);

    // Parse the rows
    rows.forEach((row, i) => {
      // Convert nummeric to string
      [0, 8, 10, 11, 9, 3].forEach(index => {
        if (isNumeric(row[index])) row[index] = row[index].toString();
      });

      const latLng: { lat: number, lng: number } = row[14] && row[15] ? {lat: Number(row[14]), lng: Number(row[15])} : null;
      // Add to lines
      this.List.push({
        obj: new Address({
          Type: mapping[0].find(x => x.Value === row[0]),
          Division: this._Global.Division,
          Street: row[4],
          Number: row[5],
          Postcode: row[6],
          City: row[7],
          Country: mapping[1].find(x => x.Name === row[8]),
          Remarks: row[13],
          ShippingInfo: row[12],
          CarrierServiceLevel: mapping[2].find(x => x.Value === row[10]),
          CarrierServiceLevelOther: mapping[3].find(x => x.Value === row[11]), // toString otherwise 1800 (number) does not work
          SupplyPlant: mapping[4].find(x => x.Value === row[9]),
          Currency: mapping[5].find(x => x.Value === row[3]),
          Name: row[1],
          Email: row[2],
          Relation: this.Base.Relation,
          LatLng: latLng
        }), loading: false, imported: false, error: null
      });
    });
  }
}
