import {HttpParams} from '@angular/common/http';
import {Component, ElementRef, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Role} from '../../models/Role/role.object';
import {RoleService} from '../../models/Role/role.service';
import {FilterObject} from '../../objects/filter-object';

@Component({
  selector: 'app-star-rating',
  templateUrl: './selector.component.html',
  providers: [
    RoleService,
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => StarRatingComponent), multi: true}
  ]
})
export class StarRatingComponent implements OnInit {

  _value: string = null;

  // SELECTOR <=> this.ChildControl <=> CONTROLLER (THIS) <=> value (get/set) <=> END component

  Results: Role[];
  ChildControl = new UntypedFormControl();

  @Input('name') name: string = null; // verplicht ?
  @Input() placeholder = 'Gebruikers Type';
  @Input() required: any = null; // Does not work with @param required
  @ViewChild('stars') starElement: ElementRef; // Does not work with @param required
  filterObject: FilterObject = new FilterObject;

  get value() {
    return this._value;
  }

  set value(val) {
    this.onChange(val);
    this._value = val;
  }

  // CODE BELOW IS REQUIRED TO REGISTER THE NG_VALUE_ACCESSOR
  onChange: any = () => {
  };

  // Asynch search observable
  ngOnInit() {
    const Params = new HttpParams().append('filter', JSON.stringify(this.filterObject));
    this._Role.get(Params).subscribe(res => {
      this.Results = res;
    });
  }

  compareWith(o1: any, o2: any) {
    return o1 === o2 || o1 && o2 && o1.Id && o2.Id && o1.Id === o2.Id;
  }

  onTouched: any = () => {
  };

  constructor(private _Role: RoleService) {
    this.filterObject.include = this._Role.defaultInclude;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    this.value = value;
  }

  setValue($event: any) {
    // clip-path: polygon(0 0, 70% 0, 70% 100%, 0% 100%);
    this.starElement.nativeElement.style = this.starElement.nativeElement.style + ' color: red';
    // $event.nativeElement.style.clipPath = 'polygon(0 0, 70% 0, 70% 100%, 0% 100%)';
  }
}
