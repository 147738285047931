import {Account} from '../Account/account.object';
import {Activation} from '../Activation/activation.object';
import {Article} from '../Article/article.object';
import {BasketLine} from '../BasketLine/basket-line.object';
import {DefaultModel} from '../Default/default.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';

export class ActivationArticle extends DefaultModel {

  Account: Account = null;
  Activation: Activation = null;
  Article: Article = null;
  Type: DropdownOption = null;
  Amount: number = null;

  constructor(data: any = null) {

    super(data);

    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.Account = this.Account ? new Account(this.Account) : null;
    this.Activation = this.Activation ? new Activation(this.Activation) : null;
    this.Article = this.Article ? new Article(this.Article) : null;
    this.Type = this.Type ? new DropdownOption(this.Type) : null;

  }

  // Maps the ActivationArticle into a BasketLine object
  asBasketLine(): BasketLine {
    return new BasketLine({
      Original: this,
      Article: this.Article,
      Amount: this.Amount
    });
  }

}
