import {HttpParams} from '@angular/common/http';
import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Contact} from '../../models/Contact/contact.object';
import {Email} from '../../models/Email/email.object';
import {Mail} from '../../models/Mail/mail.object';
import {MailService} from '../../models/Mail/mail.service';
import {MailTranslation} from '../../models/MailTranslations/mail-translations.object';
import {GlobalService} from '../../services/global.service';
import {AttachmentControllerComponent} from '../attachment-controller/attachment-controller.component';
import {Attachment} from '../../models/Attachment/attachment.object';

@Component({
  selector: 'app-email-editor',
  templateUrl: './email-editor.component.html',
  styleUrls: ['./email-editor.component.css'],
  providers: [MailService]
})
export class EmailEditorComponent implements OnInit {

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<EmailEditorComponent>;

  Original: Email = new Email();
  isLoading = false;
  Mails: Mail[] = []; // Array of mailtemplates with their translation included

  constructor(private _Dialog: MatDialog, private _Email: MailService, private _CdRef: ChangeDetectorRef, private _Global: GlobalService) {
  }

  ngOnInit() {
    const Params = new HttpParams().append('filter', JSON.stringify({include: {MailTranslations: ['Locale']}}));
    this._Email.get(Params).subscribe(res => {
      this.Mails = res;
    });

  }

  edit(input: Email = new Email) {
    this.Original = new Email(JSON.parse(JSON.stringify(input))); // Store original
    this.Original.SenderName = (this._Global.Account.FirstName ? this._Global.Account.FirstName : '') + ' ' + (this._Global.Account.LastName ? this._Global.Account.LastName : '');
    this.Original.SenderEmail = (this._Global.Account.Email ? this._Global.Account.Email : '');
    this.dialogRef = this._Dialog.open(this.templateRef, {minWidth: '60vw'});
    this._CdRef.detectChanges();
  }

  send() {
    const docs = [];
    this.Original.Documents.forEach(d => {
      docs.push({Id: d.Id, Number: d.Number});
    });
    this.isLoading = true;
    this._Email.sendMailWithoutTemplate(
        {
          contacts: this.Original.Contacts,
          fromName: this.Original.SenderName,
          fromEmail: this.Original.SenderEmail,
          subject: this.Original.Subject,
          mailHTML: this.Original.Body,
          attachments: this.Original.Attachments,
          documents: docs,
        }).subscribe(res => {
      this.isLoading = false;
      this._Dialog.getDialogById(this.dialogRef.id).close();
    }, err => {
      this.isLoading = false;
    });
  }

  addContact(contact: Contact = new Contact) {
    this.Original.Contacts.push(contact);
  }

  removeContact(contact: Contact) {
    this.Original.Contacts.splice(this.Original.Contacts.indexOf(contact), 1);
  }

  onSelectTranslation(translation: MailTranslation) {
    if (!this.Original.Subject) this.Original.Subject = translation.Subject;
    this.Original.SenderEmail = translation.SenderMail;
    this.Original.SenderName = translation.SenderName;
    let content = translation.Content;
    this.Original.Attachments = translation.Attachments;
    this.Original.Variables.forEach(variable => content = content.replace(new RegExp('{' + variable.name + '}', 'g'), variable.value));
    this.Original.Body = content;
  }

  openAttachmentController() {
    const dialog = this._Dialog.open(AttachmentControllerComponent, {
      data: {multiple: true},
      minWidth: '60vw'
    });

    // If there an attachment has been picked
    dialog.afterClosed().subscribe((res: Attachment[]) => {
      if (res) this.Original.Attachments = res;
    });
  }

}
