import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'ppSecondsToTime'
})
export class ppSecondsToTime implements PipeTransform {

  transform(value: number, type: string = 'hours'): string {

    let ret;

    if (type === 'asHours') {
      ret = moment.duration(value * 1000).asHours();
    } else if (type == 'HH:mm') {

      const hours = Math.floor(value / 3600);
      const minutes = Math.floor((value - (hours * 3600)) / 60);
      ret = (hours < 10 ? '0' + hours : hours);
      ret += ':' + (minutes < 10 ? '0' + minutes : minutes);

    } else {
      ret = value;
    }

    return ret;
  }

}
