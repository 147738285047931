import {HttpParams} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AttachmentService} from '../../models/Attachment/attachment.service';
import {DocumentService} from '../../models/Document/document.service';
import {Promotion} from '../../models/Promotion/promotion.object';
import {PromotionService} from '../../models/Promotion/promotion.service';
import {FilterObject} from '../../objects/filter-object';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-promotion-picker',
  templateUrl: './promotion-picker.component.html',
  providers: [PromotionService, AttachmentService, DocumentService],
  styleUrls: ['./promotion-picker.component.css']
})
export class PromotionPickerComponent implements OnInit {

  // Array of all promotions to show
  isLoading = false;
  filterObject: FilterObject = new FilterObject();
  showFilters = true;
  @Input() List: Promotion[] = [];
  @Output() ListChange: EventEmitter<Promotion[]> = new EventEmitter<Promotion[]>(); // When ready we're generating and sending out an Document.

  Promotions: Promotion[] = []; // Full list with promotions
  Selected: Promotion[] = []; // List with selected promotions and their amounts

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<PromotionPickerComponent>;

  constructor(private _Dialog: MatDialog, private _Promotion: PromotionService, public _Global: GlobalService) {
  }

  ngOnInit() {
    this.filterObject.include = this._Promotion.defaultInclude;
  }

  open(input: Promotion[] = []) {

    // Assign selected
    this.Selected = input;

    this.filter();
    // Show dialog
    this.dialogRef = this._Dialog.open(this.templateRef, {width: '80vw'});
  }

  getData() {
    // Get list of promotions
    this._Promotion.get(new HttpParams().append('filter', JSON.stringify(this.filterObject))).subscribe(res => {
      this.Promotions = res;
    });
    this._Promotion.count(new HttpParams().append('where', JSON.stringify(this.filterObject.where))).subscribe(count => {
      this.filterObject.count = count.count;
    });
  }


  pageChange(e: { pageIndex: number, pageSize: number, length: number }) {
    this.filterObject.skip = e.pageIndex * e.pageSize;
    this.filterObject.limit = e.pageSize;
    this.getData();
  }

  save() {
    this.ListChange.emit(this.Selected);
    this._Dialog.getDialogById(this.dialogRef.id).close();
  }

  onToggleAll(e: MatCheckboxChange) {
    if (e.checked) {
      this.Promotions.forEach(item => {
        if (!this.Selected.find(x => x.Id === item.Id)) this.Selected.push(item);
      });
    } else {
      this.Promotions.forEach(item => {
        if (this.Selected.find(x => x.Id === item.Id)) this.Selected.splice(this.Selected.findIndex(x => x.Id === item.Id), 1);
      });
    }
  }

  onToggle(e: MatCheckboxChange, r: Promotion) {
    if (e.checked) {
      if (!this.Selected.find(x => x.Id === r.Id)) this.Selected.push(r);
    } else {
      if (this.Selected.find(x => x.Id === r.Id)) this.Selected.splice(this.Selected.findIndex(x => x.Id === r.Id), 1);
    }
  }

  isSelected(item: Promotion): boolean {
    return this.Selected.findIndex(x => x.Id === item.Id) !== -1;
  }

  filter(data: any = null) {
    // Build up and filter
    const and: object[] = [];

    // Add division filter
    if (data && data.Relation) and.push({'RelationId': data.Relation.Id});
    if (data && data.Status && data.Status.length > 0) and.push({'StatusId': {'inq': data.Status}});
    if (data && data.From) and.push({'Date': {'gt': data.From}});
    if (data && data.Untill) and.push({'Date': {'lt': data.Untill}});
    if (data && data.PaymentMethod && data.PaymentMethod.length > 0) and.push({'PaymentMethodId': {'inq': data.PaymentMethod}});
    if (data && data.Reference) and.push({or: [{'Reference': {'like': '%' + data.Reference + '%'}}, {'PaymentRef': {'like': '%' + data.Reference + '%'}}]});
    if (data && data.Account) and.push({'AccountId': data.Account.Id});
    if (data && data.Number) and.push({'Number': {'like': '%' + data.Number + '%'}}); // Default

    // If needed reset filter
    this.filterObject.where = {'and': and};
    this.filterObject.skip = 0; // Back to first page
    this.getData();
  }

}

