import {AccountDivision} from '../AccountDivision/account-division.object';
import {Activation} from '../Activation/activation.object';
import {ActivationAccount} from '../ActivationAccount/activation-account.object';
import {Address} from '../Address/addresses.object';
import {BasketLine} from '../BasketLine/basket-line.object';
import {DefaultModel} from '../Default/default.object';
import {Division} from '../Division/division.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';
import {Locale} from '../Locales/locale.object';
import {Log} from '../Log/log.object';
import {Relation} from '../Relation/relation.object';
import {Role} from '../Role/role.object';

export class Account extends DefaultModel {
  ExternalId: string = null;
  FirstName: string = null;
  LastName: string = null;
  DateOfBirth: Date = null;
  PlaceOfBirth: string = null;
  Relation: Relation = null;
  MaritalStatus: DropdownOption = null;
  DependantChildren = 0;
  Children = 0;
  WeddingDate: Date = null;
  SpouseFirstName: string = null;
  SpouseLastName: string = null;
  SpouseDateOfBirth: Date = null;
  SpouseIndependent = false;
  NationalRegisterNumber: string = null;
  EducationAndSchools: string = null;
  StudentUntil: Date = null;
  ReceivedLogin: Date = null;
  Pets: string = null;
  HasCar = false;
  Email: string = null;
  Mobile: string = null;
  Phone: string = null;
  Locale: Locale = null;
  Role: Role = null;
  Type: DropdownOption = null;
  LastLogin: Date = null;
  IsActive: boolean = true;
  Password: string = null;
  ResetToken: string = null;
  Address: Address = null;
  Divisions: Division[] = [];
  AccountDivisions: AccountDivision[] = [];
  Activations: Activation[] = [];
  ActivationAccounts: ActivationAccount[] = [];
  Logs: Log[] = [];
  BasketLines: BasketLine[] = [];
  DefaultDivision: Division = null;

  constructor(data: any = null) {
    super(data);
    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.Relation = this.Relation ? new Relation(this.Relation) : null;
    this.DefaultDivision = this.DefaultDivision ? new Division(this.DefaultDivision) : null;
    this.Locale = this.Locale ? new Locale(this.Locale) : null;
    this.Role = this.Role ? new Role(this.Role) : null;
    this.Type = this.Type ? new DropdownOption(this.Type) : null;
    this.Address = this.Address ? new Address(this.Address) : null;
    this.MaritalStatus = this.MaritalStatus ? new DropdownOption(this.MaritalStatus) : null;

    this.Divisions.forEach((item, index) => {
      this.Divisions[index] = new Division(item);
    });

    this.AccountDivisions.forEach((item, index) => {
      this.AccountDivisions[index] = new AccountDivision(item);
    });

    this.Activations.forEach((item, index) => {
      this.Activations[index] = new Activation(item);
    });

    this.Logs.forEach((item, index) => {
      this.Logs[index] = new Log(item);
    });

    this.BasketLines.forEach((item, index) => {
      this.BasketLines[index] = new BasketLine(item);
    });

    this.ActivationAccounts.forEach((item, index) => {
      this.ActivationAccounts[index] = new ActivationAccount(item);
    });
  }

  getFullName(withEmail: boolean = false): string {
    const tmpArr: string[] = [];
    if (this.FirstName) tmpArr.push(this.FirstName);
    if (this.LastName) tmpArr.push(this.LastName);
    if (this.Email && withEmail) tmpArr.push(this.Email);
    return tmpArr.join(' ');
  }
}
