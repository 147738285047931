import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Division} from '../../models/Division/division.object';
import {SupportMessage} from '../../models/SupportMessage/support-message.object';
import {SupportTicket} from '../../models/SupportTicket/support-ticket.object';
import {SupportTicketService} from '../../models/SupportTicket/support-ticket.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-support-ticket-editor',
  templateUrl: './support-ticket-editor.component.html',
  styleUrls: ['./support-ticket-editor.component.css'],
  providers: [{provide: MAT_DIALOG_DATA, useValue: ''}, SupportTicketService, MatDialog]

})
export class SupportTicketEditorComponent implements OnInit {


  @Input() List: SupportTicket[] = [];
  @Output() ListChange: EventEmitter<SupportTicket[]> = new EventEmitter<SupportTicket[]>();
  @ViewChild('templateRef') templateRef;
  Dialog: MatDialogRef<SupportTicketEditorComponent>;
  Original: SupportTicket = null;
  Copy: SupportTicket = null;
  isLoading = false;
  Divisions: Division[] = [];


  constructor(private _Dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private _SupportTicket: SupportTicketService, public _Global: GlobalService) {
  }

  edit(input: SupportTicket = new SupportTicket) {
    this.Dialog = this._Dialog.open(this.templateRef, {
      minWidth: 300,
    });
    this.Original = input;
    this.Copy = new SupportTicket(JSON.parse(JSON.stringify(input)));
    this.Copy.Division = this.Copy.Division ? this.Copy.Division : this._Global.Division;
    this.Copy.Account = this.Copy.Account ? this.Copy.Account : this._Global.Account;
    this.Copy.SupportMessages = this.Copy.SupportMessages ? this.Copy.SupportMessages : [];
    if (this.Copy.SupportMessages.length === 0) {
      this.Copy.SupportMessages.push(new SupportMessage({
      Sender: this._Global.Account,
      Division: this._Global.Division
    }));
    }
  }

  copy(input: SupportTicket = new SupportTicket()) {
    input.Id = null;
    this.edit(input);
  }

  save() {
    this.isLoading = true;
    // send to multiple
    if (this._Global.hasGUIPermission('Support')) {
      this._SupportTicket.sendToMultiple({ticket: this.Copy, divisions: this.Divisions}).subscribe(res => {
        this._Dialog.getDialogById(this.Dialog.id).close();
        if (res.length === 0) this.isLoading = false;
        res.forEach(r => {
          this.assign(r);
        });
      });
    } else {
      // create the first time with messages
      if (!this.Copy.Id) {
        this._SupportTicket.upsertWithMessages(this.Copy).subscribe(res => {
          this._Dialog.getDialogById(this.Dialog.id).close();
          this.assign(res);
        });
        // update ticket
      } else {
        this._SupportTicket.upsert(this.Copy).subscribe(res => {
          this._Dialog.getDialogById(this.Dialog.id).close();
          this.assign(res);
        });
      }
    }
  }

  assign(ticket) {
    this.Copy = ticket;
    const found = this.List.find(x => x.Id === ticket.Id);
    found ? this.List[this.List.indexOf(found)] = this.Copy : this.List.push(this.Copy);
    this.Original ? Object.assign(this.Original, this.Copy) : false;
    this.ListChange.emit(this.List);
    this.isLoading = false;
  }

  remove(r: SupportTicket, close?: boolean) {
    this._SupportTicket.remove(r).subscribe(res => {
      this.List.splice(this.List.indexOf(this.List.find(x => x.Id === r.Id && x.Id !== null)), 1);
      this.ListChange.emit(this.List);
      if (close) this._Dialog.getDialogById(this.Dialog.id).close();
    });

  }

  ngOnInit() {
  }

  compareFn(optionOne, optionTwo): boolean {
    return optionTwo && optionOne ? optionOne.Id === optionTwo.Id : false;
  }

}
