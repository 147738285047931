import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {BasketLine} from './basket-line.object';

@Injectable()
export class BasketLineService {

  defaultInclude = [];

  constructor(private http: HttpClient) {
  }

  upsert(input: BasketLine): Observable<BasketLine> {
    return this.http
        .put(environment.apiUrl + '/BasketLines', JSON.stringify(input))
      .pipe(
        map(res => <BasketLine>res),
        map(res => {
          // Only update available props in relation. (Avoid to add irelevant props)
          for (const prop in res) prop in input ? input[prop] = res[prop] : false;
          return new BasketLine(input);
        })
      );
  }

  addByArticleCode(articleCode, filter): Observable<BasketLine> {
    let ReqParams = new HttpParams()
      .append('articleCode', articleCode);
    if (filter) ReqParams = ReqParams.append('filter', JSON.stringify(filter));
    return this.http
      .post<{ articleCode: string }>(environment.apiUrl + '/BasketLines/addByArticleCode', null, {params: ReqParams})
      .pipe(
        map(line => <{ articleCode: string }>line),
        map(line => {
          return new BasketLine(line);
        })
      );
  }

  remove(input: BasketLine): Observable<{ count: number }> {
    return this.http
      .delete<{ count: number }>(environment.apiUrl + '/BasketLines/' + input.Id);
  }
}
