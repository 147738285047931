import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Location} from '../../models/Location/location.object';
import {LocationService} from '../../models/Location/location.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-location-editor',
  templateUrl: './location-editor.component.html',
  styleUrls: ['./location-editor.component.css'],
  providers: [LocationService]
})
export class LocationEditorComponent implements OnInit {

  // Original & deep copy
  Original: Location = new Location;
  Copy: Location = new Location;

  isLoading: boolean;

  @Input() List: Location[] = [];
  @Output() ListChange: EventEmitter<Location[]> = new EventEmitter<Location[]>();

  @Output() Refresh: EventEmitter<boolean> = new EventEmitter();

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<LocationEditorComponent>;

  constructor(private _Dialog: MatDialog, private _Location: LocationService, private _CdRef: ChangeDetectorRef, private _Global: GlobalService) {
  }

  ngOnInit() {
  }

  // Show editor & assign vars
  edit(entry: Location = new Location) {
    this.Original = entry; // Assign original
    if (!entry.Division) entry.Division = this._Global.Division; // Assign Division if not assigned
    this.Copy = new Location(JSON.parse(JSON.stringify(entry))); // Deep copy
    console.log(this.Copy);
    this.dialogRef = this._Dialog.open(this.templateRef);
    this._CdRef.detectChanges();
  }

  save() {
    this.isLoading = true;
    this._Location
        .upsert(this.Copy)
        .subscribe(res => {
          this.Copy = res;
          Object.assign(this.Original, res);
          const found = this.List.find(x => x.Id === res.Id);
          found ? Object.assign(found, res) : this.List.push(res); // Modify or push array list
          this.ListChange.emit(this.List);
          this.isLoading = false;
          this._Dialog.getDialogById(this.dialogRef.id).close();
        }, () => this.isLoading = false);
  }

  remove(entry: Location) {
    this._Location
        .remove(entry)
        .subscribe(res => {
          const found = this.List.find(x => x.Id === entry.Id);
          if (found) this.List.splice(this.List.indexOf(entry), res.count);
          this.ListChange.emit(this.List);
        });
  }

}
