import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {FilterObject} from '../../objects/filter-object';
import {Document} from './document.object';

@Injectable()
export class DocumentService {

  defaultInclude = [
    {
      'DocumentLines': [
        {
          'PromotionCode': ['Type', 'Article', 'Categories']
        },
        'VATCode', 'Unit',
        {'Promotion': 'Title'},
        'DiscountReason',
        {'Article': ['Packaging', 'Unit', 'Categories', 'Category1']}]
    },
    'DiscountReason', 'SupplyPlant', 'Type', 'Classification', 'Locale', {'Contact': 'Locale'}, {'Address': [{'Country': 'ShipmentArticle'}, 'Type']}, 'Currency', 'ShippingMethod', 'Division', 'DocumentPrints', 'Status', 'Account',
    {
      'Relation': [{'Contacts': 'Locale'},
        {
          relation: 'Addresses',
          scope: {
            include: ['Relation', 'CarrierServiceLevel', 'SupplyPlant', 'CarrierServiceLevelOther', 'Type', {'Country': 'ShipmentArticle'}],
            where: {TypeId: {neq: 'd5b0344f-e908-4a59-953f-a9ae1bbbcaad'}}
          }
        }, {'DefaultAddress': [{'Country': 'ShipmentArticle'}, 'Type']}, {'DefaultContact': 'Locale'}, 'Locale', 'DefaultVATCode']
    }, 'Modifier', 'Creator'];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<Document[]> {
    return this.http
      .get<Document[]>(environment.apiUrl + '/Documents', {params: ReqParams})
      .pipe(
        map(res => <Document[]>res),
        map(res => {
          return res.map(rel => new Document(rel));
        })
      );
  }

  upsert(Doc: Document): Observable<Document> {
    return this.http
      .put<Document>(environment.apiUrl + '/Documents', JSON.stringify(Doc))
      .pipe(
        map(res => <Document>res),
        map(res => {
          // Modify original doc without destroying related objects (doclines)
          for (const prop in res) if (prop in Doc) Doc[prop] = res[prop];
          return new Document(Doc);
        })
      );
  }

  patchAttribute(c: object): Observable<Document> {
    return this.http
      .patch(environment.apiUrl + '/Documents/' + c['Id'], JSON.stringify(c)).pipe(
        map(res => <Document>res),
        map(res => {
          for (const prop in res) prop in c ? c[prop] = res[prop] : false;
          return new Document(c);
        })
      );
  }

  remove(document: Document): Observable<{ count: number }> {
    return this.http
      .delete<{ count: number }>(environment.apiUrl + '/Documents/' + document.Id);
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
      .get(environment.apiUrl + '/Documents/count', {params: ReqParams})
      .pipe(
        map(res => <{ count: number }>res)
      );
  }

  download(document: Document): Observable<Blob> {
    return this.http
      .get(environment.apiUrl + '/Documents/' + document.Id + '/download', {responseType: 'blob'})
      .pipe(
        map(res => {
          return new Blob([res], {type: 'application/pdf'});
        })
      );
  }

  exportXlsx(ReqParams: HttpParams = null): Observable<Blob> {
    return this.http
      .get(environment.apiUrl + '/Documents/export', {params: ReqParams, responseType: 'blob'})
      .pipe(
        map(res => {
          return new Blob([res], {type: 'application/vnd.ms-excel'});
        })
      );
  }

  exportToSAP(document: Document): Observable<Document> {
    return this.http
      .post(environment.apiUrl + '/Documents/' + document.Id + '/exportToSAP', null)
      .pipe(
        map(res => {
          for (const prop in res) prop in document ? document[prop] = res[prop] : false;
          return document;
        })
      );
  }

  getInvoicesFromExactByRelation(ReqParams: HttpParams = null): Observable<any[]> {
    return this.http
      .get<any[]>(environment.apiUrl + '/Documents/getInvoicesFromExactByRelation', {params: ReqParams});
  }

  sendTrackingCodesToShopify(): Observable<{ count: number }> {
    return this.http
      .post<{ count: number }>(environment.apiUrl + '/Documents/sendTrackingCodesToShopify', null, {}).pipe(
        map(res => <{ count: number }>res)
      );
  }

  moveAndDeleteAddress(searchForAddressId: string, replaceByAddressId: string) {
    const ReqParams = new HttpParams().append('searchForAddressId', searchForAddressId).append('replaceByAddressId', replaceByAddressId);
    return this.http.put(environment.apiUrl + '/Documents/moveAndDeleteAddress', null, {params: ReqParams});
  }

  getDocumentsWithLargeFilter(filter: FilterObject): Observable<Document[]> {
    return this.http
      .post<Document[]>(environment.apiUrl + '/Documents/getDocumentsWithLargeFilter', filter, {})
      .pipe(
        map(res => <Document[]>res),
        map(res => {
          return res.map(rel => new Document(rel));
        })
      );
  }
}
