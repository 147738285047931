import {DefaultModel} from '../Default/default.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';

export class Timeline extends DefaultModel {

  Model: string = null;
  ParentId: string = null;
  Type: DropdownOption = null;
  Color: string = null;
  Icon: string = null;
  Description: string = null;
  Start: Date = null;
  End: Date = null;

  constructor(data: any = null) {

    super(data);

    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.Type = this.Type ? new DropdownOption(this.Type) : null;

  }
}
