import {Account} from '../Account/account.object';
import {Activation} from '../Activation/activation.object';
import {DefaultModel} from '../Default/default.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';

export class ActivationEntry extends DefaultModel {

  Activation: Activation = null;
  Account: Account = null;
  Type: DropdownOption = null;
  Amount = 0;

  randomNumber: number = null;

  constructor(data: any = null) {

    super(data);

    this.randomNumber = Math.random();

    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.Activation = this.Activation ? new Activation(this.Activation) : null;
    this.Account = this.Account ? new Account(this.Account) : null;
    this.Type = this.Type ? new DropdownOption(this.Type) : null;
  }

}
