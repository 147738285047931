import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {BasketLine} from '../BasketLine/basket-line.object';
import {PromotionCode} from './promotion-code.object';

@Injectable()
export class PromotionCodeService {

  defaultInclude: any[] = [
    'Type',
    'Article',
    'Categories',
    'Groups',
    'Modifier',
    'Creator'
  ];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<PromotionCode[]> {
    return this.http
      .get<PromotionCode[]>(environment.apiUrl + '/PromotionCodes/search', {params: ReqParams}).pipe(
        map(res => <PromotionCode[]>res),
        map(res => {
          return res.map(rel => new PromotionCode(rel));
        }),);
  }

  getById(Id: string, ReqParams: HttpParams = null): Observable<PromotionCode> {
    return this.http
        .get<PromotionCode>(environment.apiUrl + '/PromotionCodes/' + Id, {params: ReqParams}).pipe(
            map(res => <PromotionCode>res),
            map(res => {
              return new PromotionCode(res);
            }));
  }

  upsert(Entry: PromotionCode): Observable<PromotionCode> {
    return this.http
        .put<PromotionCode>(environment.apiUrl + '/PromotionCodes', JSON.stringify(Entry)).pipe(
            map(res => <PromotionCode>res),
            map(res => {
              // Modify original doc without destroying related objects (doclines)
              for (const prop in res) if (prop in Entry) Entry[prop] = res[prop];
              return new PromotionCode(Entry);
            }));

  }

  remove(Entry: PromotionCode): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/PromotionCodes/' + Entry.Id);
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/PromotionCodes/count', {params: ReqParams})
        .pipe(
            map(res => <{ count: number }>res)
        );
  }

  exportXlsx(ReqParams: HttpParams = null): Observable<Blob> {
    return this.http
      .get(environment.apiUrl + '/Groups/export', {params: ReqParams, responseType: 'blob'})
      .pipe(
        map(res => {
          return new Blob([res], {type: 'application/vnd.ms-excel'});
        })
      );
  }

  addCode(code: string, lines: BasketLine[]): Observable<PromotionCode> {
    return this.http
      .post<PromotionCode>(environment.apiUrl + '/PromotionCodes/addCode', JSON.stringify({code, lines})).pipe(
        map(res => <PromotionCode>res),
        map(res => {
          return new PromotionCode(res);
        }));
  }

  checkRequirements(code: string, lines: BasketLine[]): Observable<PromotionCode> {
    const httpParams = new HttpParams().append('code', code).append('lines', JSON.stringify(lines));
    return this.http
      .post<PromotionCode>(environment.apiUrl + '/PromotionCodes/checkRequirements', {params: httpParams});
  }

  applyPromotion(code: string, lines: BasketLine[]): Observable<BasketLine[]> {
    return this.http
      .post<BasketLine[]>(environment.apiUrl + '/PromotionCodes/applyPromotion', JSON.stringify({code, lines}))
      .pipe(
        map(res => <BasketLine[]>res),
        map(res => {
          return res.map(rel => new BasketLine(rel));
        })
      );
  }
}
