import {DefaultModel} from '../Default/default.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';
import {Locale} from '../Locales/locale.object';
import {Relation} from '../Relation/relation.object';

export class Contact extends DefaultModel {

  ExternalId: string = null;
  ExternalRelationId: string = null;
  Relation: Relation = null;
  Type: DropdownOption = null;
  Fullname: string = null;
  JobTitle: string = null;
  Gender: string = null;
  Phone: string = null;
  Mobile: string = null;
  Email: string = null;
  Locale: Locale = null;
  Remarks: string = null;
  IsActive: boolean = null;
  IsDefault = false;
  FreeField1: string = null;
  FreeField2: string = null;

  // For NgModelgroups
  randomNumber: number;

  constructor(data: any = null) {

    super(data);

    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    // ALWAYS generate random number (for ngModel group primary key (identifier))
    this.randomNumber = Math.random();

    this.Relation = this.Relation ? new Relation(this.Relation) : null;
    this.Locale = this.Locale ? new Locale(this.Locale) : null;
    this.Type = this.Type ? new DropdownOption(this.Type) : null;
  }

  getFullName(): string {
    return this.Fullname;
  }


}
