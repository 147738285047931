import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Account} from '../models/Account/account.object';
import {Division} from '../models/Division/division.object';
import {AlertService} from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  Division: Division = null; // The current selected division.
  Account: Account = null; // When logged in => this is set to the current users account

  constructor(private _Alert: AlertService) {
  }

  hasGUIPermission(p: string): boolean {
    return this.Account.Role && this.Account.Role.GUI_ACLs &&
        this.Account.Role.GUI_ACLs.findIndex(x => x.PrincipalType === p && x.AccessType === '*') !== -1;
  }

  hasAPIPermission(p: string, a: string): boolean {
    return this.Account.Role && this.Account.Role.GUI_ACLs &&
        this.Account.Role.API_ACLs.findIndex(x => x.PrincipalType === p && (x.AccessType === a || x.AccessType === '*')) !== -1;
  }

  dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a, b) => {
      let result;
      if (property.indexOf('.') > -1) {
        result = (a[property.split('.')[0]][property.split('.')[1]] < b[property.split('.')[0]][property.split('.')[1]]) ? -1 : (a[property.split('.')[0]][property.split('.')[1]] > b[property.split('.')[0]][property.split('.')[1]]) ? 1 : 0;

      } else {
        result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;

      }
      return result * sortOrder;
    };
  }

  getImage(url: string): string {
    return environment.apiUrl + '/Attachments/download/' + encodeURIComponent(url);
  }

  openImage(path: string) {
    window.open(this.getImage(path), '_blank');
  }

  openURL(path: string, option) {
    window.open(path, option);
  }

  copyURL(path: string) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (path));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    this._Alert.sendMessage('Copied: ' + path, 'success');
  }
}
