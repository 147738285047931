import {Account} from '../Account/account.object';
import {Activation} from '../Activation/activation.object';
import {DefaultModel} from '../Default/default.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';

export class ActivationAccount extends DefaultModel {

  Account: Account = null;
  Activation: Activation = null;
  Status: DropdownOption = new DropdownOption({Id: 'e8bea57b-b64c-46f0-a2f2-2917ba8ec7f0', Value: 'Pending'});
  LeavedHome: Date = null;
  Arrived: Date = null;
  Leaved: Date = null;
  ArrivedHome: Date = null;
  TotalTraveltime: number = null;
  TotalWorktime: number = null;
  Break: number = null;
  Spoken: number = null;
  Convinced: number = null;
  Reminded: number = null;
  Bought: number = null;
  TraveledDistance: number = null;
  Expenses: number = null;
  FeedbackStore: string = null;
  FeedbackCustomers: string = null;
  FeedbackYourself: string = null;
  ScoreYourself: number = null;
  ScoreStore: number = null;
  VoucherDog: number = null;
  VoucherCat: number = null;
  ReceivedInfoMail: Date = null;

  constructor(data: any = null) {

    super(data);

    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.Account = this.Account ? new Account(this.Account) : null;
    this.Activation = this.Activation ? new Activation(this.Activation) : null;
    this.Status = this.Status ? new DropdownOption(this.Status) : null;

  }

}
