import {HttpParams} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/internal/operators';
import {Address} from '../../models/Address/addresses.object';
import {AddressService} from '../../models/Address/addresses.service';
import {FilterObject} from '../../objects/filter-object';
import {OpeningHours} from '../../objects/opening-hours';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-crm-address-editor',
  templateUrl: './crm-address-editor.component.html',
  styleUrls: ['./crm-address-editor.component.css'],
  providers: [AddressService]
})
export class CrmAddressEditorComponent implements OnInit {

  @Input() hideRelation = false; // When editing division adress, there is no relation required.
  @Input() List: Address[] = [];
  @Input() localOnly = false; // If on true, we won't upsert the database, only the local ngModel (used for CRM Relation editor)
  @Output() ListChange: EventEmitter<Address[]> = new EventEmitter<Address[]>();
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<CrmAddressEditorComponent>;
  Original: Address = null;
  Copy: Address = new Address;
  isLoading = false;
  checkedForDuplicates = true;
  duplicates: { validated: boolean, address: Address }[] = []; // list with smilar addresses

  delayer: Subject<any> = new Subject<any>();

  constructor(private _Dialog: MatDialog, private _Address: AddressService, private _Global: GlobalService) {
    this.delayer.pipe(
      debounceTime(200),
    ).subscribe(() => this.check());
  }

  edit(input: Address = new Address()) {
    this.duplicates = [];
    if (!input.Division) input.Division = JSON.parse(JSON.stringify(this._Global.Division)); // Assign Division if not assigned
    this.dialogRef = this._Dialog.open(this.templateRef);
    this.Original = input;
    this.Copy = new Address(JSON.parse(JSON.stringify(input)));
    this.Copy.Division = this.Copy.Division ? this.Copy.Division : this._Global.Division;
  }

  check() {
    this.checkedForDuplicates = false;

    // Required fields
    if (!this.Copy.Street) return;
    if (!this.Copy.Number) return;
    if (!this.Copy.Postcode) return;
    if (!this.Copy.City) return;

    // Build the filter
    const filterObject = new FilterObject();
    filterObject.include = ['Relation', 'Type', 'Country'];
    if (this.Copy.Type) filterObject.where = {TypeId: this.Copy.Type.Id};

    // Check for duplicates
    const httpParams = new HttpParams().append('filter', JSON.stringify(filterObject));
    this._Address.check(this.Copy, httpParams).subscribe(res => {
      this.checkedForDuplicates = true;
      this.duplicates = res.map(row => {
        return {validated: false, address: row};
      });
    });
  }

  // Are all the checkboxes selected?
  isValidated(): boolean {
    return this.duplicates.filter(x => !x.validated).length === 0;
  }

  duplicate(address: Address) {
    address = new Address((JSON.parse(JSON.stringify(address))));
    address.ExternalId = null;
    address.IsDefault = null;
    address.Id = null;
    this.edit(address);
  }

  save(object = this.Copy) {
    if (this.localOnly) {
      this.updateOriginal(object);
    } else {
      this.isLoading = true;
      this._Address.upsert(object).subscribe(res => {
        this.isLoading = false;
        this.updateOriginal(res);
      }, err => {
        this.isLoading = false;
      });
    }
  }

  updateOriginal(object = this.Copy) {
    this.Copy = object;
    Object.assign(this.Original, this.Copy);
    const found = this.List.find(x => (x.Id === this.Copy.Id && x.Id !== null) || (x.Id === null && x.randomNumber === this.Copy.randomNumber));
    found ? this.List[this.List.indexOf(found)] = this.Copy : this.List.push(this.Copy);
    this._Dialog.getDialogById(this.dialogRef.id).close();
    this.ListChange.emit(this.List);
  }

  remove(a: Address) {
    this._Address.remove(a).subscribe(res => {
      this.List.splice(this.List.indexOf(a), 1);
      this.ListChange.emit(this.List);
    });
  }

  ngOnInit() {
  }

  addOpeningHours() {
    this.Copy.OpeningHours = new OpeningHours();
  }

  copyHours(key: string) {
    this.Copy.OpeningHours[key].open = this.Copy.OpeningHours[this.Copy.OpeningHours.getKeys()[this.Copy.OpeningHours.getKeys().indexOf(key) - 1]].open;
    this.Copy.OpeningHours[key].from = this.Copy.OpeningHours[this.Copy.OpeningHours.getKeys()[this.Copy.OpeningHours.getKeys().indexOf(key) - 1]].from;
    this.Copy.OpeningHours[key].till = this.Copy.OpeningHours[this.Copy.OpeningHours.getKeys()[this.Copy.OpeningHours.getKeys().indexOf(key) - 1]].till;
    this.Copy.OpeningHours[key].pauze = this.Copy.OpeningHours[this.Copy.OpeningHours.getKeys()[this.Copy.OpeningHours.getKeys().indexOf(key) - 1]].pauze;
  }

  // Employees should not allowed to create shiptos
  isAllowedToCreateAddress(): boolean {
    // Some customerGroups are not allowed to create shiptos after 1 march
    const datePassed = moment().isAfter('2023-03-06 00:00:00');
    if (datePassed && ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '15'].includes(this.Copy.Relation?.CustomerGroup?.ExternalId) && this.Copy.Type?.Id === '4870f240-1c60-46a2-af41-1b135e46b1ee') return false;
    return !(this.Copy && this.Copy.Relation && this.Copy.Relation.Code.substr(0, 3) === '998');
  }
}
