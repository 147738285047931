import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import {WorkSheet} from 'xlsx';
import {ArticleService} from '../../models/Article/article.service';
import {Contact} from '../../models/Contact/contact.object';
import {Document} from '../../models/Document/document.object';
import {DocumentService} from '../../models/Document/document.service';
import {Email} from '../../models/Email/email.object';
import {FilterObject} from '../../objects/filter-object';
import {AlertService} from '../../services/alert.service';
import {GlobalService} from '../../services/global.service';
import {EmailEditorComponent} from '../email-editor/email-editor.component';

@Component({
  selector: 'app-import-track-and-trace-wizard',
  templateUrl: './import-track-and-trace-wizard.component.html',
  styleUrls: ['./import-track-and-trace-wizard.component.css'],
  providers: [ArticleService]
})
export class ImportTrackAndTraceWizardComponent implements OnInit {
  // MatDialogs
  @ViewChild('templateRef') templateRef;
  @ViewChild('emailEditor') emailEditor: EmailEditorComponent;
  LoadingDocumentsList: boolean[] = [];
  Documents: Document[] = [];
  LoadingList: boolean[] = [];
  CompletedList: boolean[] = [];
  Sheets: { Title: string, Worksheet: WorkSheet }[] = [];
  Sheet: WorkSheet = null;
  TrackingCodePrefix: string = 'https://tracking.dpd.de/status/en_BE/parcel/';
  Columns: Array<any> = [];
  Rows: Array<any>[] = [];
  DocumentNumberColumn: number = null;
  TrackAndTraceColumn: number = null;
  dialogRef: MatDialogRef<ImportTrackAndTraceWizardComponent>;
  filterObject: FilterObject = new FilterObject();
  errorMail: Email = new Email({
    Subject: 'Error during Track & Trace import!',
    Contacts: [
      new Contact({
          Email: this._Global.Account.Email,
          FirstName: this._Global.Account.FirstName,
          LastName: this._Global.Account.LastName
        }
      )]
  });
  @Output() ListChange: EventEmitter<any> = new EventEmitter<any>();


  constructor(private _Dialog: MatDialog, private _Global: GlobalService, private _Document: DocumentService, private _Alert: AlertService) {
  }

  ngOnInit() {
  }

  // Show editor & assign vars
  edit() {
    this.LoadingList = [];
    this.CompletedList = [];
    this.Sheets = [];
    this.Sheet = null;
    this.TrackingCodePrefix = 'https://tracking.dpd.de/status/en_BE/parcel/';
    this.Rows = [];
    this.DocumentNumberColumn = null;
    this.TrackAndTraceColumn = null;
    this.dialogRef = this._Dialog.open(this.templateRef, {minWidth: '90vw', maxHeight: '90vh'});
  }

  getDocuments() {
    this.filterObject.limit = 0;
    this.filterObject.loading = true;
    this.filterObject.where = {};
    const codes = [];
    this.Rows.forEach((r, i) => {
      codes.push(r[this.DocumentNumberColumn]);
    });
    this.filterObject.where = {Number: {inq: codes}};
    this._Document.getDocumentsWithLargeFilter(this.filterObject).subscribe(res => {
      this.filterObject.loading = false;
      this.Documents = res;
    }, err => {
      this.filterObject.loading = false;
    });
  }

  documentFound(number: string) {
    return !!this.Documents.find(x => number === x.Number);
  }

  async import() {
    this.errorMail.Body = null;
    this.Rows.forEach((row, i) => {
      const found = this.Documents.find(x => x.Number === row[this.DocumentNumberColumn]);
      if (found) {
        this.LoadingList[i] = true;
        if (!this.Documents[this.Documents.indexOf(found)].TrackAndTrace) this.Documents[this.Documents.indexOf(found)].TrackAndTrace = [];
        this.Documents[this.Documents.indexOf(found)].TrackAndTrace.push(this.TrackingCodePrefix + row[this.TrackAndTraceColumn]);
        this.Documents[this.Documents.indexOf(found)].TrackAndTrace = this.Documents[this.Documents.indexOf(found)].TrackAndTrace.filter((x, j) => {
          return this.Documents[this.Documents.indexOf(found)].TrackAndTrace.indexOf(x) === j;
        });
      } else {
        this.addErrorLine('Row ' + (i + 1) + ' failed | (Document: ' + row[this.DocumentNumberColumn] + ' not found!, Track and Trace Codes: ' + row[this.TrackAndTraceColumn], i, true);
      }
    });
    this.Documents.forEach((doc, i) => {
      this._Document.patchAttribute({Id: doc.Id, TrackAndTrace: doc.TrackAndTrace}).subscribe(res => {
        this.Rows.filter(x => x[this.DocumentNumberColumn] === doc.Number).forEach(r => {
          this.CompletedList[this.Rows.indexOf(r)] = true;
          this.LoadingList[this.Rows.indexOf(r)] = false;
        });
        this.importComplete();
      }, err => {
        this.Rows.filter(x => x[this.DocumentNumberColumn] === doc.Number).forEach(r => {
          this.CompletedList[this.Rows.indexOf(r)] = false;
          this.LoadingList[this.Rows.indexOf(r)] = false;
        });
        this.addErrorLine('Document ' + doc.Number + ' failed | (Document: ' + doc.Number + ' Failed!, Track and Trace Codes: ' + doc.TrackAndTrace.join(', '), i, true);
        this.addErrorLine('Full Error: ' + JSON.stringify(err), i, false);
        this.importComplete();
      });
    });
  }

  addErrorLine(input: string, index: number, alert: boolean) {
    if (!this.errorMail.Body) this.errorMail.Body = '';
    this.errorMail.Body += input;
    this.errorMail.Body += '<br>';
    if (alert) this._Alert.sendMessage('Row ' + (index + 1) + ' failed', 'danger');
  }

  importComplete() {
    if (this.LoadingList.indexOf(true) === -1) {
      this.ListChange.emit();
      this._Alert.sendMessage('Track and Trace codes updated', 'success');
      if (this.errorMail.Body && this.errorMail.Body !== '') this.emailEditor.edit(this.errorMail);
    }
  }

  readFile(e) {
    e.stopPropagation();
    e.preventDefault();
    const files = e.target.files;
    const reader = new FileReader();

    reader.onload = (e: any) => {

      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});
      // Grab the sheet with name 'Import'
      if (Object.keys(wb.Sheets).length === 0) this._Alert.sendMessage('No Sheets found', 'danger');
      Object.keys(wb.Sheets).forEach(key => {
        this.Sheets.push({Title: key, Worksheet: wb.Sheets[key]});
      });
    };

    reader.readAsBinaryString(files[0]);

  }

  // Read rows and returns as an array of object with {key: value}
  parseRows(e: WorkSheet) {
    this.Rows = XLSX.utils.sheet_to_json(this.Sheet, {header: 1, raw: false});
    this.Columns = this.Rows.splice(0, 1)[0];
    this.CompletedList.fill(false, 0, this.Rows.length);
    console.log(this.Rows);
  }

  log() {
  }
}
