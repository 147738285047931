import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ArticleService} from '../../models/Article/article.service';
import {BasketLine} from '../../models/BasketLine/basket-line.object';
import {DropdownOptionService} from '../../models/DropdownOption/dropdown-option.service';
import {Promotion} from '../../models/Promotion/promotion.object';
import {PromotionService} from '../../models/Promotion/promotion.service';
import {PromotionBlock} from '../../models/PromotionBlock/promotion-block.object';
import {PromotionOption} from '../../models/PromotionOption/promotion-option.object';
import {Relation} from '../../models/Relation/relation.object';

@Component({
  selector: 'app-promotion-selector-editor',
  templateUrl: './promotion-selector-editor.component.html',
  styleUrls: ['./promotion-selector-editor.component.css'],
  providers: [PromotionService, ArticleService]
})
export class PromotionSelectorEditorComponent implements OnInit, OnChanges {

  // Original & deep copy
  Original: Promotion = new Promotion;
  Copy: Promotion = new Promotion;
  isLoading = false;
  isLoadingArticles = true;
  selectedOptions: { Option: PromotionOption, Count: number }[] = [];

  @Input() Relation: Relation = null;
  @Input() List: Promotion[] = [];
  @Output() ListChange: EventEmitter<BasketLine[]> = new EventEmitter<BasketLine[]>();
  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<PromotionSelectorEditorComponent>;

  constructor(private _Dialog: MatDialog,
              private _Promotion: PromotionService,
              private _Article: ArticleService,
              private _DropdownOption: DropdownOptionService,
              private _Cdref: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this._Cdref.detectChanges();
  }

  // Show editor & assign vars
  edit(entry: Promotion = new Promotion) {
    this.selectedOptions = [];
    this.Original = entry; // Assign original
    this.Copy = new Promotion(JSON.parse(JSON.stringify(entry))); // Deep copy
    this.isLoadingArticles = false;
    this.Copy.Blocks.forEach(b => {
      b.Options.forEach(o => {
        this.selectedOptions.push({Option: o, Count: o.DefaultSelected});
      });
    });
    this.dialogRef = this._Dialog.open(this.templateRef, {minWidth: '1500px'});
  }

  save() {
    const lines = [];
    this.selectedOptions.forEach(opt => {
      opt.Option.BasketLines.forEach(line => {
        line = new BasketLine(JSON.parse(JSON.stringify(line)));
        if (opt.Count > 0) {
          line.PromotionOption = null;
          line.Id = null;
          line.Ordered = line.Ordered * opt.Count;
          line.Promotion = new Promotion(JSON.parse(JSON.stringify(this.Copy)));
          lines.push(line);
        }
      });
    });
    this.ListChange.emit(lines);
    this._Dialog.getDialogById(this.dialogRef.id).close();

  }

  checkValid(): boolean {
    // Check if all lines are valid + if total values are valid
    let returnvalue = false;
    let total = 0;
    this.Copy.Blocks.forEach(block => {
      block.Options.forEach(option => {
        total += this.getCount(option);
      });
      if (this.getBlockInValid(block)) returnvalue = true;
    });
    if (this.Copy.Operator === 'or' && total === 0) {
      returnvalue = true;
    }
    return returnvalue;
  }

  getBlockInValid(block: PromotionBlock): boolean {
    let count = 0;
    block.Options.forEach(option => {
      count += this.getCount(option);
    });
    if (this.Copy.Operator === 'and' && count === 0 && block.MinRequired !== 0) {
      return true;
    }
    if (this.Copy.Operator === 'and') {
      if (block.MinRequired && block.MinRequired > count) {
        return true;
      }
      if (block.MaxRequired && block.MaxRequired < count) {
        return true;
      }
      if (((block.StepRequired && !block.MaxRequired && this.getBlockSelected(block) % (this.getHighestBlockStep() * block.StepRequired) !== 0))) {
        return true;
      }
    } else if (this.Copy.Operator === 'or') {
      if (block.MinRequired && block.MinRequired > count && count > 0) {
        return true;
      }
      if (block.MaxRequired && block.MaxRequired < count && count > 0) {
        return true;
      }
    }
  }

  getBlockStep(block: PromotionBlock): number {
    const amount = this.getBlockSelected(block) / (block.StepRequired ? block.StepRequired : 1);
    return (amount > 0 ? Math.round(amount) : 1);
  }

  getHighestBlockStep(): number {
    let step = 0;
    this.Copy.Blocks.forEach(block => {
      const s = this.getBlockStep(block);
      if (s > step && block.StepRequired) step = s;
    });
    return step;
  }

  removeOption(option: PromotionOption) {
    const found = this.selectedOptions.find(x => x.Option && x.Option.Id === option.Id);
    if (found && found.Count !== 0) {
      found.Count--;
    } else {
      this.selectedOptions.splice(this.selectedOptions.indexOf(found), 1);
    }
  }

  addOption(option: PromotionOption) {
    const found = this.selectedOptions.find(x => x.Option && x.Option.Id === option.Id);
    if (found) {
      found.Count++;
    } else {
      this.selectedOptions.push({Option: option, Count: 1});
    }
  }

  getCount(option: PromotionOption): number {
    const found = this.selectedOptions.find(x => x.Option && x.Option.Id === option.Id);
    return found ? found.Count : 0;
  }

  getBlockSelected(block: PromotionBlock): number {
    let count = 0;
    block.Options.forEach(o => {
      const found = this.selectedOptions.find(x => x.Option && x.Option.Id === o.Id);
      count += found ? found.Count : 0;
    });
    return count;
  }

  getOptionSelected(option: PromotionOption): number {
    const found = this.selectedOptions.find(x => x.Option && x.Option.Id === option.Id);
    return found ? found.Count : 0;
  }

  getArticleImage(option: PromotionOption): string {
    let returnstring = null;
    option.BasketLines.forEach(b => {
      if (b.Article && b.Article.Image && !returnstring) {
        returnstring = 'https://api-aorta.edgardcooper.com/v1/Attachments/download/' + encodeURIComponent(b.Article.Image);
      }
    });
    return returnstring;
  }
}
