import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {RouterModule} from '@angular/router';
import {DragulaModule} from 'ng2-dragula';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {NgxUploaderModule} from 'ngx-uploader';
import {NgxWigModule} from 'ngx-wig';
import {AlertComponent} from '../../components/app-alert/alert.component';
import {AttachmentBrowserComponent} from '../../components/app-attachment-browser/attachment-browser.component';
import {ButtonComponent} from '../../components/app-button/button.component';
import {FullCalendarComponent} from '../../components/app-fullcalendar/full-calendar.component';
import {GroupWrapperComponent} from '../../components/app-group-wrapper/group-wrapper.component';
import {TimelineComponent} from '../../components/app-timeline/timeline.component';
import {ChartjsWrapperComponent} from '../../components/chartjs-wrapper/chartjs-wrapper.component';
import {AccountDivisionEditorComponent} from '../../dialogs/account-division-editor/account-division-editor.component';
import {AccountEditorComponent} from '../../dialogs/account-editor/account-editor.component';
import {AclEditorComponent} from '../../dialogs/acl-editor/acl-editor.component';
import {ActivationAccountEditorComponent} from '../../dialogs/activation-account-editor/activation-account-editor.component';
import {ActivationArticleEditorComponent} from '../../dialogs/activation-article-editor/activation-article-editor.component';
import {ActivationEditorComponent} from '../../dialogs/activation-editor/activation-editor.component';
import {ActivationEntryEditorComponent} from '../../dialogs/activation-entry-editor/activation-entry-editor.component';
import {ArticleEditorComponent} from '../../dialogs/article-editor/article-editor.component';
import {ArticlePickerComponent} from '../../dialogs/article-picker/article-picker.component';
import {AttachmentControllerComponent} from '../../dialogs/attachment-controller/attachment-controller.component';
import {BasketLineEditorComponent} from '../../dialogs/basket-line-editor/basket-line-editor.component';
import {CountryEditorComponent} from '../../dialogs/country-editor/editor.component';
import {CrmAddressEditorComponent} from '../../dialogs/crm-address-editor/crm-address-editor.component';
import {CrmContactEditorComponent} from '../../dialogs/crm-contact-editor/crm-contact-editor.component';
import {CrmRelationEditorComponent} from '../../dialogs/crm-relation-editor/crm-relation-editor.component';
import {DivisionEditorComponent} from '../../dialogs/division-editor/division-editor.component';
import {DocumentEditorComponent} from '../../dialogs/document-editor/document-editor.component';
import {EmailEditorComponent} from '../../dialogs/email-editor/email-editor.component';
import {FileFolderEditorComponent} from '../../dialogs/file-folder-editor/file-folder-editor.component';
import {FileUploaderComponent} from '../../dialogs/file-uploader/file-uploader.component';
import {GroupEditorComponent} from '../../dialogs/group-editor/group-editor.component';
import {GroupFinderComponent} from '../../dialogs/group-finder/group-finder.component';
import {ImportAddressesWizardComponent} from '../../dialogs/import-addresses-wizard/import-addresses-wizard.component';
import {ImportDocumentLinesWizardComponent} from '../../dialogs/import-documentlines-wizard/import-document-lines-wizard.component';
import {ImportTrackAndTraceWizardComponent} from '../../dialogs/import-track-and-trace-wizard/import-track-and-trace-wizard.component';
import {LocationEditorComponent} from '../../dialogs/location-editor/location-editor.component';
import {PriceListEditorComponent} from '../../dialogs/price-list-editor/price-list-editor.component';
import {PromotionCodePickerComponent} from '../../dialogs/promotion-code-picker/promotion-code-picker.component';
import {PromotionEditorComponent} from '../../dialogs/promotion-editor/promotion-editor.component';
import {PromotionPickerComponent} from '../../dialogs/promotion-picker/promotion-picker.component';
import {PromotionSelectorEditorComponent} from '../../dialogs/promotion-selector-editor/promotion-selector-editor.component';
import {RelationPickerComponent} from '../../dialogs/relation-picker/relation-picker.component';
import {RoleEditorComponent} from '../../dialogs/role-editor/role-editor.component';
import {ScheduleEditorComponent} from '../../dialogs/schedule-editor/schedule-editor.component';
import {SupportTicketEditorComponent} from '../../dialogs/support-ticket-editor/support-ticket-editor.component';
import {TimelineEditorComponent} from '../../dialogs/timeline-editor/timeline-editor.component';
import {VisualArticlePickerComponent} from '../../dialogs/visual-article-picker/visual-article-picker.component';
import {ConfirmDialogComponent} from '../../directives/confirm-dialog/confirm-dialog.component';
import {ConfirmDialogDirective} from '../../directives/confirm-dialog/confirm-dialog.directive';
import {ErrorValidatorDirective} from '../../directives/error-validator/error-validator';
import {ToggleFullScreenDirective} from '../../directives/full-screen/toggle-full-screen.directive';
import {LinkDirective} from '../../directives/router-link/router-link-directive';
import {AccountSelectorComponent} from '../../inputs/app-account-selector/selector.component';
import {ArticleSelectorComponent} from '../../inputs/app-article-selector/selector.component';
import {CountrySelectorComponent} from '../../inputs/app-country-selector/selector.component';
import {CreatorModifierInformationComponent} from '../../inputs/app-creator-modifier-info/creator-modifier-info.component';
import {DivisionSelectorComponent} from '../../inputs/app-division-selector/selector.component';
import {DocumentSelectorComponent} from '../../inputs/app-document-selector/selector.component';
import {DropdownOptionSelectorComponent} from '../../inputs/app-dropdown-option/selector.component';
import {DropdownSelectorComponent} from '../../inputs/app-dropdown-selector/selector.component';
import {GroupSelectorComponent} from '../../inputs/app-group-selector/selector.component';
import {LocaleSelectorComponent} from '../../inputs/app-locale-selector/selector.component';
import {MailTemplateSelectorComponent} from '../../inputs/app-mail-template-selector/selector.component';
import {PriceListSelectorComponent} from '../../inputs/app-price-list-selector/selector.component';
import {PromotionSelectorComponent} from '../../inputs/app-promotion-selector/selector.component';
import {RelationSelectorComponent} from '../../inputs/app-relation-selector/selector.component';
import {RoleSelectorComponent} from '../../inputs/app-role-selector/selector.component';
import {StarRatingComponent} from '../../inputs/app-star-rating/selector.component';
import {TranslationFieldEditorComponent} from '../../inputs/app-translation-field-editor/editor.component';
import {AuthenticatedComponent} from '../../layouts/authenticated/authenticated.component';
import {LeftSideNavigationComponent} from '../../layouts/authenticated/left-side-navigation-component/left-side-navigation.component';
import {UnAuthenticatedComponent} from '../../layouts/unauthenticated/unauthenticated.component';
import {SafeHtmlPipe} from '../../pipes/safe-html.pipe';
import {ppSecondsToTime} from '../../pipes/secondstotime.pipe';
import {ppToDate} from '../../pipes/todateobject.pipe';
import {ConditionsComponent} from '../unauthenticated/conditions/conditions.component';
import {PrivacyComponent} from '../unauthenticated/privacy/privacy.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatMenuModule,
    MatButtonModule,
    MatBadgeModule,
    MatListModule,
    MatTabsModule,
    MatCardModule,
    MatGridListModule,
    MatDialogModule,
    MatInputModule,
    MatPaginatorModule,
    MatStepperModule,
    MatTableModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatSelectModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatSortModule,
    MatRadioModule,
    MatChipsModule,
    MatTreeModule,
    MatProgressBarModule,
    NgxUploaderModule,
    PerfectScrollbarModule,
    MatMomentDateModule,
    MatSlideToggleModule,
    // Other
    NgxWigModule,
    DragulaModule, // Sort / rearrange table rows
  ],
  declarations: [
    // Terms nd conditions
    ConditionsComponent,
    PrivacyComponent,
    // Layouts
    AuthenticatedComponent,
    UnAuthenticatedComponent,
    LeftSideNavigationComponent,
    ButtonComponent,
    FullCalendarComponent,
    AlertComponent,
    ToggleFullScreenDirective,
    TimelineComponent,
    ChartjsWrapperComponent,
    // Form inputs
    AccountSelectorComponent,
    RelationSelectorComponent,
    DivisionSelectorComponent,
    ArticleSelectorComponent,
    DropdownOptionSelectorComponent,
    DocumentSelectorComponent,
    LocaleSelectorComponent,
    CountrySelectorComponent,
    MailTemplateSelectorComponent,
    RoleSelectorComponent,
    StarRatingComponent,
    DropdownSelectorComponent,
    PriceListSelectorComponent,
    // Dialogs
    CrmRelationEditorComponent,
    CrmContactEditorComponent,
    CrmAddressEditorComponent,
    DocumentEditorComponent,
    ArticleEditorComponent,
    LocationEditorComponent,
    ScheduleEditorComponent,
    TimelineEditorComponent,
    AccountEditorComponent,
    RoleEditorComponent,
    AclEditorComponent,
    SupportTicketEditorComponent,
    DivisionEditorComponent,
    AccountDivisionEditorComponent,
    AttachmentControllerComponent,
    EmailEditorComponent,
    ActivationEditorComponent,
    ActivationAccountEditorComponent,
    ActivationArticleEditorComponent,
    ArticlePickerComponent,
    StarRatingComponent,
    GroupEditorComponent,
    GroupFinderComponent,
    ActivationEntryEditorComponent,
    PromotionEditorComponent,
    TranslationFieldEditorComponent,
    GroupWrapperComponent,
    GroupSelectorComponent,
    VisualArticlePickerComponent,
    RelationPickerComponent,
    PromotionPickerComponent,
    PromotionCodePickerComponent,
    PromotionSelectorEditorComponent,
    BasketLineEditorComponent,
    CountryEditorComponent,
    PriceListEditorComponent,
    PromotionSelectorComponent,
    ImportDocumentLinesWizardComponent,
    ImportAddressesWizardComponent,
    ImportTrackAndTraceWizardComponent,
    FileUploaderComponent,
    FileFolderEditorComponent,
    // Directives
    ConfirmDialogComponent,
    ConfirmDialogDirective,
    LinkDirective,
    ErrorValidatorDirective,
    // other
    ppToDate,
    ppSecondsToTime,
    SafeHtmlPipe,
    AttachmentBrowserComponent,
    CreatorModifierInformationComponent
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatMenuModule,
    MatButtonModule,
    MatBadgeModule,
    MatListModule,
    MatTabsModule,
    MatCardModule,
    MatGridListModule,
    MatDialogModule,
    MatInputModule,
    MatPaginatorModule,
    MatStepperModule,
    MatTableModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatOptionModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatSortModule,
    MatRadioModule,
    MatChipsModule,
    MatTreeModule,
    MatProgressBarModule,
    NgxUploaderModule,
    PerfectScrollbarModule,
    MatMomentDateModule,
    MatSlideToggleModule,
    // Dialogs
    CrmRelationEditorComponent,
    CrmContactEditorComponent,
    CrmAddressEditorComponent,
    DocumentEditorComponent,
    ArticleEditorComponent,
    LocationEditorComponent,
    TimelineEditorComponent,
    ScheduleEditorComponent,
    AccountEditorComponent,
    RoleEditorComponent,
    AclEditorComponent,
    SupportTicketEditorComponent,
    DivisionEditorComponent,
    AccountDivisionEditorComponent,
    AttachmentControllerComponent,
    EmailEditorComponent,
    ActivationEditorComponent,
    ActivationAccountEditorComponent,
    ActivationArticleEditorComponent,
    ArticlePickerComponent,
    StarRatingComponent,
    GroupEditorComponent,
    GroupFinderComponent,
    ActivationEntryEditorComponent,
    PromotionEditorComponent,
    TranslationFieldEditorComponent,
    GroupWrapperComponent,
    GroupSelectorComponent,
    VisualArticlePickerComponent,
    RelationPickerComponent,
    PromotionPickerComponent,
    PromotionCodePickerComponent,
    PromotionSelectorEditorComponent,
    BasketLineEditorComponent,
    CountryEditorComponent,
    PriceListEditorComponent,
    ImportDocumentLinesWizardComponent,
    ImportAddressesWizardComponent,
    ImportTrackAndTraceWizardComponent,
    // Other
    ButtonComponent,
    FullCalendarComponent,
    ConfirmDialogDirective,
    ConfirmDialogComponent,
    AlertComponent,
    TimelineComponent,
    ChartjsWrapperComponent,
    LinkDirective,
    ErrorValidatorDirective,
    AttachmentBrowserComponent,
    // Form inputs
    AccountSelectorComponent,
    RelationSelectorComponent,
    DivisionSelectorComponent,
    ArticleSelectorComponent,
    DropdownOptionSelectorComponent,
    DocumentSelectorComponent,
    LocaleSelectorComponent,
    CountrySelectorComponent,
    MailTemplateSelectorComponent,
    RoleSelectorComponent,
    DropdownSelectorComponent,
    PriceListSelectorComponent,
    PromotionSelectorComponent,
    FileUploaderComponent,
    FileFolderEditorComponent,
    // Other
    NgxWigModule,
    ppToDate,
    ppSecondsToTime,
    SafeHtmlPipe,
    DragulaModule,
    CreatorModifierInformationComponent
  ]
})
export class SharedModule {
}
