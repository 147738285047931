import {Time} from '@angular/common';
import {Country} from '../Country/country.object';
import {DefaultModel} from '../Default/default.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';

export class LeadTime extends DefaultModel {

  Origin: DropdownOption = null;
  Destination: Country = null;
  LeadTime: Number = null;
  CutOffTime: Time = null;

  constructor(data: any = null) {
    super(data);
    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.Origin = this.Origin ? new DropdownOption(this.Origin) : null;
    this.Destination = this.Destination ? new Country(this.Destination) : null;

  }
}
