import {HttpParams} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {forkJoin} from 'rxjs';
import * as XLSX from 'xlsx';
import {Article} from '../../models/Article/article.object';
import {ArticleService} from '../../models/Article/article.service';
import {Document} from '../../models/Document/document.object';
import {DocumentLines} from '../../models/DocumentLine/documentline.object';

@Component({
  selector: 'app-import-documentlines-wizard',
  templateUrl: './import-document-lines-wizard.component.html',
  styleUrls: ['./import-document-lines-wizard.component.css'],
  providers: [ArticleService]
})
export class ImportDocumentLinesWizardComponent implements OnInit {
  // MatDialogs
  @ViewChild('templateRef') templateRef;
  Data: { Article: Article, ArticleCode: string, Ordered: number, IsLoading: boolean }[] = [];
  @Input() Document: Document = null;
  @Output() DocumentChange: EventEmitter<Document> = new EventEmitter<Document>();
  dialogRef: MatDialogRef<ImportDocumentLinesWizardComponent>;

  constructor(private _Dialog: MatDialog, private _Article: ArticleService) {
  }

  ngOnInit() {
  }

  // Show editor & assign vars
  edit(input: Document = new Document) {
    this.Data = [];
    this.dialogRef = this._Dialog.open(this.templateRef);
    // this._Dialog.getDialogById(this.dialogRef.id).close();
  }

  import() {
    this.Data.forEach(row => {
      if (row.Article) {
        const tmpLine = new DocumentLines({Article: row.Article, Ordered: row.Ordered, UnitPrice: row.Article.SalesPrice});
        if (!tmpLine.Description) tmpLine.Description = tmpLine.Article.CommercialName;
        this.Document.DocumentLines.push(tmpLine);
      }
    });
    this.DocumentChange.emit(this.Document);
    this._Dialog.getDialogById(this.dialogRef.id).close();
  }

  isLoading() {
    return this.Data.findIndex(x => x.IsLoading === true) !== -1;
  }

  readFile(e) {
    e.stopPropagation();
    e.preventDefault();

    const files = e.target.files;
    const reader = new FileReader();

    reader.onload = (e: any) => {

      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname]; */

      // Grab the sheet with name "Import"
      const ws: XLSX.WorkSheet = wb.Sheets['Import'];

      /* save data */
      const test = XLSX.utils.sheet_to_json(ws, {header: 1});
      this.parseRows(test);
    };

    reader.readAsBinaryString(files[0]);

  }

  // Read rows and returns as an array of object with {key: value}
  parseRows(rows: any[]) {
    rows.splice(0, 1); // Delete header row
    rows = rows.filter(r => r.length > 0); // Delete empty rows
    console.log(rows);

    const obs = [];

    rows.forEach((row, i) => {

      // Add to lines
      this.Data.push({Article: null, ArticleCode: row[0], Ordered: row[1], IsLoading: true});
      //this.List.push(new DocumentLines({Ordered: row[1]}));

      // Map Article
      let httpParams = new HttpParams().append('filter', JSON.stringify({
        where: {Code: row[0]},
        include: ['Children']
      }));
      httpParams = httpParams.append('relationId', this.Document.Relation.Id);
      httpParams = httpParams.append('showAll', JSON.stringify(true));

      this._Article.searchRepresentativePrices(httpParams).subscribe(result => {
        if (result.length === 1) {
          this.Data[i].Article = result[0];
          this.Data[i].IsLoading = false;
        } else {
          this.Data[i].IsLoading = false;
        }
      }, err => {
        this.Data[i].IsLoading = false;
      });
      /*
      obs.push(
          this._Article.searchRepresentativePrices(httpParams)
      );
       */
    });

    forkJoin(obs).subscribe(res => {
      res.forEach((result, i) => {
        if (res.length === 1) {
          this.Data[i].Article = result[0];
          this.Data[i].IsLoading = false;
        } else {
          this.Data[i].IsLoading = false;
        }
      });
    }, err => {
      console.error(err);
    });


  }
}
