import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Schedule} from '../../models/Schedule/schedule.object';
import {ScheduleService} from '../../models/Schedule/schedule.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-schedule-editor',
  templateUrl: './schedule-editor.component.html',
  styleUrls: ['./schedule-editor.component.css'],
  providers: [{provide: MAT_DIALOG_DATA, useValue: ''}, ScheduleService, MatDialog]

})
export class ScheduleEditorComponent implements OnInit {


  @Input() List: Schedule[] = [];
  @Output() ListChange: EventEmitter<Schedule[]> = new EventEmitter<Schedule[]>();
  @ViewChild('templateRef') templateRef;
  Dialog: MatDialogRef<ScheduleEditorComponent>;
  Original: Schedule = null;
  Copy: Schedule = null;
  Type = 0;
  MonthDays: Array<any> = new Array(31).fill(0);
  Months: Array<string> = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'];
  Days: Array<string> = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
  isLoading = false;

  constructor(private _Dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private _Schedule: ScheduleService, public _Global: GlobalService, private _CdRef: ChangeDetectorRef) {
  }

  edit(t: Schedule = new Schedule()) {
    // set radio button correctly
    if (t.DayOfWeek) this.Type = 0;
    if (t.DayOfMonth) this.Type = 1;
    if (t.MonthOfYear) this.Type = 2;
    this.Dialog = this._Dialog.open(this.templateRef);
    this.Original = t;
    this.Copy = new Schedule(JSON.parse(JSON.stringify(t)));
    this.Copy.Division = this.Copy.Division ? this.Copy.Division : this._Global.Division;

  }

  copy(t: Schedule = new Schedule()) {
    this.Copy = new Schedule(JSON.parse(JSON.stringify(t)));
    this.Copy.Id = null;
    this.edit(this.Copy);
  }

  save() {
    this.isLoading = true;
    this._Schedule.upsert(this.Copy).subscribe(res => {
      this._Dialog.getDialogById(this.Dialog.id).close();
      this.Copy = res;
      const found = this.List.find(x => x.Id === res.Id);
      found ? this.List[this.List.indexOf(found)] = this.Copy : this.List.push(this.Copy);
      Object.assign(this.Original, this.Copy);
      this.ListChange.emit(this.List);
      this.isLoading = false;
    });
  }


  remove(r: Schedule, close?: boolean) {
    this.isLoading = true;
    this._Schedule.remove(r).subscribe(res => {
      this.List.splice(this.List.indexOf(this.List.find(x => x.Id === r.Id && x.Id !== null)), 1);
      this.ListChange.emit(this.List);
      if (close) this._Dialog.getDialogById(this.Dialog.id).close();
      this.isLoading = false;
    });

  }


  ngOnInit() {
  }

  compareFn(optionOne, optionTwo): boolean {
    return optionTwo && optionOne ? optionOne.Id === optionTwo.Id : false;

  }


}
