import {Account} from '../Account/account.object';
import {ActivationArticle} from '../ActivationArticle/activation-article.object';
import {Article} from '../Article/article.object';
import {DocumentLines} from '../DocumentLine/documentline.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';
import {Promotion} from '../Promotion/promotion.object';
import {PromotionCode} from '../PromotionCode/promotion-code.object';
import {PromotionOption} from '../PromotionOption/promotion-option.object';
import {Relation} from '../Relation/relation.object';

export class BasketLine {

  Id: string = null;
  Promotion: Promotion = null;
  PromotionCode: PromotionCode = null;
  PromotionOption: PromotionOption = null;
  Original: any = null; // Represents the original object (DocumentLine, ActivationArticle, ..)
  Article: Article = null;
  Account: Account = null;
  UnitPrice = 0;
  Unit: DropdownOption = null;
  Ordered = 0;
  Discount = 0;
  OrderNumber = 0;
  DiscountReason: DropdownOption = null;
  Category: DropdownOption = null;

  // For NgModelgroups
  randomNumber: number;

  constructor(data: any = null) {

    this.randomNumber = Math.random();

    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.Article = this.Article ? new Article(this.Article) : null;
    this.Account = this.Account ? new Account(this.Account) : null;
    this.Category = this.Category ? new DropdownOption(this.Category) : null;
    this.DiscountReason = this.DiscountReason ? new DropdownOption(this.DiscountReason) : null;
    this.Promotion = this.Promotion ? new Promotion(this.Promotion) : null;
    this.PromotionCode = this.PromotionCode ? new PromotionCode(this.PromotionCode) : null;
    this.PromotionOption = this.PromotionOption ? new PromotionOption(this.PromotionOption) : null;
    this.Unit = this.Unit ? new DropdownOption(this.Unit) : null;

  }

  asDocumentLine(relation: Relation = null): DocumentLines {
    return new DocumentLines({
      Id: this.Original ? this.Original.Id : null,
      Article: this.Article,
      Description: this.Article ? this.Article.CommercialName : (this.PromotionCode ? this.PromotionCode.Description : 'No Description found!'),
      Ordered: this.Ordered,
      Discount: this.Discount,
      DiscountReason: this.DiscountReason,
      Unit: this.Unit ? this.Unit : (this.Article && this.Article.Packaging ? this.Article.Packaging : null),
      Promotion: this.Promotion,
      PromotionCode: this.PromotionCode,
      UnitPrice: this.Article && this.Article.SalesPrice ? this.Article.SalesPrice : (this.UnitPrice ? this.UnitPrice : 0),
      VATCode: this.PromotionCode ? {Id: '0adba52b-ad6f-459d-9134-8312e8786331'} : (relation && relation.DefaultVATCode ? relation.DefaultVATCode : null),
    });
  }

  asActivationArticle(): ActivationArticle {

    // Create from original
    const tmpObj: ActivationArticle = new ActivationArticle(JSON.parse(JSON.stringify(this.Original)));

    // Apply changes
    tmpObj.Article = this.Article;
    tmpObj.Amount = this.Ordered;

    // Return
    return tmpObj;
  }
}
