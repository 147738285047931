import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-creator-modifier-icon',
  templateUrl: './creator-modifier-info.component.html',
  styleUrls: ['creator-modifier-info.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreatorModifierInformationComponent implements OnInit {

  @Input() object: any = {};

  constructor() {
  }

  ngOnInit() {
  }

}
