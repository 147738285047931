import {HttpParams} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit, TemplateRef} from '@angular/core';
import {UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DropdownOption} from '../../models/DropdownOption/dropdown-option.object';
import {DropdownOptionService} from '../../models/DropdownOption/dropdown-option.service';
import {FilterObject} from '../../objects/filter-object';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-dropdown-option',
  templateUrl: './selector.component.html',
  providers: [
    DropdownOptionService,
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DropdownOptionSelectorComponent), multi: true}
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownOptionSelectorComponent implements OnInit {

  _value: any = null; // Could be DropdownOption or DropdownOption[]

  DropdownOptions: DropdownOption[];
  @Input() placeholder = 'DropdownOption';
  @Input() blancoOption = false;
  @Input() emptyOption: boolean = false; // add null val
  @Input() multiple: string | boolean = false;
  @Input() displayColors = false; // Display colors of options if theyre available?
  @Input() getOnlyId = false;
  @Input() required = false;
  @Input() disabled = false;
  @Input() model = null;
  @Input() filterWhere = null;
  @Input() name = null;
  @Input() discriminator = null;
  @Input() mathint = null;
  @Input() matSuffix: TemplateRef<any>;

  ChildControl = new UntypedFormControl();
  Selected: string = null; // Default selected
  filterObject: FilterObject = new FilterObject();

  get value() {
    return this._value;
  }

  set value(val) {
    if (val instanceof DropdownOption || !val) {
      this._value = val;
      this.ChildControl.setValue(val);
      this.onChange(val);
    } else if (val instanceof Array) {
      this._value = val;
      this.ChildControl.setValue(val);
      this.onChange(val);
    }
  }

  // CODE BELOW IS REQUIRED TO REGISTER THE NG_VALUE_ACCESSOR
  onChange: any = () => {
  };

  compareWith(o1: any, o2: any) {

    return o1 === o2 || o1 && o2 && o1.Id && o2.Id && o1.Id === o2.Id;
  }

  ngOnInit() {
    if (this.multiple === '') this.multiple = true;
    const and = [{IsActive: true}, {Model: this.model}, {Discriminator: this.discriminator}, {or: [{DivisionId: this._Global.Division ? this._Global.Division.Id : null}, {DivisionId: null}]}];
    if (this.filterWhere) and.push(this.filterWhere);
    this.filterObject.order = 'Order ASC';
    this.filterObject.where = {and: and};
    this.filterObject.limit = null;
    this.filterObject.include = ['Translations'];

    const Params: HttpParams = new HttpParams()
      .append('filter', JSON.stringify(this.filterObject));

    this._DropdownOption.get(Params).subscribe(res => {
      this.DropdownOptions = res;
      this._CdRef.detectChanges();

    });
  }

  onTouched: any = () => {
  };

  constructor(private _DropdownOption: DropdownOptionService, private _Global: GlobalService, private _CdRef: ChangeDetectorRef) {
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    this.value = value;
  }

}
