import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Article} from '../Article/article.object';
import {Promotion} from '../Promotion/promotion.object';
import {PromotionCode} from '../PromotionCode/promotion-code.object';
import {Relation} from '../Relation/relation.object';
import {Group} from './group.object';

@Injectable()
export class GroupService {

  defaultInclude: any[] = [
    {'Relations': {'DefaultAddress': {'Country': 'ShipmentArticle'}}},
    {'Articles': ['Unit', 'Packaging']},
    {'Promotions': 'Title'},
    {'PromotionCodes': ['Type', 'Article', 'Categories', 'Groups']}, 'Modifier', 'Creator',
  ];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<Group[]> {
    return this.http
      .get<Group[]>(environment.apiUrl + '/Groups', {params: ReqParams}).pipe(
        map(res => <Group[]>res),
        map(res => {
          return res.map(rel => new Group(rel));
        }));
  }

  getById(Id: string, ReqParams: HttpParams = null): Observable<Group> {
    return this.http
      .get<Group>(environment.apiUrl + '/Groups/' + Id, {params: ReqParams}).pipe(
        map(res => <Group>res),
        map(res => {
          return new Group(res);
        }));
  }

  upsert(Entry: Group): Observable<Group> {
    return this.http
      .put<Group>(environment.apiUrl + '/Groups', JSON.stringify(Entry)).pipe(
        map(res => <Group>res),
        map(res => {
          // Modify original doc without destroying related objects (doclines)
          for (const prop in res) if (prop in Entry) Entry[prop] = res[prop];
          return new Group(Entry);
        }));

  }

  remove(Entry: Group): Observable<{ count: number }> {
    return this.http
      .delete<{ count: number }>(environment.apiUrl + '/Groups/' + Entry.Id);
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
      .get(environment.apiUrl + '/Groups/count', {params: ReqParams})
      .pipe(
        map(res => <{ count: number }>res)
      );
  }

  removeByRelation(entry: Group, rel: Relation): Observable<{ count: number }> {
    return this.http
      .delete<{ count: number }>(environment.apiUrl + '/Groups/' + entry.Id + '/Relation/' + rel.Id);
  }

  removeByArticle(entry: Group, art: Article): Observable<{ count: number }> {
    return this.http
      .delete<{ count: number }>(environment.apiUrl + '/Groups/' + entry.Id + '/Article/' + art.Id);
  }

  removeByPromotion(entry: Group, art: Promotion): Observable<{ count: number }> {
    return this.http
      .delete<{ count: number }>(environment.apiUrl + '/Groups/' + entry.Id + '/Promotion/' + art.Id);
  }

  removeByPromotionCode(entry: Group, art: PromotionCode): Observable<{ count: number }> {
    return this.http
      .delete<{ count: number }>(environment.apiUrl + '/Groups/' + entry.Id + '/PromotionCode/' + art.Id);
  }
}
