import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Buffer} from 'buffer';
import {Division} from '../../models/Division/division.object';
import {DivisionService} from '../../models/Division/division.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-division-editor',
  templateUrl: './division-editor.component.html',
  styleUrls: ['./division-editor.component.css'],
  providers: [DivisionService]
})
export class DivisionEditorComponent implements OnInit {

  // Original list to modify
  @Input() List: Division[] = [];
  @Output() ListChange: EventEmitter<Division[]> = new EventEmitter<Division[]>();

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<DivisionEditorComponent>;

  // Original and copy;
  Original: Division = new Division;
  Copy: Division = new Division;

  isLoading = false;

  constructor(private _Dialog: MatDialog, private _Division: DivisionService, private _CdRef: ChangeDetectorRef, private _Global: GlobalService) {
  }

  ngOnInit() {

  }

  edit(input: Division = new Division) {
    this.Original = input; // Store original
    this.Copy = new Division(JSON.parse(JSON.stringify(input)));
    this.dialogRef = this._Dialog.open(this.templateRef);
    this._CdRef.detectChanges();
  }


  save(object: Division = this.Copy) {
    this.isLoading = true;
    this._Division.upsert(object).subscribe(res => {
      this.isLoading = false;
      this.Copy = res;
      Object.assign(this.Original, res);
      const found = this.List.find(x => x.Id === res.Id);
      found ? Object.assign(found, res) : this.List.push(res); // Modify or push array list
      this.ListChange.emit(this.List);
      this._Dialog.getDialogById(this.dialogRef.id).close();
    }, err => {
      this.isLoading = false;
    });
  }

  remove(object: Division) {
    this.isLoading = true;
    this._Division.remove(object).subscribe(res => {
      if (this.List) this.List.splice(this.List.findIndex(x => x.Id === object.Id), res.count);
      this.ListChange.emit(this.List);
      this.isLoading = false;
    }, () => this.isLoading = false);
  }


  readFile(e) {
    const Files = e.target.files;
    const reader = new FileReader();
    let base64 = null;
    reader.addEventListener('load', () => {
      base64 = reader.result;
    }, false);
    reader.readAsDataURL(Files[0]);
    reader.onload = (event) => {
      this.processImage(base64);
    };
  }

  processImage(base64: any) {
    const byteCharacters = atob(base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    this.Copy.Logo = new Buffer(Array.from(byteArray)); // Create a NodeJS buffer
  }

}
