import {HttpEventType, HttpResponse} from '@angular/common/http';
import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Attachment} from '../../models/Attachment/attachment.object';
import {AttachmentService} from '../../models/Attachment/attachment.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css'],
  providers: [AttachmentService]
})
export class FileUploaderComponent implements OnInit {

  queue: { file: File, progress: number }[] = [];

  @ViewChild('file') file;

  @Input() path: string = null;
  @Input() string: Attachment[] = [];
  UploadedFiles: Attachment[] = [];

  constructor(private _Global: GlobalService,
              private _Dialog: MatDialog,
              private _Attachment: AttachmentService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<FileUploaderComponent>) {
    this.path = data.path;
  }

  ngOnInit() {
    this.queue = []; // Empty the queue
  }

  addToQueue() {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (const key in files) {
      if (!isNaN(parseInt(key, 10))) {
        this.queue.push({file: files[key], progress: 0});
      }
    }
  }

  removeFromQueue(input: { file: File, progress: number }) {
    this.queue.splice(this.queue.indexOf(input), 1);
  }

  addFiles() {
    this.file.nativeElement.click();
  }

  startUpload() {
    this.queue.forEach(row => {
      this._Attachment.upload(row.file, this.path).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          // calculate the progress percentage
          row.progress = Math.round((100 * event.loaded) / event.total);
        } else if (event instanceof HttpResponse) {
          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          this.UploadedFiles.push(new Attachment(event.body));
        }
      });
    });
  }

  isUploading(): boolean {
    return this.queue.findIndex(x => x.progress > 0 && x.progress < 100) !== -1;
  }

  hasQueue(): boolean {
    return this.queue.findIndex(x => x.progress === 0) !== -1;
  }

  close() {
    console.log(this.UploadedFiles);
    this.dialogRef.close(this.UploadedFiles);
  }
}
