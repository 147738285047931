import {HttpParams} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnDestroy, Output, TemplateRef} from '@angular/core';
import {ControlValueAccessor, NG_ASYNC_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/internal/operators';
import {DropdownOption} from '../../models/DropdownOption/dropdown-option.object';
import {DropdownOptionService} from '../../models/DropdownOption/dropdown-option.service';
import {FilterObject} from '../../objects/filter-object';

@Component({
  selector: 'app-dropdown-selector',
  templateUrl: './selector.component.html',
  providers: [
    DropdownOptionService,
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DropdownSelectorComponent), multi: true},
    {provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => DropdownSelectorComponent), multi: true}
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownSelectorComponent implements ControlValueAccessor, OnDestroy {

  // SELECTOR <=> this.ChildControl <=> CONTROLLER (THIS) <=> value (get/set) <=> END component

  Results: DropdownOption[];
  ChildControl = new UntypedFormControl();

  @Input('name') name: string = null; // verplicht ?
  @Input() placeholder = 'value';
  @Input() required: any = null; // Does not work with @param required
  @Input() distanceTo: { lat: number, lng: number } = null; // If isset, calculate the distance to this point
  @Input() matSuffix: TemplateRef<any> = null; // Wrap into matSuffix
  @Input() where: object = {};
  @Output() filterSearch: EventEmitter<any> = new EventEmitter<any>();
  Selected: DropdownOption = null; // Default selected
  filterObject: FilterObject = new FilterObject;

  Subscription: Subscription;
  // CODE BELOW IS REQUIRED TO REGISTER THE NG_VALUE_ACCESSOR
  onChange: any = () => {
  };
  onTouched: any = () => {
  }

  constructor(private _Dropdown: DropdownOptionService) {
    this.Subscription = this.ChildControl.valueChanges
        .pipe(debounceTime(400))
        .subscribe(a => {
          if (a instanceof DropdownOption) { // Selected trough autocomplete
            this.value = a;
          } else if (a) { // String
            this.doSearch(a).subscribe(res => {
              this.Results = res;
              // Only one result found / multiple
              this.value = res && res.length === 1 ? res[0] : a;
            });
          } else { // Empty
            this.value = null;
          }
        });
  }

  get value() {
    return this.Selected instanceof DropdownOption ? this.Selected : null;
  }

  set value(val) {
    this.Selected = val;
    if (val !== this.ChildControl.value) this.ChildControl.setValue(val);
    this.onChange(this.Selected);
    this.onTouched();
  }

  // Async validator, note the NG_ASYNC_VALIDATORS provider !

  filter() {
    console.warn('filtering');
    this.filterSearch.emit(this.Selected);
  }

  ngOnDestroy() {
    this.Subscription.unsubscribe();
  }

  // Triggered when param value changes.
  validate(c: UntypedFormControl): Observable<any> {
    return new Observable(observer => {
      // Selected from autocomplete list = valid OR empty and NOT required
      if (this.Selected instanceof DropdownOption || (!this.ChildControl.value && !this.required)) {
        this.ChildControl.setErrors(null);
        observer.next(null);
        observer.complete();
      } else {
        this.ChildControl.setErrors({err: 'err'});
        observer.next({'err': true});
        observer.complete();
      }
    });
  }

  // Asynch search observable
  doSearch(e: any): Observable<DropdownOption[]> {
    this.filterObject.where = {and: [{'Value': {'like': '%' + e + '%'}}, this.where]};
    const Params = new HttpParams().append('filter', JSON.stringify(this.filterObject));
    return this._Dropdown.get(Params);
  }

  displayFn(input: DropdownOption): string {
    return input instanceof DropdownOption ? input.Value : input;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    this.value = value;
  }

}
