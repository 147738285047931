import {HttpParams} from '@angular/common/http';
import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {forkJoin} from 'rxjs';
import {Account} from '../../models/Account/account.object';
import {AccountService} from '../../models/Account/account.service';
import {AccountDivision} from '../../models/AccountDivision/account-division.object';
import {AccountDivisionService} from '../../models/AccountDivision/account-division.service';
import {Address} from '../../models/Address/addresses.object';
import {AttachmentService} from '../../models/Attachment/attachment.service';
import {BasketLine} from '../../models/BasketLine/basket-line.object';
import {BasketLineService} from '../../models/BasketLine/basket-line.service';
import {Division} from '../../models/Division/division.object';
import {DivisionService} from '../../models/Division/division.service';
import {Relation} from '../../models/Relation/relation.object';
import {AlertService} from '../../services/alert.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-account-editor',
  templateUrl: './account-editor.component.html',
  styleUrls: ['./account-editor.component.css'],
  providers: [AccountService, AttachmentService, BasketLineService, DivisionService, AccountDivisionService]
})
export class AccountEditorComponent implements OnInit {

  // Original list to modify
  @Input() List: Account[] = [];
  @Output() ListChange: EventEmitter<Account[]> = new EventEmitter<Account[]>();

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<AccountEditorComponent>;

  // Original and copy;
  Original: Account = new Account();
  Copy: Account = new Account;

  // Lists
  Divisions: Division[] = [];
  allowedDivisions: Division[] = [];

  isLoading = false;
  isLoadingEO = false;
  isLoadingDir = false;

  constructor(private _Dialog: MatDialog,
              private divisionService: DivisionService,
              private _Attachment: AttachmentService,
              private _Basket: BasketLineService,
              private _AccountDivision: AccountDivisionService,
              private _Alert: AlertService,
              private _Account: AccountService,
              private _CdRef: ChangeDetectorRef,
              public _Global: GlobalService) {
  }

  ngOnInit() {
  }

  edit(input: Account = new Account) {
    this.Original = input; // Store original
    this.Copy = new Account(JSON.parse(JSON.stringify(input)));
    if (!this.Copy.Address) this.Copy.Address = new Address({Type: {Id: 'd5b0344f-e908-4a59-953f-a9ae1bbbcaad'}}); // Google Maps Type for accounts, because these get calculated
    this.divisionService.get().subscribe(res => this.Divisions = res);
    this.dialogRef = this._Dialog.open(this.templateRef);
    this._CdRef.detectChanges();
  }

  remove(object: Account) {
    this.isLoading = true;
    this._Account.remove(object).subscribe(res => {
      if (this.List) this.List.splice(this.List.findIndex(x => x.Id === object.Id), res.count);
      this.ListChange.emit(this.List);
      this.isLoading = false;
    }, () => this.isLoading = false);
  }

  save(object: Account = this.Copy) {
    this.isLoading = true;
    this._Account.upsert(object).subscribe(res => {
      this.isLoading = false;
      this.Copy = res;
      Object.assign(this.Original, res);
      const found = this.List.find(x => x.Id === res.Id);
      found ? Object.assign(found, res) : this.List.push(res); // Modify or push array list
      if (this.allowedDivisions.length === 0) {
        this.isLoading = false;
      } else {
        this.allowedDivisions.forEach(d => {
          this._AccountDivision.upsert(new AccountDivision({
            Division: d,
            Account: this.Copy
          })).subscribe(res => {
            this.Copy.Divisions.push(d);
            this._Alert.sendMessage('Access to division has been added!', 'success');
            this.isLoading = false;
          });
        });
      }
      this.ListChange.emit(this.List);
      this._Dialog.getDialogById(this.dialogRef.id).close();
    }, err => {
      this.isLoading = false;
    });
  }

  compareWith(o1: any, o2: any) {
    return o1 === o2 || o1 && o2 && o1.Id && o2.Id && o1.Id === o2.Id;
  }

  retrievePassword(input: Account) {
    const url = window.location.href.split('/#')[0];
    this._Account.retrievePassword(
        {
          Email: input.Email,
          Url: url.indexOf('localhost') !== -1 ? url : (input.Role && input.Role.Id === 'Reseller' ? 'https://reseller.edgardcooper.be' : 'https://aorta.edgardcooper.be')
        }).subscribe(res => {
      this._Alert.sendMessage('Er werd een e-mail met instructies gestuurd naar ' + input.Email, 'success');
    });
  }

  linkWithExact() {
    this.isLoadingEO = true;
    this._Account.linkWithExact(this.Copy).subscribe(res => {
      this.isLoadingEO = false;
    }, err => this.isLoadingEO = false);
  }

  mkDir() {
    this.isLoadingDir = true;
    const ReqParams = new HttpParams().append('path', 'Accounts/' + this.Copy.Email);
    this._Attachment.mkDir(ReqParams).subscribe(res => {
      this._Alert.sendMessage('The directory has been created!', 'info');
      this.isLoadingDir = false;
    }, err => this.isLoadingDir = false);
  }

  addBasketLines(data: { lines: BasketLine[], relation: Relation }) {
    const arrObs = [];
    data.lines.forEach(line => { // Assign account
      line.Account = new Account({Id: this.Copy.Id});
      arrObs.push(this._Basket.upsert(line));
    });
    forkJoin(arrObs).subscribe(res => { // execute and add to array
      this.Copy.BasketLines = this.Copy.BasketLines.concat(data.lines);
    });
  }

  removeBasketLine(data: BasketLine) {
    this._Basket.remove(data).subscribe(res => {
      this.Copy.BasketLines.splice(this.Copy.BasketLines.indexOf(data), res.count);
    });
  }

  hasAccess(d: Division, a: Account): boolean {
    return a.Divisions.find(x => x.Id === d.Id) !== undefined;
  }

  setDivision(e: MatCheckboxChange, d: Division) {
    if (this.Copy.Id) {
      if (e.checked) {
        this._AccountDivision.upsert(new AccountDivision({Division: d, Account: this.Copy})).subscribe(res => {
          this.Copy.Divisions.push(d);
          this._Alert.sendMessage('Access to division has been added!', 'success');
        });
      } else {
        const Params = new HttpParams().append('where', JSON.stringify({
          AccountId: this.Copy.Id,
          DivisionId: d.Id
        }));
        this._AccountDivision.removeWithParams(Params).subscribe(rel => {
          this.Copy.Divisions.splice(this.Copy.Divisions.indexOf(d), 1);
          this._Alert.sendMessage('Access rule has been removed!', 'success');
        });
      }
    } else {
      if (e.checked) {
        this.allowedDivisions.push(d);
      } else {
        this.allowedDivisions.splice(this.allowedDivisions.indexOf(d), 1);
      }
    }

  }
}
