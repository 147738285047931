import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivationArticle} from '../../models/ActivationArticle/activation-article.object';
import {ActivationArticleService} from '../../models/ActivationArticle/activation-article.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-activation-article-editor',
  templateUrl: './activation-article-editor.component.html',
  styleUrls: ['./activation-article-editor.component.css'],
  providers: [ActivationArticleService]
})
export class ActivationArticleEditorComponent implements OnInit {

  // Original list to modify
  @Input() List: ActivationArticle[] = [];
  @Output() ListChange: EventEmitter<ActivationArticle[]> = new EventEmitter<ActivationArticle[]>();

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<ActivationArticleEditorComponent>;

  // Original and copy;
  Original: ActivationArticle = new ActivationArticle;
  Copy: ActivationArticle = new ActivationArticle;

  isLoading = false;

  constructor(private _Dialog: MatDialog, private _ActivationArticle: ActivationArticleService, private _Global: GlobalService) {
  }

  ngOnInit() {

  }

  edit(input: ActivationArticle = new ActivationArticle()) {
    this.Original = input; // Store original
    if (!input.Division) input.Division = this._Global.Division; // Assign Division if not assigned
    this.Copy = new ActivationArticle(JSON.parse(JSON.stringify(input)));
    if (!this.Copy.Account) this.Copy.Account = this._Global.Account;
    this.dialogRef = this._Dialog.open(this.templateRef);
  }

  remove(object: ActivationArticle) {
    this.isLoading = true;
    this._ActivationArticle.remove(object).subscribe(res => {
      if (this.List) this.List.splice(this.List.findIndex(x => x.Id === object.Id), res.count);
      this.ListChange.emit(this.List);
      this.isLoading = false;
    }, () => this.isLoading = false);
  }

  save(object: ActivationArticle = this.Copy, next: boolean = false) {
    this.isLoading = true;
    this._ActivationArticle.upsert(object).subscribe(res => {
      this.isLoading = false;
      this.Copy = res;
      Object.assign(this.Original, res);
      const found = this.List.find(x => x.Id === res.Id);
      found ? Object.assign(found, res) : this.List.push(res); // Modify or push array list
      this.ListChange.emit(this.List);
      this._Dialog.getDialogById(this.dialogRef.id).close();
      if (next) this.edit(new ActivationArticle({Activation: this.Copy.Activation}));
    }, err => {
      this.isLoading = false;
    });
  }

}
