import {Article} from '../Article/article.object';
import {Division} from '../Division/division.object';
import {PriceList} from '../PriceList/price-list.object';
import {Relation} from '../Relation/relation.object';

export class PriceListDetail {

  Id: string = null;
  Division: Division = null;
  Article: Article = null;
  Relation: Relation = null;
  Price: number = null;
  Discount: number = null;

  constructor(data: any = null) {
    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.Division = this.Division ? new Division(this.Division) : null;
    this.Article = this.Article ? new Article(this.Article) : null;
    this.Relation = this.Relation ? new Relation(this.Relation) : null;
  }
}
