import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Address} from '../../models/Address/addresses.object';
import {AddressService} from '../../models/Address/addresses.service';
import {ContactService} from '../../models/Contact/contact.service';
import {Group} from '../../models/Group/group.object';
import {GroupService} from '../../models/Group/group.service';
import {Relation} from '../../models/Relation/relation.object';
import {RelationService} from '../../models/Relation/relation.service';
import {GlobalService} from '../../services/global.service';
import {AddressMergerComponent} from '../address-merger/address-merger.component';

@Component({
  selector: 'app-crm-relation-editor',
  templateUrl: './crm-relation-editor.component.html',
  providers: [{provide: MAT_DIALOG_DATA, useValue: ''}, RelationService, ContactService, AddressService, GroupService],
  styleUrls: ['./crm-relation-editor.component.css'],
})
export class CrmRelationEditorComponent implements OnInit {

  @Input() List: Relation[] = [];
  @Output() ListChange: EventEmitter<Relation[]> = new EventEmitter<Relation[]>();
  @ViewChild('templateRef') templateRef;
  Dialog: MatDialogRef<CrmRelationEditorComponent>;
  mergeDialog: MatDialogRef<AddressMergerComponent>;
  Original: Relation = null;
  Copy: Relation = null;
  isLoading = false;
  isLoadingEO = false;
  env = environment;

  Groups: Observable<Group[]>;
  addressesToMerge: Address[] = [];

  constructor(private _Dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private _Relation: RelationService, public _Global: GlobalService, private _Group: GroupService) {
    this.Groups = this._Group.get();
  }

  edit(r: Relation = new Relation()) {
    if (!r.Division) r.Division = this._Global.Division;
    this.Original = r;
    this.Copy = new Relation(JSON.parse(JSON.stringify(r)));
    this.addressesToMerge = [];
    this.Dialog = this._Dialog.open(this.templateRef);
  }

  save() {
    this.isLoading = true;
    this._Relation.upsert(this.Copy).subscribe(res => {
      this.isLoading = false;
      this.Copy = res;
      Object.assign(this.Original, this.Copy);
      const found = this.List.find(x => x.Id === this.Copy.Id && x.Id !== null);
      found ? this.List[this.List.indexOf(found)] = this.Copy : this.List.push(this.Copy);
      this.ListChange.emit(this.List);
      this._Dialog.getDialogById(this.Dialog.id).close();
    }, err => this.isLoading = false);
  }


  remove(r: Relation) {
    this._Relation.remove(r).subscribe(res => {
      this.List.splice(this.List.indexOf(this.List.find(x => x.Id === r.Id && x.Id !== null)), 1);
      this.ListChange.emit(this.List);
    });

  }

  ngOnInit() {
  }

  compareFn(optionOne, optionTwo): boolean {
    return optionTwo && optionOne ? optionOne.Id === optionTwo.Id : false;
  }

  toggleCheck(e: MatCheckboxChange, a: Address) {
    e.checked ? this.addressesToMerge.push(a) : this.addressesToMerge.splice(this.addressesToMerge.indexOf(a), 1);
  }

  isChecked(a: Address) {
    return this.addressesToMerge.indexOf(a) !== -1;
  }

  openAddressMerger() {
    this.mergeDialog = this._Dialog.open(AddressMergerComponent, {data: {addresses: this.addressesToMerge}, disableClose: true});
    this.mergeDialog.afterClosed().subscribe(res => {
      if (res) {
        this.Copy.Addresses.splice(this.Copy.Addresses.findIndex(a => a.Id === res), 1);
        this.addressesToMerge = [];
      }
    });
  }

  close() {
    this._Dialog.getDialogById(this.Dialog.id).close();
  }

  pushToExact(object: Relation = this.Copy) {
    const tmpCopy = new Relation(JSON.parse(JSON.stringify(object)));
    tmpCopy.Documents = []; // Remove the documents to avoid the error 'Request too large'
    this.isLoadingEO = true;
    this._Relation.pushToExact(tmpCopy).subscribe(res => {
      this.isLoadingEO = false;
      Object.assign(object, res);
    }, err => this.isLoadingEO = false);
  }

  pullFromExact(object: Relation = this.Copy) {
    const tmpCopy = new Relation(JSON.parse(JSON.stringify(object)));
    tmpCopy.Documents = []; // Remove the documents to avoid the error 'Request too large'
    this.isLoadingEO = true;
    this._Relation.pullFromExact(tmpCopy).subscribe(res => {
      this.isLoadingEO = false;
      Object.assign(object, res);
    }, err => this.isLoadingEO = false);
  }

}
