import {Activation} from '../Activation/activation.object';
import {DefaultModel} from '../Default/default.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';

export class Score extends DefaultModel {

  Activation: Activation = null;
  Question: DropdownOption = null;
  Points: number = null;

  constructor(data: any = null) {
    super();
    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];
    this.Activation = this.Activation ? new Activation(this.Activation) : null;
    this.Question = this.Question ? new DropdownOption(this.Question) : null;
  }
}
