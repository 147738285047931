import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-unauthenticated',
  templateUrl: './unauthenticated.component.html',
  styleUrls: ['./unauthenticated.component.css']
})
export class UnAuthenticatedComponent implements OnInit {
  title = '';
  showButtons = false;

  constructor(public _Route: ActivatedRoute, private _Router: Router, public app: AppComponent) {
    this._Router.events.subscribe(event => {
      const snapshot = this._Route.snapshot;
      if (snapshot && snapshot.firstChild && snapshot.firstChild.data && snapshot.firstChild.data.breadcrumb) {
        this.showButtons = this._Route.snapshot.firstChild.data.buttons;
        this.title = this._Route.snapshot.firstChild.data.breadcrumb;
      }
    });
  }

  ngOnInit() {

  }

}
