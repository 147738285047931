import {Division} from '../Division/division.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';

export class DocumentType {

  Division: Division = null;
  Id: string = null;
  Title: string = null;
  Description: string = null;
  Prefix: string = null;
  Number: number = null;
  Footer = '';
  PageFooter = '';
  Type: DropdownOption = null;
  Template: string = null;
  IsVisibleOnDashboard: boolean = null;

  constructor(data: any = null) {
    // Assign
    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.Division = this.Division ? new Division(this.Division) : null;
  }
}
