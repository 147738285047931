import {Account} from '../Account/account.object';

export class Log {

  Id: string = null;
  Account: Account = null;
  Date: Date = null;

  constructor(data: any = null) {
    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];
    this.Account = this.Account ? new Account(this.Account) : null;

  }
}
