import {Locale} from '../Locales/locale.object';

export class Translation {

  Id: string = null;
  Locale: Locale = null;
  Model: string = null;
  ForeignId: string = null;
  Property: string = null;
  Value: string = null;

  constructor(data: any = null) {
    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.Locale = this.Locale ? new Locale(this.Locale) : null;
  }

  // Searches for translation and returns one or more.
  get(loc: Locale): string {
    return 'hi';
  }
}
