export class ACL {

  Id: string = null;
  RoleId: string = null;
  PrincipalId: string = null;
  PrincipalType: string = null;
  AccessType: string = null;
  Description: string = null;

  constructor(data: any = null) {
    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];
  }
}
