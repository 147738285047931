import {HttpParams} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as moment from 'moment';
import {Article} from '../../models/Article/article.object';
import {ArticleService} from '../../models/Article/article.service';
import {AttachmentService} from '../../models/Attachment/attachment.service';
import {BasketLine} from '../../models/BasketLine/basket-line.object';
import {BasketLineService} from '../../models/BasketLine/basket-line.service';
import {DocumentService} from '../../models/Document/document.service';
import {Promotion} from '../../models/Promotion/promotion.object';
import {PromotionService} from '../../models/Promotion/promotion.service';
import {PromotionCode} from '../../models/PromotionCode/promotion-code.object';
import {PromotionCodeService} from '../../models/PromotionCode/promotion-code.service';
import {Relation} from '../../models/Relation/relation.object';
import {RelationService} from '../../models/Relation/relation.service';
import {FilterObject} from '../../objects/filter-object';
import {AlertService} from '../../services/alert.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-visual-article-picker',
  templateUrl: './visual-article-picker.component.html',
  providers: [ArticleService, AttachmentService, MatSnackBar, DocumentService, PromotionService, RelationService, BasketLineService, PromotionCodeService],
  styleUrls: ['./visual-article-picker.component.css']
})
export class VisualArticlePickerComponent implements OnInit {

  // Array of all articles to show
  isLoading = false;
  isSimulating = false;
  isLoadingList = false;
  newDate: Date = new Date();
  currentHover: Article = null;
  showAll: boolean = false;
  selectedRelation: Relation = null;
  Scan: string = null;
  filterObject: FilterObject = new FilterObject();
  @Input() Original: any = null; // The initial Original object for when adding new lines. (This contains some important properties like Id, type, ParentActivation,..)
  @Input() Step = false; // use the article step to add and remove articles
  @Input() MinQTY = false; // use the article min order quantity to add articles
  @Input() Max: number = null; // max items that can be selected
  @Input() ShoppingCart = false; // show a shopping cart (orders)
  @Input() List: BasketLine[] = [];
  @Output() ListChange: EventEmitter<{ lines: BasketLine[], relation: Relation }> = new EventEmitter<{ lines: BasketLine[], relation: null }>(); // When ready we're generating and sending out an Document.
  @Input() Relation: Relation = null;
  @Output() RelationChange: EventEmitter<Relation> = new EventEmitter<Relation>();
  filterObjectPromotions: FilterObject = new FilterObject(); // FilterObject for the promotions

  Articles: Article[] = []; // Full list with articles
  Promotions: Promotion[] = []; // Full list with promotions
  PromotionCodes: PromotionCode[] = []; // Full list with promotion codes
  Selected: BasketLine[] = []; // List with selected articles and their amounts

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  @ViewChild('infoTemplateRef') infoTemplateRef;
  @ViewChild('filterForm') filterForm: NgForm;
  dialogRef: MatDialogRef<VisualArticlePickerComponent>;
  infoDialogRef: MatDialogRef<VisualArticlePickerComponent>;

  constructor(private _Dialog: MatDialog, private snackBar: MatSnackBar, private _BasketLine: BasketLineService, private _Article: ArticleService, private _Alert: AlertService, private _Promotion: PromotionService, private _PromotionCode: PromotionCodeService, public _Global: GlobalService, public _Relation: RelationService) {
    this.filterObject.include = _Article.defaultInclude;
    this.filterObject.order = 'AssortimentOrder ASC, Order ASC';
    this.filterObject.where = {ParentId: null};
    this.filterObject.limit = 24;

    this.filterObjectPromotions.include = _Promotion.defaultInclude;
    this.filterObjectPromotions.where = {
      or: [
        {and: [{StartDate: {lte: new Date()}}, {EndDate: {gte: new Date()}}]},
        {and: [{StartDate: null}, {EndDate: {gte: new Date()}}]},
        {and: [{StartDate: {lte: new Date()}}, {EndDate: null}]},
        {and: [{StartDate: null}, {EndDate: null}]},
        {and: [{PreviewDate: {lte: new Date()}}, {EndDate: {gte: new Date()}}]},
      ]
    };


  }

  ngOnInit() {
  }

  open(input: BasketLine[] = []) {

    this.isLoading = false;

    // Deep copy (to avoid error maximum stack exeed)
    this.Original = JSON.parse(JSON.stringify(this.Original));
    // Assign selected
    this.Selected = input;
    // Show dialog
    this.dialogRef = this._Dialog.open(this.templateRef, {maxWidth: 'none', width: '100%', maxHeight: 'none', height: '100%'});
    this.selectedRelation = this.Relation ? this.Relation : null;
    if (this.Relation) this.filter();
  }

  getData() {
    this.Articles = [];
    this.isLoadingList = true;
    // Get list of articles

    let Params = new HttpParams()
      .append('filter', JSON.stringify(this.filterObject));

    if (this.Relation) Params = Params.append('relationId', this.Relation.Id);
    if (this.showAll) Params = Params.append('showAll', this.showAll.toString());

    this._Article.searchRepresentativePrices(Params).subscribe(res => {
      this.Articles = res;
      this.isLoadingList = false;
    });

    Params = new HttpParams()
      .append('where', JSON.stringify(this.filterObject.where));
    if (this.showAll) Params = Params.append('showAll', this.showAll.toString());
    if (!this.showAll && this.Relation) Params = Params.append('relationId', this.Relation.Id);

    this._Article.countWithRepresentative(Params).subscribe(count => {
      this.filterObject.count = count.count;
    });

    // Fetch the promocodes available.
    this.codeFilter({StartFrom: this.newDate});
  }

  // open without resetting everything
  reopen(input: BasketLine[]) {
    this.Selected = input;
    this.dialogRef = this._Dialog.open(this.templateRef, {width: '80vw'});
  }

  getTotalCount(): number {
    let total = 0;
    this.Selected.forEach(line => {
      total += line.Ordered;
    });
    return total;
  }

  getAmount(input: Article): number {
    let amount = 0;
    const foundLines: BasketLine[] = this.Selected.filter(x => x.Article && x.Article.Id === input.Id);
    foundLines.forEach(line => amount += line.Ordered);
    return amount;
  }

  getTotalAmount(): number {
    let total = 0;
    this.Selected.forEach(line => {
      total += (line.Ordered * (line.Article ? line.Article.SalesPrice : 0)) * (1 - line.Discount);
    });
    return total;
  }

  // Check or multiple lines exists
  // We want to disable the add & remove button in case there are multiple lines found.
  hasMultipleLines(input: Article): boolean {
    const foundLines: BasketLine[] = this.Selected.filter(x => x.Article && x.Article.Id === input.Id);
    return foundLines.length > 1;
  }

  pageChange(e: { pageIndex: number, pageSize: number, length: number }) {
    this.filterObject.skip = e.pageIndex * e.pageSize;
    this.filterObject.limit = e.pageSize;
    this.getData();
  }

  // Make sure the step has been respected, per 1, per 5, per 25,..
  onBlur(event, option: Article, remove: boolean = true) {
    // When not dividable by the step, round up to above
    const step: number = option.Step || 1;
    if (event.target.value % step !== 0) {
      event.target.value = Math.ceil(event.target.value / step) * step;
      const msg = 'Article ' + option?.Description + ' is only available per batch of ' + option?.Step + '. We changed the amount to ' + event.target.value + '!';
      this.snackBar.open(msg, 'OK');
    }
    this.toggleQTY(option, event.target.value - this.getAmount(option), remove);
  }

  toggleQTY(art: Article, qty: number, remove: boolean = true) {
    // Update or create basket line
    let found = this.Selected.find(x => x.Article && x.Article.Id === art.Id);
    if (found) {
      found.Ordered += qty;
      if (found.Ordered === 0 && remove) this.Selected.splice(this.Selected.indexOf(found), 1);
    } else {
      found = new BasketLine({Article: art, Ordered: qty});
      this.Selected.push(found);
    }
  }

  async save() {

    if (this.isLoading) return Promise.resolve();
    this.isLoading = true;

    // Apply the promo codes
    for (const code of this.PromotionCodes) {
      this.Selected = await this._PromotionCode.applyPromotion(code.Code, this.Selected).toPromise();
    }

    // Emit and close dialog
    this.ListChange.emit({lines: this.Selected, relation: this.Relation});
    this._Dialog.getDialogById(this.dialogRef.id).close();
  }

  filter(data: any = null) {

    // Build up and filter
    const and: object[] = [];

    // Add division filter
    and.push({'DivisionId': this._Global.Division.Id});
    and.push({'ParentId': null});
    if (data && data.Code) and.push({or: [{'SearchChildren': {'like': '%' + data.Code + '%'}}, {'Code': {'like': '%' + data.Code + '%'}}]});
    if (data && data.Barcode) and.push({or: [{'SearchChildren': {'like': '%' + data.Barcode + '%'}}, {'Barcode': {'like': '%' + data.Barcode + '%'}}]});
    if (data && data.Category && data.Category.length > 0) {
      and.push({
        or: data.Category.map(category => {
          return {SearchCategories: {like: '%' + category + '%'}};
        })
      });
      // data.Category.forEach(c => and.push({'SearchCategories': {'like': '%' + c + '%'}}));
    }
    if (data && data.Description) and.push({or: [{'Description': {'like': '%' + data.Description + '%'}}, {'search': {'like': '%' + data.Description + '%'}}]});
    if (data && data.CommercialName) and.push({or: [{'SearchTranslations': {'like': '%' + data.CommercialName + '%'}}, {'SearchChildrenTranslations': {'like': '%' + data.CommercialName + '%'}}]});
    if (data && data.Groups && data.Groups.length > 0) {
      and.push({
        or: data.Groups.map(group => {
          return {SearchGroups: {like: '%' + group.Id + '%'}};
        })
      });
      // data.Groups.forEach(group => and.push({SearchGroups: {like: '%' + group.Id + '%'}}));
    }

    // Filter out the shipping costs
    and.push({or: [{'Category1Id': {neq: '4a15e144-da25-43c6-b7a4-8663012baf53'}}, {'Category1Id': null}]});

    // If needed reset filter
    this.selectedRelation = (data && data.Relation) ? data.Relation : null;
    this.filterObject.where = {'and': and};
    this.filterObject.skip = 0; // Back to first page
    this.getData();
    this.getPromotions();
  }


  // this filters the promocode, this is being shown on an extra tab
  // at this moment is the tab hidden.
  codeFilter(data: any = null) {
    const and: object[] = [];

    if (data && data.Code) and.push({'Code': {like: '%' + data.Code + '%'}});
    if (data && data.Type) and.push({'TypeId': data.Type.Id});
    if (data && data.Article) and.push({'ArticleId': data.Article.Id});
    if (data && data.Category) and.push({'CategoryId': data.Category.Id});


    if (data && data.StartFrom) and.push({'Start': {gte: data.StartFrom}});
    if (data && data.StartUntil) and.push({'Start': {lte: data.StartUntil}});
    if (data && data.EndFrom) and.push({'End': {gte: data.EndFrom}});
    if (data && data.EndUntil) and.push({'End': {lte: data.EndUntil}});

  }

  getPromotions() {

    let Params = new HttpParams().append('filter', JSON.stringify(this.filterObjectPromotions));
    if (this.Relation) Params = Params.append('relationId', this.Relation.Id);

    this._Promotion.searchRepresentativePromotions(Params).subscribe(res => {
      this.Promotions = res;
    });
  }

  checkDate(promotion: Promotion): boolean {
    return !promotion.PreviewDate ||
      new Date(promotion.StartDate) < new Date();
  }

  addToCart(e: BasketLine[]) {
    this.Selected = this.Selected.concat(e);
  }

  setRelation(event: Relation) {
    this.Relation = event;
    this.RelationChange.emit(event);
  }

  addByArticleCode() {
    this._BasketLine.addByArticleCode(this.Scan, {include: this._Article.defaultInclude}).subscribe(line => {
      if (line.Article) {
        this.toggleQTY(line.Article, (line.Article.Step ? line.Article.Step : 1));
        this._Alert.sendMessage(line.Article.Code + ' - ' + line.Article.DisplayName + ' added to shopping cart', 'success');
      } else {
        this._Alert.sendMessage('No Article found', 'danger');
      }
    }, err => {
      this._Alert.sendMessage('Error finding article', 'danger');
    });
  }

  // Add a new promo code if not exists
  // The code will be added when valid
  addPromotionCode(code: string) {
    this.Selected.forEach(l => {
      console.log(l);
      if (l.Article) l.UnitPrice = l.Article.SalesPrice;
    });
    this._PromotionCode.addCode(code, this.Selected).subscribe(res => {
      if (res && this.PromotionCodes.findIndex(x => x.Code === code) === -1) this.PromotionCodes.push(res);
    });
  }

  pullPriceList(r: Relation) {
    this.isSimulating = true;
    this._Relation.pullPriceList(r).subscribe(() => {
      r.LastPricelist = new Date();
      this.isSimulating = false;
      this.filter(this.filterForm.form.value);
    }, err => {
      this.isSimulating = false;
    });
  }

  // If older than one month, show warning.
  warnForOutdatedPricelist(): boolean {
    return this.Relation && this.Relation.LastPricelist && moment(this.Relation.LastPricelist).isBefore(moment().subtract(1, 'month'));
  }

  warnForNopricelist(): boolean {
    return this.Relation && !this.Relation.LastPricelist;
  }

  getDeliveryDate(article: Article) {

    const dates = []; // Array to compare and return the highest date
    if (!article) return null; // No article filled in
    if (!this.Relation) return null; // No relation filled in

    // create deepCopy
    article = JSON.parse(JSON.stringify(article));

    // No supply plant filled in, remove all the locations
    if (!this.Relation.DefaultSupplyPlant) article.Locations = [];
    else article.Locations = article.Locations.filter(x => x.SupplyPlant.Id === this.Relation.DefaultSupplyPlant.Id);

    // Article niveau
    if (article.InStockDate) dates.push(article.InStockDate);

    // If theres a instock date on location niveau
    if (article.Locations.length > 0 && article.Locations[0].InStockDate) dates.push(article.Locations[0].InStockDate);

    return dates.reduce((a, b) => moment(a).isAfter(b) ? a : b, undefined);
  }

}

