import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Attachment} from '../../models/Attachment/attachment.object';
import {AttachmentService} from '../../models/Attachment/attachment.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-attachment-controller',
  templateUrl: './attachment-controller.component.html',
  styleUrls: ['./attachment-controller.component.scss'],
  providers: [AttachmentService]
})
export class AttachmentControllerComponent implements OnInit {

  multiple: boolean = false;
  file: Attachment = null;
  files: Attachment[] = [];

  constructor(public globalService: GlobalService, public dialogRef: MatDialogRef<AttachmentControllerComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    if (this.data && this.data.multiple) this.multiple = this.data.multiple;
  }

  ngOnInit() {
  }

  onChange(e: Attachment[]) {
    if (this.data && this.data.multiple) {
      this.files = e || null;
    } else {
      this.file = e[0] || null;
    }
  }

  pick() {
    this.dialogRef.close(this.data && this.data.multiple ? this.files : this.file);
  }
}
