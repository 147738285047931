import {Account} from '../Account/account.object';
import {Address} from '../Address/addresses.object';
import {BasketLine} from '../BasketLine/basket-line.object';
import {Contact} from '../Contact/contact.object';
import {DefaultModel} from '../Default/default.object';
import {DocumentLines} from '../DocumentLine/documentline.object';
import {DocumentPrint} from '../DocumentPrint/documentprint.object';
import {DocumentType} from '../DocumentType/document-type.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';
import {Locale} from '../Locales/locale.object';
import {Relation} from '../Relation/relation.object';

export class Document extends DefaultModel {

  ExternalId: string = null;
  Type: DocumentType = null;
  Number: string = null;
  Relation: Relation = null;
  Account: Account = null;
  Address: Address = null;
  Contact: Contact = null;
  Generator: Document = null;
  Order: Element = null;
  Date: Date = new Date;
  Currency: DropdownOption = new DropdownOption({Id: '6a87614d-3210-44a6-b64a-ecec786e5eaa', FreeTextField1: '€'}); // Default EUR
  Source: string = null;
  Intrem: string = null;
  Extrem: string = null;
  Discount = 0;
  DiscountReason: DropdownOption = null;
  Status: DropdownOption = new DropdownOption({Id: '6f2ffafe-e634-450c-981d-53304a089d14'}); // Default Draft
  Classification: DropdownOption = null;
  DueDate: Date = null;
  DeliveryDate: Date = null;
  PaymentRef: string = null;
  PaymentMethod: DropdownOption = null;
  ShippingMethod: DropdownOption = null;
  Locale: Locale = new Locale({Id: 'en-GB'});
  Reference: string = null;
  SupplyPlant: DropdownOption = null;
  TrackAndTrace: string[] = [];
  SentToMirto: Date = null;
  VatAmountDC = 0;
  AmountDC = 0;
  AmountDCInclVat = 0;
  NotifyEmailOnApprove: Date = null;

  ImportedInExact: Date = null;
  ImportedError: string = null;

  DocumentLines: DocumentLines[] = [];
  DocumentPrints: DocumentPrint[] = [];

  constructor(data: any = null) {

    super(data);

    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.Type = this.Type ? new DocumentType(this.Type) : null;
    this.Relation = this.Relation ? new Relation(this.Relation) : null;
    this.Account = this.Account ? new Account(this.Account) : null;
    this.Address = this.Address ? new Address(this.Address) : null;
    this.Contact = this.Contact ? new Contact(this.Contact) : null;
    this.Status = this.Status ? new DropdownOption(this.Status) : null;
    this.Currency = this.Currency ? new DropdownOption(this.Currency) : null;
    this.PaymentMethod = this.PaymentMethod ? new DropdownOption(this.PaymentMethod) : null;
    this.Generator = this.Generator ? new Document(this.Generator) : null;
    this.Locale = this.Locale ? new Locale(this.Locale) : null;
    this.DiscountReason = this.DiscountReason ? new DropdownOption(this.DiscountReason) : null;
    this.SupplyPlant = this.SupplyPlant ? new DropdownOption(this.SupplyPlant) : null;
    this.ShippingMethod = this.ShippingMethod ? new DropdownOption(this.ShippingMethod) : null;
    this.Classification = this.Classification ? new DropdownOption(this.Classification) : null;

    this.DocumentLines.forEach((item, index) => {
      this.DocumentLines[index] = new DocumentLines(item);
    });
    this.DocumentPrints.forEach((item, index) => {
      this.DocumentPrints[index] = new DocumentPrint(item);
    });
  }

  addDocumentLinesByBasketLines(input: { lines: BasketLine[], relation: Relation } = null) {
    if (input) {
      // Iterate each line, search for an update or removed line
      input.lines.forEach(line => {
        if (!line.PromotionCode || (line.PromotionCode && !this.DocumentLines.find(x => x.PromotionCode && x.PromotionCode.Id === line.PromotionCode.Id))) {
          const newLine = line.asDocumentLine(input.relation);
          if (this.Type.Id === 'd5cc4fd9-1801-4527-81e1-b9de93076dab') newLine.UnitPrice = 0; // Always zéro for FOC
          this.DocumentLines.push(newLine);
        }
      });
    }
  }
}
