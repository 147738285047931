/*!
 * Our base default model
 * This model contains default properties
 */

import {Account} from '../Account/account.object';
import {Division} from '../Division/division.object';

export class DefaultModel {
  Id: string = null;
  Division: Division = null;
  Modified: Date = null;
  Modifier: Account = null;
  Created: Date = null;
  Creator: Account = null;

  randomNumber: number;

  constructor(data: any = null) {

    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    // ALWAYS generate random number (for ngModel groups primary key (identifier))
    this.randomNumber = Math.random();
  }
}
