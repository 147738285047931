import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {Location} from './location.object';


@Injectable()
export class LocationService {

  defaultInclude = ['Division', 'Article', 'SupplyPlant'];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<Location[]> {
    return this.http
        .get<Location[]>(environment.apiUrl + '/Locations', {params: ReqParams})
        .pipe(
            map(res => <Location[]>res),
            map(res => {
              return res.map(rel => new Location(rel));
            })
        );
  }

  getById(Id: string, ReqParams: HttpParams = null): Observable<Location> {
    return this.http
        .get<Location>(environment.apiUrl + '/Locations/' + Id, {params: ReqParams})
        .pipe(
            map(res => <Location>res),
            map(res => {
              return new Location(res);
            })
        );
  }

  upsert(Entry: Location): Observable<Location> {
    return this.http
        .put<Location>(environment.apiUrl + '/Locations', JSON.stringify(Entry))
        .pipe(
            map(res => <Location>res),
            map(res => {
              // Modify original doc without destroying related nexted objects
              for (const prop in res) if (prop in Entry) Entry[prop] = res[prop];
              return new Location(Entry);
            })
        );
  }

  remove(Entry: Location): Observable<{ count: number }> {
    return this.http
        .delete<{ count: number }>(environment.apiUrl + '/Locations/' + Entry.Id);
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
        .get(environment.apiUrl + '/Locations/count', {params: ReqParams})
        .pipe(
            map(res => <{ count: number }>res)
        );
  }

  printBarcodes(ReqParams: HttpParams = null): Observable<Blob> {
    return this.http
        .get(environment.apiUrl + '/Locations/print-barcodes', {params: ReqParams, responseType: 'blob'})
        .pipe(
            map(res => {
              return new Blob([res], {type: 'application/file'});
            })
        );
  }

  exportXlsx(ReqParams: HttpParams = null): Observable<Blob> {
    return this.http
        .get(environment.apiUrl + '/Locations/export', {params: ReqParams, responseType: 'blob'})
        .pipe(
            map(res => {
              return new Blob([res], {type: 'application/vnd.ms-excel'});
            })
        );
  }
}


