import {HttpParams} from '@angular/common/http';
import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Activation} from '../../models/Activation/activation.object';
import {ActivationService} from '../../models/Activation/activation.service';
import {AttachmentService} from '../../models/Attachment/attachment.service';
import {Email} from '../../models/Email/email.object';
import {RelationService} from '../../models/Relation/relation.service';
import {AlertService} from '../../services/alert.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-activation-editor',
  templateUrl: './activation-editor.component.html',
  styleUrls: ['./activation-editor.component.css'],
  providers: [RelationService, AttachmentService],
})
export class ActivationEditorComponent implements OnInit {

  // Original list to modify
  @Input() List: Activation[] = [];
  @Output() ListChange: EventEmitter<Activation[]> = new EventEmitter<Activation[]>();

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  @ViewChild('emailInput') emailInput;
  dialogRef: MatDialogRef<ActivationEditorComponent>;

  // Original and copy;
  Original: Activation = new Activation();
  Copy: Activation = new Activation;

  isLoading = false;

  constructor(private _Dialog: MatDialog, private _Attachment: AttachmentService, private _Alert: AlertService, private _Activation: ActivationService, private _CdRef: ChangeDetectorRef, public _Relation: RelationService, public _Global: GlobalService) {
  }

  ngOnInit() {
  }

  edit(input: Activation = new Activation) {
    this.Original = input; // Store original
    this.Copy = new Activation(JSON.parse(JSON.stringify(input)));
    if (!this.Copy.Division) this.Copy.Division = this._Global.Division; // Assign Division if not assigned
    this.dialogRef = this._Dialog.open(this.templateRef);
    this._CdRef.detectChanges();
  }

  remove(object: Activation) {
    this.isLoading = true;
    this._Activation.remove(object).subscribe(res => {
      if (this.List) this.List.splice(this.List.findIndex(x => x.Id === object.Id), res.count);
      this.ListChange.emit(this.List);
      this.isLoading = false;
    }, () => this.isLoading = false);
  }

  save() {
    this.isLoading = true;
    this._Activation.upsert(this.Copy).subscribe(res => {
      this.Copy = res;
      res = new Activation(res);
      this.isLoading = false;
      Object.assign(this.Original, res);
      const found = this.List.find(x => x.Id === res.Id);
      found ? Object.assign(found, res) : this.List.push(res); // Modify or push array list
      this.ListChange.emit(this.List);
      this._Dialog.getDialogById(this.dialogRef.id).close();
    }, err => {
      this.isLoading = false;
    });
  }

  compareWith(o1: any, o2: any) {
    return o1 === o2 || o1 && o2 && o1.Id && o2.Id && o1.Id === o2.Id;
  }

  // Generate e-mail layout
  generateEmail(row: Activation): Email {
    return new Email({
      Relation: row.Relation,
      Contacts: row.Contact ? [row.Contact] : [],
      Variables: [
        {name: 'contact:fullName', value: row.Contact ? row.Contact.getFullName() : ''},
        {name: 'address:fullAddress', value: row.Address ? row.Address.getFullAddress() : ''}
      ]
    });
  }

  addEmail(e: any, input: any) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(e).toLowerCase())) {
      this.Copy.ExtraEmails.push(e);
      this.emailInput.nativeElement.input = '';
      this.emailInput.nativeElement.value = '';
    }
  }

  mkDir() {
    this.isLoading = true;
    const ReqParams = new HttpParams().append('path', 'Activations/' + this.Copy.Id);
    this._Attachment.mkDir(ReqParams).subscribe(res => {
      this._Alert.sendMessage('The directory has been created!', 'info');
      this.isLoading = false;
    }, err => this.isLoading = false);
  }

}
