import {Division} from '../Division/division.object';
import {DropdownOption} from '../DropdownOption/dropdown-option.object';
import {PriceListDetail} from '../PriceListDetail/price-list-detail.object';

export class PriceList {

  Id: string = null;
  ExternalId: string = null;
  Division: Division = null;
  Currency: DropdownOption = null;
  Code: string = null;
  Description: string = null;
  PriceListDetails: PriceListDetail[] = [];

  constructor(data: any = null) {

    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    this.Division = this.Division ? new Division(this.Division) : null;
    this.Currency = this.Currency ? new DropdownOption(this.Currency) : null;

    this.PriceListDetails.forEach((item, index) => {
      this.PriceListDetails[index] = new PriceListDetail(item);
    });
  }
}
