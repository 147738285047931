import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {Locale} from './locale.object';

@Injectable()
export class LocaleService {
  defaultInclude = [{'Country': 'ShipmentArticle'}];

  constructor(private http: HttpClient) {
  }

  get(ReqParams: HttpParams = null): Observable<Locale[]> {
    return this.http
        .get<Locale[]>(environment.apiUrl + '/Locales', {params: ReqParams})
        .pipe(
            map(res => <Locale[]>res),
            map(res => {
              return res.map(rel => new Locale(rel));
            })
        );
  }

}
