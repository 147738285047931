export class Attachment {

  Name: string = null;
  Size: number = null;
  Date: Date = null;
  Type: string = null;
  Path: string = null;
  Children: Attachment[] = [];

  IsOpen = false;

  constructor(data: any = null) {
    // Better solution than Object.assign(this, data); to avoid assign not-declared, irrelevant properties.
    for (const prop in data) if (prop in this) this[prop] = data[prop];

    if (this.Children) {
      this.Children.forEach((item, index) => {
      this.Children[index] = new Attachment(item);
    });
    }
  }
}
