import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Role} from '../../models/Role/role.object';
import {RoleService} from '../../models/Role/role.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-role-editor',
  templateUrl: './role-editor.component.html',
  styleUrls: ['./role-editor.component.css']
})
export class RoleEditorComponent implements OnInit {

  // Original list to modify
  @Input() List: Role[] = [];
  @Output() ListChange: EventEmitter<Role[]> = new EventEmitter<Role[]>();

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<RoleEditorComponent>;

  // Original and copy;
  Original: Role = new Role();
  Copy: Role = new Role();

  isLoading = false;

  constructor(private _Dialog: MatDialog, private _Role: RoleService, private _CdRef: ChangeDetectorRef, public _Global: GlobalService) {
  }

  ngOnInit() {

  }

  edit(input: Role = new Role) {
    this.Original = input; // Store original
    this.Copy = new Role(JSON.parse(JSON.stringify(input)));
    this.dialogRef = this._Dialog.open(this.templateRef);
    this._CdRef.detectChanges();
  }

  remove(object: Role) {
    this.isLoading = true;
    this._Role.remove(object).subscribe(res => {
      if (this.List) this.List.splice(this.List.findIndex(x => x.Id === object.Id), res.count);
      this.ListChange.emit(this.List);
      this.isLoading = false;
    }, () => this.isLoading = false);
  }

  save(object: Role = this.Copy) {
    this.isLoading = true;
    this._Role.upsert(object).subscribe(res => {
      this.isLoading = false;
      this.Copy = res;
      Object.assign(this.Original, res);
      const found = this.List.find(x => x.Id === res.Id);
      found ? Object.assign(found, res) : this.List.push(res); // Modify or push array list
      this.ListChange.emit(this.List);
      this._Dialog.getDialogById(this.dialogRef.id).close();
    }, err => {
      this.isLoading = false;
    });
  }

  compareWith(o1: any, o2: any) {
    return o1 === o2 || o1 && o2 && o1.Id && o2.Id && o1.Id === o2.Id;
  }


}
