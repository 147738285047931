import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {DeliverDates} from './delivery-dates.object';
import {LeadTime} from './lead-time.object';

@Injectable()
export class LeadTimeService {

  defaultInclude = ['Origin', 'Destination'];

  constructor(private http: HttpClient) {
  }

  get(reqParams: HttpParams = null): Observable<LeadTime[]> {
    return this.http
      .get<LeadTime[]>(environment.apiUrl + '/LeadTimes', {params: reqParams}).pipe(
        map(res => <LeadTime[]>res),
        map(res => {
          return res.map(rel => new LeadTime(rel));
        }),);
  }

  getById(id: string, ReqParams: HttpParams = null): Observable<LeadTime> {
    return this.http
      .get<LeadTime>(environment.apiUrl + '/LeadTimes/' + id, {params: ReqParams}).pipe(
        map(res => <LeadTime>res),
        map(res => {
          return new LeadTime(res);
        }));
  }

  getDeliveryDate(originId: string, destinationId: string, articles: string[]): Observable<DeliverDates> {
    const httpParams = new HttpParams()
      .append('originId', originId)
      .append('destinationId', destinationId);
    return this.http
      .post<DeliverDates>(environment.apiUrl + '/LeadTimes/getDeliveryDate', JSON.stringify(articles), {params: httpParams});
  }

  upsert(entry: LeadTime): Observable<LeadTime> {
    return this.http
      .put<LeadTime>(environment.apiUrl + '/LeadTimes', JSON.stringify(entry)).pipe(
        map(res => <LeadTime>res),
        map(res => {
          // Modify original doc without destroying related objects (doclines)
          for (const prop in res) if (prop in entry) entry[prop] = res[prop];
          return new LeadTime(entry);
        }));
  }

  remove(entry: LeadTime): Observable<{ count: number }> {
    return this.http
      .delete<{ count: number }>(environment.apiUrl + '/LeadTimes/' + entry.Id);
  }

  count(ReqParams: HttpParams = null): Observable<{ count: number }> {
    return this.http
      .get(environment.apiUrl + '/LeadTimes/count', {params: ReqParams})
      .pipe(
        map(res => <{ count: number }>res)
      );
  }
}
