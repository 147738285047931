import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Contact} from '../../models/Contact/contact.object';
import {ContactService} from '../../models/Contact/contact.service';
import {GlobalService} from '../../services/global.service';

@Component({
  selector: 'app-crm-contact-editor',
  templateUrl: './crm-contact-editor.component.html',
  providers: [ContactService],
  styleUrls: ['./crm-contact-editor.component.css'],
})
export class CrmContactEditorComponent implements OnInit {

  @Input() hideRelation = false; // When editing division adress, there is no relation required.
  @Input() List: Contact[] = [];
  @Output() ListChange: EventEmitter<Contact[]> = new EventEmitter<Contact[]>();
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<CrmContactEditorComponent>;
  Original: Contact = null;
  Copy: Contact = null;
  isLoading = false;
  isLoadingEO = false;

  constructor(private _Dialog: MatDialog, private _Contact: ContactService, private _Global: GlobalService) {
  }

  edit(input: Contact = new Contact()) {
    if (!input.Division) input.Division = this._Global.Division; // Assign Division if not assigned
    this.dialogRef = this._Dialog.open(this.templateRef);
    this.Original = input;
    this.Copy = new Contact(JSON.parse(JSON.stringify(input)));
    this.Copy.Division = this.Copy.Division ? this.Copy.Division : this._Global.Division;
  }

  duplicate(contact: Contact) {
    contact = new Contact((JSON.parse(JSON.stringify(contact))));
    contact.Id = null;
    contact.IsDefault = null;
    contact.ExternalId = null;
    this.edit(contact);
  }

  save(object = this.Copy) {
    this.isLoading = true;
    this._Contact.upsert(object).subscribe(res => {
      this.isLoading = false;
      this.Copy = object;
      Object.assign(this.Original, this.Copy);
      const found = this.List.find(x => (x.Id === this.Copy.Id && x.Id !== null) || (x.Id === null && x.randomNumber === this.Copy.randomNumber));
      found ? this.List[this.List.indexOf(found)] = this.Copy : this.List.push(this.Copy);
      this._Dialog.getDialogById(this.dialogRef.id).close();
      this.ListChange.emit(this.List);
    }, err => this.isLoading = false);
  }


  remove(c: Contact) {
    this._Contact.remove(c).subscribe(res => {
      this.List.splice(this.List.indexOf(c), 1);
      this.ListChange.emit(this.List);
    });
  }

  ngOnInit() {
  }

}
