import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs/index';
import {tap} from 'rxjs/internal/operators';
import {AlertService} from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService implements HttpInterceptor {

  constructor(private _Router: Router, private _Alert: AlertService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // If not file upload => add json headers
    req.body instanceof FormData ? null : req = req.clone({headers: req.headers.set('Content-Type', 'application/json')});

    // Add CORS
    req = req.clone({headers: req.headers.set('Access-Control-Allow-Origin', 'http://aorta.priscus.be')});

    // Allow server to create cookies on client
    req = req.clone({headers: req.headers.set('Access-Control-Allow-Credentials', 'true')});

    // Allow send client to send the Authorization cookie to server
    req = req.clone({withCredentials: true});

    // Go ahead
    return next.handle(req).pipe(tap(
        event => {
          // Intercept error messages
          if (event instanceof HttpResponse && event.body && event.body.error) {
            // for (let error of event.body.errors) this.PN.PNotify({type: 'danger', title: 'Error', text: error});
            // this.PN.PNotify({type: 'danger', title: 'Error', text: event.body.error.message});
          }
        },
        err => {

          if (err instanceof HttpErrorResponse && err.error.error) {
            this._Alert.sendMessage(err.error.error.message, 'danger');
          }

          if (err instanceof HttpErrorResponse && err.status === 401) {
            // handle 401 errors (Not authorized => login page)
            this._Router.navigate(['/login']);
          }
        }
    ));
  }
}
