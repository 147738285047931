import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AccountDivision} from '../../models/AccountDivision/account-division.object';
import {AccountDivisionService} from '../../models/AccountDivision/account-division.service';

@Component({
  selector: 'app-account-division-editor',
  templateUrl: './account-division-editor.component.html',
  styleUrls: ['./account-division-editor.component.css'],
  providers: [AccountDivisionService]
})
export class AccountDivisionEditorComponent implements OnInit {

  // Original list to modify
  @Input() List: AccountDivision[] = [];
  @Output() ListChange: EventEmitter<AccountDivision[]> = new EventEmitter<AccountDivision[]>();

  // MatDialogs
  @ViewChild('templateRef') templateRef;
  dialogRef: MatDialogRef<AccountDivisionEditorComponent>;

  // Original and copy;
  Original: AccountDivision = new AccountDivision;
  Copy: AccountDivision = new AccountDivision;

  isLoading = false;

  constructor(private _Dialog: MatDialog, private _AccountDivision: AccountDivisionService) {
  }

  ngOnInit() {

  }

  edit(input: AccountDivision = new AccountDivision()) {
    this.Original = input; // Store original
    this.Copy = new AccountDivision(JSON.parse(JSON.stringify(input)));
    this.dialogRef = this._Dialog.open(this.templateRef);
  }

  remove(object: AccountDivision) {
    this.isLoading = true;
    this._AccountDivision.remove(object).subscribe(res => {
      if (this.List) this.List.splice(this.List.findIndex(x => x.Id === object.Id), res.count);
      this.ListChange.emit(this.List);
      this.isLoading = false;
    }, () => this.isLoading = false);
  }

  save(object: AccountDivision = this.Copy) {
    this.isLoading = true;
    this._AccountDivision.upsert(object).subscribe(res => {
      this.isLoading = false;
      this.Copy = res;
      Object.assign(this.Original, res);
      const found = this.List.find(x => x.Id === res.Id);
      found ? Object.assign(found, res) : this.List.push(res); // Modify or push array list
      this.ListChange.emit(this.List);
      this._Dialog.getDialogById(this.dialogRef.id).close();
    }, err => {
      this.isLoading = false;
    });
  }


}
