import {HttpClient, HttpEvent, HttpParams, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {Contact} from '../Contact/contact.object';
import {Attachment} from './attachment.object';

@Injectable()
export class AttachmentService {

  constructor(private http: HttpClient) {
  }

  list(ReqParams: HttpParams = null): Observable<Attachment[]> {
    return this.http
        .get<Attachment[]>(environment.apiUrl + '/Attachments/list', {params: ReqParams})
        .pipe(
            map(res => <Attachment[]>res),
            map(res => {
              return res.map(rel => new Attachment(rel));
            })
        );
  }

  download(input: Attachment): Observable<Blob> {
    return this.http
        .get(environment.apiUrl + '/Attachments/download/' + encodeURIComponent(input.Path), {responseType: 'blob'})
        .pipe(
            map(res => {
              return new Blob([res], {type: 'application/file'});
            })
        );
  }

  mail(Layout: { SenderName: string, SenderEmail: string, Subject: string, Body: string, Receivers: Contact[], Attachments: Attachment[] }): Observable<any> {
    return this.http
        .post(environment.apiUrl + '/Attachments/mail', JSON.stringify(Layout));
  }

  remove(input: Attachment): Observable<any> {
    return this.http
        .delete(environment.apiUrl + '/Attachments/' + encodeURIComponent(input.Path));
  }

  // Upload single file
  upload(file, path: string): Observable<HttpEvent<any>> {
    // create a new multipart-form for every file
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    // create a http-post request and pass the form and tell it to report the upload progress
    const req = new HttpRequest('POST', environment.apiUrl + '/Attachments/upload/' + encodeURIComponent(path), formData, {
      reportProgress: true
    });
    return this.http.request(req);
  }

  mkDir(ReqParams: HttpParams = null): Observable<any> {
    return this.http
        .post(environment.apiUrl + '/Attachments/mkDir', null, {params: ReqParams})
        .pipe(
            map(res => <Attachment[]>res),
            map(res => {
              return new Attachment(res);
            })
        );
  }
}
