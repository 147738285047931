import {HttpParams} from '@angular/common/http';
import {Component, forwardRef, Input, OnInit, TemplateRef} from '@angular/core';
import {NG_VALUE_ACCESSOR, UntypedFormControl} from '@angular/forms';
import {Locale} from '../../models/Locales/locale.object';
import {LocaleService} from '../../models/Locales/locale.service';
import {FilterObject} from '../../objects/filter-object';

@Component({
  selector: 'app-locale-selector',
  templateUrl: './selector.component.html',
  providers: [
    LocaleService,
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LocaleSelectorComponent), multi: true}
  ]
})
export class LocaleSelectorComponent implements OnInit {

  constructor(private _Locales: LocaleService) {
  }

  get value() {
    return this._value;
  }

  set value(val: any) {
    if (val instanceof Locale || !val) {
      this._value = val;
      this.ChildControl.setValue(val);
      this.onChange(val);
    }
  }

  Locales: Locale[] = [];
  @Input() placeholder = 'Locale';
  @Input() required: boolean = false;
  @Input() disabled = false;
  @Input() multiple: boolean = false;
  @Input() getOnlyId: boolean = false;
  @Input() where = null;
  @Input() matSuffix: TemplateRef<any> = null; // Wrap into matSuffix
  ChildControl = new UntypedFormControl();
  Selected: string = null; // Default selected
  filterObject: FilterObject = new FilterObject();

  // CODE BELOW IS REQUIRED TO REGISTER THE NG_VALUE_ACCESSOR
  _value: string = null;
  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  compareWith(o1: any, o2: any) {
    return o1 === o2 || o1 && o2 && o1.Id && o2.Id && o1.Id === o2.Id;
  }

  ngOnInit() {
    this.filterObject.include = this._Locales.defaultInclude;
    if (this.where) this.filterObject.where = this.where;
    const params = new HttpParams().append('filter', JSON.stringify(this.filterObject));
    this._Locales.get(params).subscribe(res => this.Locales = res);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    this.value = value;
  }

}
